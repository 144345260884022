import { useEffect, useState } from "react";
import Switch from "react-switch";
import { toast } from "react-toastify";
import axiosMiddleware from "../../../helper/axios";
import useForm from "../../../helper/useForm";
import ValidateRules from "../../../helper/ValidateRules";
import "./addCategory.css";

function AddCategory(props) {
  const [isImageChange, setIsImageChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Loading...");
  const [isEdited, setIsEdited] = useState(false);
  const { values, errors, handleChange, handleSubmit, initialValues } = useForm(
    handleCatForm,
    ValidateRules.Category
  );

  useEffect(() => {
    const loadData = {
      catId: props.cellProduct.id || null,
      catName: props.cellProduct.name || null,
      catImageURL: props.cellProduct.image || null,
      catDescription: props.cellProduct.description || null,
      catStatus: parseInt(props.cellProduct.isActive) ? true : false,
      isApproved: parseInt(props.cellProduct.isApproved) ? true : false,
    };
    initialValues(loadData);
    setIsEdited(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cellProduct]);

  function loadImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      const output = document.getElementById("output");
      output.src = reader.result;
      handleChange("", "file", "catImageURL", reader.result);
      setIsImageChange(true);
      setIsEdited(true);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  function handleClose() {
    if(props.setTrigger){
    props.setTrigger(false);
    }
    if(props.setCellProduct){
      props.setCellProduct({});
    }
  }

  function handleCatForm() {
    setIsLoading(true);
    const submit = {
      name: values.catName,
      image: isImageChange && values.catImageURL,
      status: `${values.catStatus ? 1 : 0}`,
      description: values.catDescription,
      parentId: "0",
      isApproved: `${values.isApproved ? 1 : 0}`
    };

    if (values.catId !== null) {
      editCategory(values.catId, submit);
    } else {
      addCategory(submit);
    }
  }

  async function addCategory(data) {
    await axiosMiddleware
      .post("category", data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          setIsLoading(false);
          props.setTrigger(false);
          if(props.isOntheFly){
            props.newCatObject(res.data.Data);
          }else{
            window.location.reload(false);
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Something went wrong, Unable to Add Category.");
        // console.log(e);
      });
  }

  async function editCategory(id, data) {
    await axiosMiddleware
      .put(`category/${id}`, data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.onRowUpdate(res.data.Data, props.catIndex);
          props.setTrigger(false);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Something went wrong, Unable to Edit Category.");
        // console.log(e);
      });
  }

  return props.trigger ? (
    <div className="global-popup">
      <div className="addProductPopup">
        <div className="addPopupinner fade-in-up">
          <div className="addProduct-heading border-bottom">
            <h2 className="popup-heading" style={{ marginTop: "7px" }}>
              Add Category
            </h2>
            <span>
              <a onClick={handleClose} href="#close">
                <img src="./Image/closepopup-icon.svg" alt="Close Icon" />
              </a>
            </span>
          </div>
          <div className="popupInnerBody">
            <form className="fomik-form" onSubmit={handleSubmit} noValidate>
              <div className="textfield-div">
                <label className="activeUser-label" htmlFor="CatName">
                  <span>Name</span>
                </label>
                <input
                  autoComplete="off"
                  className={`form-control shadow-none input-class ${errors.catName && "is-danger"
                    }`}
                  style={{ width: "400px" }}
                  value={values.catName}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                  placeholder="Enter category name"
                  name="catName"
                  required
                />
                {errors.catName && (
                  <p className="help is-danger">{errors.catName}</p>
                )}
              </div>
              <div className="textfield-div">
                <label className="activeUser-label" htmlFor="CatDescription">
                  <span>Description</span>
                </label>
                <input
                  autoComplete="off"
                  className={`form-control shadow-none input-class`}
                  style={{ width: "400px" }}
                  value={values.catDescription}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                  placeholder="Enter description"
                  name="catDescription"
                />
              </div>
              <div className="d-flex justify-content-between">
                <label className="activeUser-label">
                  <span>Status</span>
                  <Switch
                    name="catStatus"
                    onChange={(e) => { handleChange("", "switch", "catStatus", e); setIsEdited(true); }}
                    checked={values.catStatus}
                  />
                </label>
                <label className="activeUser-label">
                  <span>Is Approved</span>
                  <Switch
                    name="isApproved"
                    onChange={(e) => { handleChange("", "switch", "isApproved", e); setIsEdited(true); }}
                    checked={values.isApproved}
                  />
                </label>
              </div>
              <div
                className="image-upload"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: "10px",
                }}
              >
                <label className="image-upload-label" htmlFor="fileUpload">
                  Upload Image
                </label>
                <input
                  type="file"
                  name="image"
                  id="fileUpload"
                  accept="image/*"
                  onChange={loadImage}
                  required
                />
                <img
                  id="output"
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    width: "120px",
                  }}
                  src={values.catImageURL}
                  alt=""
                />
              </div>
              <div
                className=" border-bottom"
                style={{ width: "500px", marginLeft: "-48px" }}
              ></div>
              <div className="addProductFooter d-flex">
                <button
                  type="button"
                  className="footer-cancel"
                  onClick={() => { handleClose(); props.setTrigger(false) }}
                >
                  Cancel
                </button>
                <div className="footerBtn">
                  <button type="submit" className={`btn primary ${isLoading || !isEdited ? "disabledButton" : "small"}`} disabled={isLoading || !isEdited ? true : false}>{isLoading ? loadingMsg : "Save"}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default AddCategory;

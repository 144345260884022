import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Switch from "react-switch";
import axiosMiddleware from "../../../helper/axios";
import useForm from "../../../helper/useForm";
import ValidateRules from "../../../helper/ValidateRules";
import "./addSeller.css";

function AddAgent(props) {
  const [isImageChange, setIsImageChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Loading...");
  const { values, errors, handleChange, handleSubmit, initialValues } = useForm(
    handleAgentForm,
    ValidateRules.Seller
  );

  useEffect(() => {
    initialValues({
      id: props.selectedAgent.id || null,
      firstName: props.selectedAgent.firstName || null,
      lastName: props.selectedAgent.lastName || null,
      email: props.selectedAgent.email || null,
      contactNumber: props.selectedAgent.contactNumber || null,
      isActive: parseInt(props.selectedAgent.isActive) ? true : false,
      profilePicture: props.selectedAgent.profilePicture || null,
      zipCode: props.selectedAgent.zipCode || null,
    });
    setIsEdited(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedAgent]);

  function loadImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      const output = document.getElementById("output");
      output.src = reader.result;
      handleChange("", "file", "profilePicture", reader.result);
      setIsImageChange(true);
      setIsEdited(true);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  function handleAgentForm() {
    setIsLoading(true);
    const submit = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      contactNumber: values.contactNumber,
      zipCode: `${values.zipCode}`,
      status: `${values.isActive ? 1 : 0}`,
      profilePicture: isImageChange ? values.profilePicture : "",
    };

    if (values.id !== null) {
      editAgent(values.id, submit);
    } else {
      addAgent(submit);
    }
  }

  async function addAgent(data) {
    await axiosMiddleware
      .post("agent", data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.onRowInsert(res.data.Data);
          props.onClose();
          setIsLoading(false);
          // window.location.reload(true);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }

  async function editAgent(id, data) {
    await axiosMiddleware
      .put(`agent/${id}`, data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.onRowUpdate(res.data.Data, props.agentIndex);
          props.onClose();
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }

  return (
    <div className="global-popup">
      <div className="addSellerPopUp">
        <div className="addPopUpInner">
          <div className="seller-headind border-bottom">
            <h2 className="popup-heading" style={{ marginTop: "4px" }}>
              Add Agent
            </h2>
            <span>
              <a onClick={props.onClose} href={props.onClose}>
                <img src="./Image/closepopup-icon.svg" alt="Close" />
              </a>
            </span>
          </div>
          <div className="popupbody">
            <form className="fomik-form" onSubmit={handleSubmit} noValidate>
              <div className="name-input">
                <span className="name-span">*First Name</span>
                <input
                  required
                  autoComplete="off"
                  placeholder="Enter First Name"
                  name="firstName"
                  type="text"
                  className={`form-control shadow-none input-class ${errors.firstName && "is-danger"
                    }`}
                  value={values.firstName}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                />
                {errors.firstName && (
                  <p className="help is-danger">{errors.firstName}</p>
                )}
              </div>
              <div className="name-input">
                <span className="name-span">*Last Name</span>
                <input
                  required
                  autoComplete="off"
                  placeholder="Enter Last Name"
                  name="lastName"
                  type="text"
                  className={`form-control shadow-none input-class ${errors.lastName && "is-danger"
                    }`}
                  value={values.lastName}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                />
                {errors.lastName && (
                  <p className="help is-danger">{errors.lastName}</p>
                )}
              </div>
              <div className="name-input">
                <span className="name-span">*Email</span>
                <input
                  required
                  autoComplete="off"
                  placeholder="email@xyz.com"
                  name="email"
                  type="email"
                  className={`form-control shadow-none input-class ${errors.email && "is-danger"
                    }`}
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                />
                {errors.email && <p className="help is-danger">{errors.email}</p>}
              </div>
              <div className="name-input">
                <span className="name-span">*Contact Number</span>
                <input
                  required
                  autoComplete="off"
                  placeholder="1234567890"
                  name="contactNumber"
                  type="text"
                  className={`form-control shadow-none input-class ${errors.contactNumber && "is-danger"
                    }`}
                  value={values.contactNumber}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                />
                {errors.contactNumber && (
                  <p className="help is-danger">{errors.contactNumber}</p>
                )}
              </div>
              <div className="name-input">
                <span className="name-span">*Zip Code</span>
                <input
                  required
                  autoComplete="off"
                  placeholder="Enter postal code"
                  name="zipCode"
                  type="text"
                  className={`form-control shadow-none input-class ${errors.zipCode && "is-danger"
                    }`}
                  value={values.zipCode}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                />
                {errors.zipCode && (
                  <p className="help is-danger">{errors.zipCode}</p>
                )}
              </div>
              <div className="name-input">
                <label className="activeUser-label">
                  <span>Status</span>
                  <br />
                  <Switch
                    name="catStatus"
                    onChange={(e) => { handleChange("", "switch", "isActive", e); setIsEdited(true); }}
                    checked={values.isActive}
                  />
                </label>
              </div>
              <div
                className="image-upload"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "10px",
                  paddingBottom: "10px",
                }}
              >
                <label className="image-upload-label" htmlFor="fileUpload">
                  Upload Image
                </label>
                <input
                  type="file"
                  name="profilePicture"
                  id="fileUpload"
                  accept="image/*"
                  onChange={loadImage}
                  required
                />
                <img
                  id="output"
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    width: "80px",
                  }}
                  src={values.profilePicture}
                  alt=""
                />
              </div>
              <div
                className="border-bottom"
                style={{ width: "500px", marginLeft: "-48px" }}
              ></div>
              <div className="addProductFooter d-flex">
                <button
                  type="reset"
                  className="footer-cancel"
                  onClick={props.onClose}
                >
                  Cancel
                </button>
                <div className="footerBtn">
                  <button type="submit" className={`btn primary ${isLoading || !isEdited ? "disabledButton" : "small"}`} disabled={isLoading || !isEdited ? true : false}>{isLoading ? loadingMsg : "Save"}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
}

export default AddAgent;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css';
import Signin from "./pages/SignIn/Signin";
import Dashboard from "./pages/sidebar_menu/Dashboard/Dashboard"
import Category from "./pages/sidebar_menu/Category/Category";
import Sidebar from "./pages/sidebar_menu/sidebar";
import SubCategory from "./pages/sidebar_menu/subcategory/SubCategory";
import Brand from "./pages/sidebar_menu/Brand/Brand";
import Request from "./pages/sidebar_menu/Request/Request";
import ForgotPassword from "./pages/SignIn/ForgotPassword";
import ResetPassword from "./pages/SignIn/ResetPassword";
import Agent from "./pages/sidebar_menu/Agent/Agent";
import Seller from "./pages/sidebar_menu/Seller/Seller";
import Logout from "./pages/sidebar_menu/Logout/Logout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./globalStyle.css";
import Customer from "./pages/sidebar_menu/Cutomer/Customer";
import Banner from "./pages/sidebar_menu/Banner/Banner";
import Settings from "./pages/sidebar_menu/Settings/Settings";

function App() {
  const history = useHistory();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [blockScroll, setBlockScroll] = useState(false);
  const toggleShowSideMenu = () => setShowSideMenu(!showSideMenu);
  //`${process.env.REACT_APP_API_KEY}`
  // function handleLogin() {
  //   if (localStorage.getItem("ApiKey")) {
  //     setAuthorize(true);
  //   } else {
  //     setAuthorize(true);
  //   }
  // }
  useEffect(() => {
    if (localStorage.getItem("ApiKey") && sessionStorage.getItem("User-Info") !== null) {
      if (window.performance.getEntriesByType('navigation').map((nav) => nav.type).includes('reload')) {
        history.push(window.location.pathname);
      } else {
        if (window.location.href.indexOf('dashboard') === -1 && window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) !== "") {
          history.push(window.location.pathname);
        } else {
          history.push("/dashboard");
        }
      }
    } else if (window.location.href.indexOf('reset-password') > -1){
      history.push(window.location.pathname);
    } else {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollBlock = (data) => {
    setBlockScroll(data);
  }

  return (
    <div className={`${showSideMenu && "sidebarLess"}`}>
      <Switch>
        <Route exact path="/" component={Signin} />
        <Route exact path="/reset" component={ForgotPassword} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Router>
          <Sidebar onSidebarButtonClick={toggleShowSideMenu} />
          <Switch>
            <div className={`panel-wrapper ${blockScroll ? "prevScroll" : ""}`} id="panel_wrapper">
              <Route exact path="/dashboard">
                <Dashboard onShowSideMenu={showSideMenu} />
              </Route>
              <Route exact path="/category">
                <Category scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/subcategory">
                <SubCategory scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/brand">
                <Brand scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/request">
                <Request scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/agent">
                <Agent scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/seller" >
                <Seller scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/customers" >
                <Customer scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/banner" >
                <Banner scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/settings" >
                <Settings scrollBlock={scrollBlock} />
              </Route>
              <Route exact path="/logout" component={Logout} />
            </div>
          </Switch>
        </Router>
      </Switch>
      <ToastContainer
        position="top-center"
        autoClose={3500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;

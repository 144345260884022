import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { getInitDate } from "../../../helper/helper";

const getStartEndDateFormated = (fromDate, toDate) => {
    var startDate = fromDate.getFullYear() + "-" + parseInt(fromDate.getMonth() + 1) + "-" + fromDate.getDate();
    var endDate = toDate.getFullYear() + "-" + (parseInt(toDate.getMonth()) + 1) + "-" + toDate.getDate();
    return { startDate, endDate };
}

export const SelectDateDropDown = (props) => { 
    const [dropdown, setDropdown] = useState(false);
    const labelDate = getInitDate(new Date())
    const [dateLabel, setDateLabel] = useState(`From ${labelDate.sDate} To ${labelDate.eDate}`);
    const [state, setState] = useState({
        selection: {
            startDate: new Date(labelDate.sDate),
            endDate: new Date(labelDate.eDate),
            key: 'selection'
        }
    });

    function onDropdown (){ 
        setDropdown(!dropdown);
    }

    const onApplyDateRange = () => { 
        const newDates = getStartEndDateFormated(state.selection.startDate, state.selection.endDate)
        setDateLabel(`From ${newDates.startDate} To ${newDates.endDate}`);
        props.OnDateRangeChange(newDates);
        setDropdown(!dropdown);
    }
    
    return (
        <div className="select-date-dropdown">
            <button type="button" onClick={() => onDropdown()} className="select-date-dropbtn">{dateLabel.toString()}</button>
            <div id="myDropdown" className={`select-date-dropdown-content ${dropdown && 'select-date-show'} ${props.onPosition === "left" && 'select-date-left'}`}>
                <DateRangePicker
                    onChange={item => setState({ ...state, ...item })}
                    months={1}
                    direction="vertical"
                    scroll={{ enabled: true }}
                    ranges={[state.selection]}
                />
                <div className="select-date-dropdown-footer">
                    <button type="button" onClick={onApplyDateRange} className="select-date-applyBtn">Apply</button>
                    <button type="button" onClick={onDropdown} className="select-date-cancelBtn">Close</button>
                </div>
            </div>
        </div>
    )
}
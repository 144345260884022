import React, { useState, useEffect } from "react";
import Switch from "react-switch";
// import AsyncSelect from "react-select/async";
import axiosMiddleware from "../../../helper/axios";
import useForm from "../../../helper/useForm";
import ValidateRules from "../../../helper/ValidateRules";
import { toast } from "react-toastify";
import "./addBrand.css";

function AddBanner(props) {
  const [isImageChange, setIsImageChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Loading...");
  const { values, errors, handleChange, handleSubmit, initialValues } = useForm(
    handleBannerForm,
    ValidateRules.Banner
  );
//   console.log(values);
  
  useEffect(() => {
    initialValues({
      bannerId: props.cellBanner.id || null,
      bannerName: props.cellBanner.name || "",
      bannerImageURL: props.cellBanner.image || null,
      bannerDescription: props.cellBanner.description || "",
      bannerStatus: parseInt(props.cellBanner.isActive) ? true : false,
      bannerIsFeatured: parseInt(props.cellBanner.isFeatured) ? true : false,
    });
    setIsEdited(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cellBanner]);

  function loadImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      const output = document.getElementById("output");
      output.src = reader.result;
      handleChange("", "file", "bannerImageURL", reader.result);
      setIsImageChange(true);
      setIsEdited(true);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  function handleClose() {
    if(props.setTrigger){props.setTrigger(false);}
    if(props.setCellBanner){props.setCellBanner({});}
  }

  function handleBannerForm() {
    setIsLoading(true);
    const submit = {
      name: values.bannerName,
      image: isImageChange && values.bannerImageURL,
      status: `${values.bannerStatus ? 1 : 0}`,
      isFeatured: `${values.bannerIsFeatured ? 1 : 0}`,
      description: `${values.bannerDescription}`,
    };
    if (values.bannerId !== null) {
      editBanner(values.bannerId, submit);
    } else {
      addBanner(submit);
    }
  }

  async function addBanner(data) {
    await axiosMiddleware
      .post("banner", data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.setTrigger(false);
          setIsLoading(false);
          window.location.reload(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Unable to Add Banner, Please try again or contact Technical Team.");
        console.log(e);
      });
  }

  async function editBanner(id, data) {
    await axiosMiddleware
      .put(`banner/${id}`, data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.onRowUpdate(res.data.Data, props.bannerIndex);
          props.setTrigger(false);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Unable to Edit Banner, Please try again or contact Technical Team.",{autoClose:3000});
        console.log(e);
      });
  }

  return props.trigger ? (
    <div className="global-popup">
      <div className="addProductPopup">
      <div className="addPopupinner fade-in-up">
            <div className="addProduct-heading border-bottom">
              <h2 className="popup-heading" style={{ marginTop: "7px" }}>
                Add Banner
              </h2>
              <span>
                <a onClick={handleClose} href="#close">
                  <img src="./Image/closepopup-icon.svg" alt="Close Icon" />
                </a>
              </span>
            </div>
            <div className="popupInnerBody">
              <form className="fomik-form" onSubmit={handleSubmit} noValidate>
                <div className="textfield-div">
                  <label className="activeUser-label" htmlFor="bannerName">
                    <span>Name</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={`form-control shadow-none input-class ${
                        errors.bannerName && "is-danger"
                    }`}
                    style={{ width: "400px" }}
                    value={values.bannerName}
                    onChange={(e) => {
                      handleChange(e, "text", "", "");
                      setIsEdited(true);
                    }}
                    placeholder="Enter Banner name"
                    name="bannerName"
                    required
                  />
                  {errors.bannerName && (
                    <p className="help is-danger">{errors.bannerName}</p>
                  )}
                </div>
                <div className="textfield-div">
                  <label
                    className="activeUser-label"
                    htmlFor="bannerDescription"
                  >
                    <span>Description</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={`form-control shadow-none input-class`}
                    style={{ width: "400px" }}
                    value={values.bannerDescription}
                    onChange={(e) => {
                      handleChange(e, "text", "", "");
                      setIsEdited(true);
                    }}
                    placeholder="Enter description"
                    name="bannerDescription"
                  />
                </div>
                <div className="d-flex justify-content-between">
                    <label className="activeUser-label">
                        <span>Status</span>
                        <Switch
                            name="bannerStatus"
                            onChange={(e) => {
                                handleChange("", "switch", "bannerStatus", e);
                                setIsEdited(true);
                            }}
                            checked={values.bannerStatus}
                        />
                    </label>
                    <label className="activeUser-label">
                        <span>Featured</span>
                        <Switch
                            name="bannerIsFeatured"
                            onChange={(e) => {
                                handleChange("", "switch", "bannerIsFeatured", e);
                                setIsEdited(true);
                            }}
                            checked={values.bannerIsFeatured}
                        />
                    </label>
                </div>
                <div
                  className="image-upload"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px",
                    paddingLeft:"0px",
                  }}
                >
                  <label className="image-upload-label" htmlFor="">
                    Upload Image
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="fileUpload"
                    accept="image/*"
                    onChange={loadImage}
                    required
                    className={`form-control shadow-none input-class ${
                      errors.bannerImageURL && "is-danger"
                    }`}
                  />
                  {errors.bannerImageURL && (
                    <p className="help is-danger" style={{padding:"0px 0px 2px 0px"}}>{errors.bannerImageURL}</p>
                  )}
                  <img
                    id="output"
                    style={{
                      marginTop: "1px",
                      width: "401px",
                      height:"200px",
                    }}
                    src={values.bannerImageURL}
                    alt=""
                  />
                </div>
                <div
                  className=" border-bottom"
                  style={{ width: "500px", marginLeft: "-48px" }}
                ></div>
                <div className="addProductFooter d-flex">
                  <button
                    type="reset"
                    className="footer-cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <div className="footerBtn">
                    <button
                      type="submit"
                      className={`btn primary ${
                        isLoading || !isEdited ? "disabledButton" : "small"
                      }`}
                      disabled={isLoading || !isEdited ? true : false}
                    >
                      {isLoading ? loadingMsg : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default AddBanner;

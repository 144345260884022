import { useEffect, useState } from 'react';
import axiosMiddleware from '../../../helper/axios';
import useForm from '../../../helper/useForm';
import ValidateRules from "../../../helper/ValidateRules";
import _forEach from 'lodash/forEach';
import "./style.css";
import { toast } from 'react-toastify';

const ContactSetting = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState("Saving...");
    const [isSettingsData, setIsSettingsData] = useState(false);
    const [settingsData, setSettingsData] = useState();
    const [isSettingActive, setIsSettingActive] = useState();

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        initialValues,
      } = useForm(handleSettingsForm, ValidateRules.Settings);

    useEffect(() => {
        initialValues({
            whatsAppMobileNumber: settingsData?.whatsAppMobileNumber || "",
            callContactNumber: settingsData?.callContactNumber || "",
            phoneCallOpeningHours: settingsData?.phoneCallOpeningHours || "",
            chatOpeningHours: settingsData?.chatOpeningHours || "",
            facebookPageUrl: settingsData?.facebookPageUrl || "",
            twitterPageUrl: settingsData?.twitterPageUrl || "",
            instagramPageUrl: settingsData?.instagramPageUrl || "",
            linkedInPageUrl: settingsData?.linkedInPageUrl || "",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSettingsData]);
    
    

    const getSettings = async () => {
        setIsLoading(true);
        await axiosMiddleware.get('setting/contact-setting').then((res)=>{
            const totalCount = (res.data.Data || []).length;
            if(totalCount > 0){
                let settingTempArr = [];
                let settingActiveTempArr = [];
                _forEach(res.data.Data, (val, index)=>{
                    settingTempArr[val.metaKey] = val.metaValue;
                    if(parseInt(val.isActive)){
                        settingActiveTempArr[val.metaKey] = true;
                    }else{
                        settingActiveTempArr[val.metaKey] = false;
                    }
                });
                console.log('settingActiveTempArr', settingActiveTempArr);
                setSettingsData({...settingTempArr});
                setIsSettingActive({...settingActiveTempArr});
                setIsSettingsData(true);
                toast.success("Success...! Contact Settings loaded.")
                setIsLoading(false);
            }
        }).catch((error)=>{
            setSettingsData([]);
            setIsSettingActive([]);
            setIsSettingsData(false);
            toast.error("Error...! Something went wrong, unable to load Conatct Settings.");
            setIsLoading(false);
            console.log('error', error);
        });
    }

    useEffect(() => {
        getSettings();
    }, []);

    function handleSettingsForm(){
        setIsLoading(true);
        let data = {
            whatsAppMobileNumber: values?.whatsAppMobileNumber,
            callContactNumber: values?.callContactNumber,
            phoneCallOpeningHours: values?.phoneCallOpeningHours,
            chatOpeningHours: values?.chatOpeningHours,
            facebookPageUrl: values?.facebookPageUrl,
            twitterPageUrl: values?.twitterPageUrl,
            instagramPageUrl: values?.instagramPageUrl,
            linkedInPageUrl: values?.linkedInPageUrl,
        }
        saveSettings(data);
    }

    const saveSettings = async (data) => {
        await axiosMiddleware.post('setting/contact-setting', data).then((res)=>{
            if(res.data.Status){
                toast.success(res.data.Message);
            }else{
                toast.error(res.data.Message);
            }
            setIsLoading(false);
        }).catch((error)=>{
            toast.error("Error...! Something went wrong, unable to save Contact Settings.");
            setIsLoading(false);
            console.log('error', error);
        });
    }

    return (
        <div className="d_card_container">
            <div className="d_card">
                <div className="d_card_header">
                    <div>Contact Settings</div>                    
                </div>
                <div className="d_card_body">
                    <form className="settingsForm fomik-form" onSubmit={handleSubmit} noValidate>
                        <div className="form-body">
                            <div className="form-section">
                                <h3 className="form-heading">Contact Numbers</h3>
                                <div className="input">
                                    <span className="input-span">Mobile Number for Whatsapp</span>
                                    <input
                                    disabled={!isSettingActive?.whatsAppMobileNumber}
                                    autoComplete="off"
                                    placeholder="Mobile Number"
                                    name="whatsAppMobileNumber"
                                    type="tel"
                                    className={`form-control shadow-none input-class`}
                                    value={values?.whatsAppMobileNumber}
                                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                                    />
                                    {errors?.whatsAppMobileNumber && (<p className="help is-danger">{errors?.whatsAppMobileNumber}</p>)}
                                </div>
                                <div className="input">
                                    <span className="input-span">Contact Number for Calls</span>
                                    <input
                                    autoComplete="off"
                                    placeholder="Contact Number"
                                    name="callContactNumber"
                                    type="tel"
                                    className={`form-control shadow-none input-class`}
                                    value={values?.callContactNumber}
                                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                                    />
                                    {errors?.callContactNumber && (<p className="help is-danger">{errors?.callContactNumber}</p>)}
                                </div>
                            </div>
                            <div className="form-section">
                                <h3 className="form-heading">Contact Opening Hours</h3>
                                <div className="input">
                                    <span className="input-span">Phone Calls (e.g. Monday-Sunday: 10:00 am - 07:00 pm)</span>
                                    <input
                                    autoComplete="off"
                                    placeholder="e.g. Monday-Sunday: 10:00 am - 07:00 pm"
                                    name="phoneCallOpeningHours"
                                    type="text"
                                    className={`form-control shadow-none input-class`}
                                    value={values?.phoneCallOpeningHours}
                                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                                    />
                                    {errors?.phoneCallOpeningHours && (<p className="help is-danger">{errors?.phoneCallOpeningHours}</p>)}
                                </div>
                                <div className="input">
                                    <span className="input-span">Chat (e.g. Monday-Sunday: 10:00 am - 07:00 pm)</span>
                                    <input
                                    autoComplete="off"
                                    placeholder="e.g. Monday-Sunday: 10:00 am - 07:00 pm"
                                    name="chatOpeningHours"
                                    type="text"
                                    className={`form-control shadow-none input-class`}
                                    value={values?.chatOpeningHours}
                                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                                    />
                                    {errors?.chatOpeningHours && (<p className="help is-danger">{errors?.chatOpeningHours}</p>)}
                                </div>
                            </div>
                            <div className="form-section">
                                <h3 className="form-heading">Social Details</h3>
                                <div className="input">
                                    <span className="input-span">Enter Facebook Page URL</span>
                                    <input
                                    autoComplete="off"
                                    placeholder="Your Facebook Page URL"
                                    name="facebookPageUrl"
                                    type="url"
                                    className={`form-control shadow-none input-class`}
                                    value={values?.facebookPageUrl}
                                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                                    />
                                    {errors?.facebookPageUrl && (<p className="help is-danger">{errors?.facebookPageUrl}</p>)}
                                </div>
                                <div className="input">
                                    <span className="input-span">Enter Twitter Page URL</span>
                                    <input
                                    autoComplete="off"
                                    placeholder="Your Twitter Page URL"
                                    name="twitterPageUrl"
                                    type="url"
                                    className={`form-control shadow-none input-class`}
                                    value={values?.twitterPageUrl}
                                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                                    />
                                    {errors?.twitterPageUrl && (<p className="help is-danger">{errors?.twitterPageUrl}</p>)}
                                </div>
                                <div className="input">
                                    <span className="input-span">Enter Instagram Page URL</span>
                                    <input
                                    autoComplete="off"
                                    placeholder="Your Instagram Page URL"
                                    name="instagramPageUrl"
                                    type="url"
                                    className={`form-control shadow-none input-class`}
                                    value={values?.instagramPageUrl}
                                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                                    />
                                    {errors?.instagramPageUrl && (<p className="help is-danger">{errors?.instagramPageUrl}</p>)}
                                </div>
                                <div className="input">
                                    <span className="input-span">Enter LinkedIn Page URL</span>
                                    <input
                                    autoComplete="off"
                                    placeholder="Your LinkedIn URL"
                                    name="linkedInPageUrl"
                                    type="url"
                                    className={`form-control shadow-none input-class`}
                                    value={values?.linkedInPageUrl}
                                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                                    />
                                    {errors?.linkedInPageUrl && (<p className="help is-danger">{errors?.linkedInPageUrl}</p>)}
                                </div>
                            </div>
                        </div>
                        <div className="form-footer">
                        <button type="submit" className={`btn primary ${isLoading || !isEdited ? "disabledButton" : "small"}`} disabled={isLoading || !isEdited ? true : false}>{isLoading ? loadingMsg : "Save Settings"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ContactSetting;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import useForm from "../../../helper/useForm";
import ValidateRules from "../../../helper/ValidateRules";
import axiosMiddleware from "../../../helper/axios";
import { iDToValueResolveAsyncSelect } from "../../../helper/helper";
import "./addRequest.css";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddRequest(props) {
  const [allParentCat, setAllParentCat] = useState([]);
  const [isAllParentCat, setIsAllParentCat] = useState(false);
  const [allSubCat, setAllSubCat] = useState([]);
  const [isAllSubCat, setIsAllSubCat] = useState(false);
  const [allBrands, setAllBrands] = useState();
  const [isAllBrands, setIsAllBrands] = useState(false);
  const [allCutomer, setAllCutomer] = useState([]);
  const [isAllCustomer, setIsAllCustomer] = useState(false);
  const [isBrandDisabled, setisBrandDisabled] = useState(false);
  const [isSubCatDisabled, setisSubCatDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Loading...");
  const brandRef = useRef();
  const subCatRef = useRef();
  const mainCatRef = useRef();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    initialValues,
    setValues,
  } = useForm(handleReqForm, ValidateRules.RequestPage);

  const loadAllCutomer = async (searchText) => {
    await axiosMiddleware
      .get(`user?searchText=${searchText}`)
      .then((res) => {
        let asyncSortedValues = iDToValueResolveAsyncSelect(res.data.Data, [
          "firstName",
          "lastName",
        ]);
        setAllCutomer(asyncSortedValues);
        setIsAllCustomer(true);
      })
      .catch((e) => {
        toast.error("Error...! Something went wrong, Unable to load Customer list.");
        // console.log(e);
      });
  };

  const loadAllCategory = async (searchText) => {
    await axiosMiddleware
      .get(
        `category?searchText=${searchText}&limit=&offset=&sortBy=&orderTo=&parentId=0`
      )
      .then((res) => {
        let asyncSortedValues = iDToValueResolveAsyncSelect(res.data.Data);
        asyncSortedValues.unshift({value:"-1", label:"Other"});
        setAllParentCat(asyncSortedValues);
        setIsAllParentCat(true);
      })
      .catch((e) => {
        toast.error("Error...! Something went wrong, Unable to load Category list.");
        // console.log(e);
      });
  };

  const loadAllSubCategory = async (searchText, parentCatId) => {
    if (parentCatId !== "") {
      await axiosMiddleware
        .get(
          `category?searchText=${searchText}&limit=&offset=&sortBy=&orderTo=&parentId=${parentCatId}`
        )
        .then((res) => {
          let asyncSortedValues = iDToValueResolveAsyncSelect(res.data.Data);
          asyncSortedValues.unshift({value:"-1", label:"Other"});
          setAllSubCat(asyncSortedValues);
          setIsAllSubCat(true);
          setisSubCatDisabled(false);
        })
        .catch((e) => {
          toast.error("Error...! Something went wrong, Unable to load Sub Category list.");
          // console.log(e);
        });
    } else {
      setisSubCatDisabled(true);
      setIsAllSubCat(true);
    }
  };

  const loadAllBrands = async (searchText, categoryIds) => {
    if (categoryIds !== "") {
      await axiosMiddleware
        .get(
          `brand?searchText=${searchText}&limit=&offset=&sortBy=&orderTo=&categoryIds=${categoryIds}`
        )
        .then((res) => {
          let asyncSortedValues = iDToValueResolveAsyncSelect(res.data.Data);
          asyncSortedValues.unshift({value:"-1", label:"Other"});
          setAllBrands(asyncSortedValues);
          setIsAllBrands(true);
          setisBrandDisabled(false);
        })
        .catch((e) => {
          toast.error("Error...! Something went wrong, Unable to load Brand list.");
          console.og(e);
        });
    } else {
      setisBrandDisabled(true);
      setIsAllBrands(true);
    }
  };

  useEffect(() => {
    let parsedDate = Date.parse(props.selectedIndexData.expectedDeliveryDate);
    let expDate = "";
    if (
      isNaN(props.selectedIndexData.expectedDeliveryDate) &&
      !isNaN(parsedDate)
    ) {
      expDate = new Date(props.selectedIndexData.expectedDeliveryDate);
    }
    if (!props.isAddNew) {
      initialValues({
        id: props.selectedIndexData.id,
        brandId: props.selectedIndexData.brandId,
        mainCategoryId: props.selectedIndexData.mainCategoryId, // main categoty
        categoryId: props.selectedIndexData.categoryId, //sub category
        quantity: props.selectedIndexData.quantity,
        lastCheckedPrice: props.selectedIndexData.lastCheckedPrice,
        expectedDeliveryDate: expDate,
        customerId: props.selectedIndexData.customer.id,
        notes: props.selectedIndexData.notes,
        modelNumber: props.selectedIndexData.modelNumber,
      });
    } else {
      initialValues({
        id: null,
      });
    }
    setIsEdited(false);
    loadAllCategory(""); // Main Category
    loadAllCutomer("");
    loadAllSubCategory(
      "",
      props.selectedIndexData.mainCategoryId
        ? props.selectedIndexData.mainCategoryId
        : ""
    ); //Sub Category
    loadAllBrands(
      "",
      props.selectedIndexData.categoryId
        ? props.selectedIndexData.categoryId
        : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cellProduct]);

  useEffect(()=>{
    if(props.newCatObject){
      setValues({...values, mainCategoryId:props.newCatObject.id});
      setTimeout(()=>{
        loadAllCategory(""); // Main Category
        loadAllSubCategory("", values.mainCategoryId); // Sub Category
      },500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.newCatObject]);
  
  useEffect(()=>{
    if(props.newSubCatObject){
      setValues({...values, categoryId:props.newSubCatObject.id});
      setTimeout(()=>{
        loadAllSubCategory("", values.mainCategoryId); // Sub Category
        loadAllBrands("", values.categoryId); // Brands
      },500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.newSubCatObject]);

  useEffect(()=>{
    if(props.newBrandObject){
      setValues({...values, brandId:props.newBrandObject.id});
      setTimeout(()=>{
        loadAllBrands("", values.categoryId); // Brands
      },500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.newBrandObject]);

  function handleReqForm() {
    setIsLoading(true);
    let expDate = new Date(values.expectedDeliveryDate);
    const data = {
      mainCategoryId: `${values.mainCategoryId}`,
      categoryId: `${values.categoryId}`,
      brandId: `${values.brandId}`,
      modelNumber: `${values.modelNumber}`,
      notes: `${values.notes}`,
      quantity: `${values.quantity}`,
      lastCheckedPrice: `${values.lastCheckedPrice}`,
      expectedDeliveryDate: `${
        expDate.getFullYear() +
        "-" +
        (parseInt(expDate.getMonth()) + 1) +
        "-" +
        expDate.getDate()
      }`,
      userId: `${values.customerId}`,
    };
    if (values.id !== null) {
      editRequest(data);
    } else {
      addRequest(data);
    }
  }

  const addRequest = async (data) => {
    await axiosMiddleware
      .post(`request`, data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          // props.onRowInsert(res.data.Data);
          setIsLoading(false);
          props.onClose();
          window.location.reload(true);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Something went wrong, Unable to Add Request.");
        // console.log(e);
      });
  };

  const editRequest = async (data) => {
    await axiosMiddleware
      .put(`request/${values.id}`, data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.onRowUpdate(res.data.Data, props.ReqIndex);
          props.onClose();
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Something went wrong, Unable to Edit Request.");
        // console.log(e);
      });
  };

  const filterSelect = (inputValue, filterFor) => {
    let filterObj = ``;
    switch (filterFor) {
      case "parent":
        filterObj = allParentCat;
        break;
      case "subCat":
        filterObj = allSubCat;
        break;
      case "brands":
        filterObj = allBrands;
        break;
      case "customer":
        filterObj = allCutomer;
        break;
      default:
        break;
    }

    return filterObj.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleOnParentCatChange = (e) => {
    if (e) {
      loadAllSubCategory("", e.value);
      setisSubCatDisabled(false);
    } else {
      subCatRef.current.setValue(null);
      setisSubCatDisabled(true);
      brandRef.current.setValue(null);
      setisBrandDisabled(true);
      setValues({ ...values, categoryId: null, brandId: null });
    }
  };

  const loadBrandByCatID = (e) => {
    if (e !== null) {
      loadAllBrands("", e.value);
      setisBrandDisabled(false);
    } else {
      setValues({ ...values, brandId: null });
      brandRef.current.setValue(null);
      setisBrandDisabled(true);
    }
  };

  const handleCategoryBrandChange = (cateType, event) => {
    switch (cateType) {
      case 'mainCategoryId': if(event && event.value === "-1"){
          handleOtherSelected('Catgeory');
        }else{
          handleChange("", "reactSelect", "mainCategoryId", event);
          handleOnParentCatChange(event);
          setIsEdited(true);
          props.setNewCatObject(event && {id: event.value, name: event.label});
        }
      break;
      case 'categoryId': if(event && event.value === "-1"){
          handleOtherSelected('Subcatgeory');
        }else{
          handleChange("", "reactSelect", "categoryId", event);
          loadBrandByCatID(event);
          setIsEdited(true);
          props.setNewSubCatObject(event && {id: event.value, name: event.label});
        }
      break;
      case 'brandId': if(event && event.value === "-1"){
        handleOtherSelected('Brand');
        }else{
          handleChange("", "reactSelect", "brandId", event);
          setIsEdited(true);
          props.setNewBrandObject(event && {id: event.value, name: event.label});
        }
      break;
      default: toast.error("Ooops...! Something went wrong.");
        break;
    }
  }

  const handleOtherSelected = (catName) => {
    swal({
      title: `Do you want to add new ${catName}`,
      text: `Yes, Open add new ${catName} form.`,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      
      if (value) {
        switch (catName) {
          case 'Catgeory': mainCatRef.current.setValue(null); loadAllCategory(""); props.onOtherSelected('Category');
          break;
          case 'Subcatgeory': props.onOtherSelected("Subcategory");
          break;
          case 'Brand': props.onOtherSelected("Brand");
          break;
          default: props.onOtherSelected('Category');
            break;
        }
      }else{
        switch (catName) {
          case 'Catgeory': 
          mainCatRef.current.setValue(null);
          subCatRef.current.setValue(null);
          setisSubCatDisabled(true);
          brandRef.current.setValue(null);
          setisBrandDisabled(true);
          setValues({ ...values, categoryId: null, brandId: null });
          break;

          case 'Subcatgeory': 
          subCatRef.current.setValue(null);
          setValues({ ...values, brandId: null });
          brandRef.current.setValue(null);
          setisBrandDisabled(true);
          break;

          case 'Brand': 
          setValues({ ...values, brandId: null });
          brandRef.current.setValue(null);
          break;
          
          default:toast.error("Error...! Something went wrong, try again");
          break;
        }
      }
    });
  }

  const loadParentCatInToSelect = (inputValue, callback) => {
    loadAllCategory(inputValue);
    callback(filterSelect(inputValue, "parent"));
  };
  const loadSubCatDataInToSelect = (inputValue, callback) => {
    loadAllSubCategory(inputValue, values.mainCategoryId);
    callback(filterSelect(inputValue, "subCat"));
  };
  const loadBrandsInToSelect = (inputValue, callback) => {
    loadAllBrands(inputValue, values.brandId);
    callback(filterSelect(inputValue, "brands"));
  };
  const loadCustomerInToSelect = (inputValue, callback) => {
    loadAllCutomer(inputValue);
    callback(filterSelect(inputValue, "customer"));
  };

  return (
    <>
    <div className="global-popup">
      <div className="addRequestPopup">
        <div className="addPopupInner">
          <div className="request-heading border-bottom">
            <h2 className="popup-heading" style={{ marginTop: "5px" }}>
              Add Request
            </h2>
            <span>
              <a onClick={props.onClose}>
                <img src="./Image/closepopup-icon.svg" alt="Close" />
              </a>
            </span>
          </div>
          <div className="popupBody">
            <form onSubmit={handleSubmit} noValidate>
              <div className="select-customer">
                <span className="select-customer-span">Select Customer</span>
                {isAllCustomer && (
                  <AsyncSelect
                    required
                    className={`${errors.customerId && "is-danger"}`}
                    name="customerId"
                    loadOptions={loadCustomerInToSelect}
                    defaultOptions={allCutomer}
                    isClearable={true}
                    defaultValue={
                      values.customerId &&
                      allCutomer[
                        allCutomer.findIndex(
                          (item) => item.value === values.customerId
                        )
                      ]
                    }
                    onChange={(e) => {
                      handleChange("", "reactSelect", "customerId", e);
                      setIsEdited(true);
                    }}
                    placeholder="Select/Search Customer...."
                  />
                )}
                {errors.customerId && (
                  <p className="help is-danger">{errors.customerId}</p>
                )}
              </div>
              <div className="category-select" style={{ marginBottom: "10px" }}>
                <span className="select-span">Category</span>
                {isAllParentCat && (
                  <AsyncSelect
                    required
                    className={`${errors.mainCategoryId && "is-danger"}`}
                    name="mainCategoryId"
                    loadOptions={loadParentCatInToSelect}
                    defaultOptions={allParentCat}
                    isClearable={true}
                    ref={mainCatRef}
                    value={
                      values.mainCategoryId &&
                      allParentCat[
                        allParentCat.findIndex(
                          (item) => item.value === values.mainCategoryId
                        )
                      ]
                    }
                    onChange={(e) => {
                      handleCategoryBrandChange("mainCategoryId", e);
                    }}
                    placeholder="Select/Search Category...."
                  />
                )}
                {errors.mainCategoryId && (
                  <p className="help is-danger">{errors.mainCategoryId}</p>
                )}
              </div>
              <div className="category-select" style={{ marginBottom: "10px" }}>
                <span className="select-span">SubCategory</span>
                {isAllSubCat && (
                  <AsyncSelect
                    required
                    className={`${errors.categoryId && "is-danger"}`}
                    name="categoryId"
                    loadOptions={loadSubCatDataInToSelect}
                    defaultOptions={allSubCat}
                    isClearable={true}
                    isDisabled={isSubCatDisabled}
                    ref={subCatRef}
                    value={
                      values.categoryId &&
                      values.categoryId !== null &&
                      allSubCat[
                        allSubCat.findIndex(
                          (item) => item.value === values.categoryId
                        )
                      ]
                    }
                    onChange={(e) => {
                      handleCategoryBrandChange("categoryId", e);
                    }}
                    placeholder="Select/Search Sub Category...."
                  />
                )}
                {errors.categoryId && (
                  <p className="help is-danger">{errors.categoryId}</p>
                )}
              </div>
              <div className="category-select" style={{ marginBottom: "10px" }}>
                <span className="select-span">Brand</span>
                {isAllBrands && (
                  <AsyncSelect
                    required
                    isDisabled={isBrandDisabled}
                    className={`${errors.brandId && "is-danger"}`}
                    name="brandId"
                    loadOptions={loadBrandsInToSelect}
                    defaultOptions={allBrands}
                    isClearable={true}
                    ref={brandRef}
                    value={
                      values.brandId &&
                      values.brandId !== null &&
                      allBrands[
                        allBrands.findIndex(
                          (item) => item.value === values.brandId
                        )
                      ]
                    }
                    onChange={(e) => {
                      handleCategoryBrandChange("brandId", e);
                    }}
                    placeholder="Select/Search Brand...."
                  />
                )}
                {errors.brandId && (
                  <p className="help is-danger">{errors.brandId}</p>
                )}
              </div>
              <div className="textfield-div">
                <label className="activeUser-label" htmlFor="modelNumber">
                  <span>Model Number:</span>
                </label>
                <input
                  autoComplete="off"
                  className={`form-control ${
                    errors.modelNumber && "is-danger"
                  }`}
                  style={{ width: "400px", fontSize: "0.9rem", color: "hsl(0, 0 %, 50 %)", marginTop: "5px"}}
                  value={values.modelNumber}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                  placeholder="Enter Model Number..."
                  name="modelNumber"
                  required
                  min="0"
                />
                {errors.modelNumber && (
                  <p className="help is-danger">{errors.modelNumber}</p>
                )}
              </div>
              <div className="quantity" style={{ marginBottom: "8px" }}>
                <span className="select-span">Quantity</span>
                <input
                  min="1"
                  required
                  type="number"
                  name="quantity"
                  className={`form-control number-input ${
                    errors.quantity && "is-danger"
                  }`}
                  value={values.quantity}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                  placeholder="Enter Quantity..."
                  style={{ width: "400px", fontSize: "0.9rem", color: "hsl(0, 0 %, 50 %)", marginTop:"5px" }}
                />
                {errors.quantity && (
                  <p className="help is-danger">{errors.quantity}</p>
                )}
              </div>
              <div
                className="last-check-price"
                style={{ marginBottom: "10px" }}
              >
                <span className="select-span">Last Checked Price</span>
                <input
                  required
                  min="1"
                  type="number"
                  name="lastCheckedPrice"
                  className={`form-control number-input ${
                    errors.lastCheckedPrice && "is-danger"
                  }`}
                  value={values.lastCheckedPrice}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                  placeholder="Enter Last Checked Price..."
                  style={{ width: "400px", fontSize: "0.9rem", color: "hsl(0, 0 %, 50 %)", marginTop: "5px" }}
                />
                {errors.lastCheckedPrice && (
                  <p className="help is-danger">{errors.lastCheckedPrice}</p>
                )}
              </div>
              <div style={{ marginBottom: "5px" }}>
                <span className="select-span">Expected Delivery Date</span>
                <DatePicker
                  required
                  name="expectedDeliveryDate"
                  placeholderText="Select Expected Delivery Date...."
                  selected={values.expectedDeliveryDate}
                  onChange={(e) => {
                    handleChange("", "dateTime", "expectedDeliveryDate", e);
                    setIsEdited(true);
                  }}
                  className="date-picker form-control"
                  wrapperClassName="datepicker-input"
                  minDate={new Date()}
                  style={{ width: "400px", fontSize: "0.9rem", color: "hsl(0, 0 %, 50 %)", marginTop: "5px" }}
                  // withPortal
                />
                {errors.expectedDeliveryDate && (
                  <p className="help is-danger">
                    {errors.expectedDeliveryDate}
                  </p>
                )}
              </div>
              <div className="textfield-div">
                <label className="activeUser-label" htmlFor="notes">
                  <span>Note:</span>
                </label>
                <input
                  autoComplete="off"
                  className={`form-control ${errors.notes && "is-danger"}`}
                  style={{ width: "400px", fontSize: "0.9rem", color: "hsl(0, 0 %, 50 %)", marginTop: "5px" }}
                  value={values.notes}
                  onChange={(e) => {
                    handleChange(e, "text", "", "");
                    setIsEdited(true);
                  }}
                  placeholder="Enter Note..."
                  name="notes"
                />
                {errors.notes && (
                  <p className="help is-danger">{errors.notes}</p>
                )}
              </div>
              <div
                className=" border-bottom"
                style={{ width: "500px", marginLeft: "-48px" }}
              ></div>
              <div className="footer-content">
                <button
                  type="reset"
                  className="footer-cancel"
                  onClick={props.onClose}
                >
                  Cancel
                </button>
                <div className="footerBtn">
                  <button
                    type="submit"
                    className={`btn primary ${
                      isLoading || !isEdited ? "disabledButton" : "small"
                    }`}
                    disabled={isLoading || !isEdited ? true : false}
                  >
                    {isLoading ? loadingMsg : "Send Request"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AddRequest;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import * as FaIcons from "react-icons/fa";
import _concat from "lodash/concat";
import _get from "lodash/get";
import _forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import axiosMiddleware from "../../../helper/axios";
import AssignSeller from "../pop-up-model-pages/AssignSeller";
import AddRequest from "../pop-up-model-pages/AddRequest";
import Details from "../pop-up-model-pages/Details";
import "./request.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import EmptyList from "../EmptyState/EmptyList";
import { useDebounce } from "../../../helper/UseDebounce";
import AddCategory from "../pop-up-model-pages/AddCategory";
import AddSubCategory from "../pop-up-model-pages/AddSubCategory";
import AddBrand from "../pop-up-model-pages/AddBrand";
import Switch from "react-switch";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import useForm from "../../../helper/useForm";
import ValidateRules from "../../../helper/ValidateRules";
import { iDToValueResolveAsyncSelect } from "../../../helper/helper";
import _isEmpty from "lodash/isEmpty";
import { iDToValueResolveAsyncSelectAllCol } from "../../../helper/helper";
import SkeletonLoading from "../../../components/SkeletonLoading";

function Request(props) {
  const [hasMore, setHasMore] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(15);
  const [offSet, setOffSet] = useState(0);
  const [requestData, setRequestData] = useState([]);
  const [isRequestData, setIsRequestData] = useState(false);
  const [showAssignSellerModal, setShowAssignSellerModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedIndexData, setSelectedIndexData] = useState([]);
  const [reqIndex, setReqIndex] = useState(-1);
  const [searchFilter, setSearchFilter] = useState("");
  const [requestType, setRequestType] = useState(1);
  const userData = JSON.parse(sessionStorage.getItem("User-Info"));
  const [resCount, setResCount] = useState(1);
  const [roleId, setRoleId] = useState();
  const debouncedSearchTerm = useDebounce(searchFilter, 1000);
  const [endMsg, setEndMsg] = useState(true);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddSubCategory, setShowAddSubCategory] = useState(false);
  const [showAddBrand, setShowAddBrand] = useState(false);
  const [newCatObject, setNewCatObject] = useState();
  const [newSubCatObject, setNewSubCatObject] = useState();
  const [newBrandObject, setNewBrandObject] = useState();
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [allagentList, setAllAgentList] = useState([]);

  const { Option } = components;

const CustOption = (props) => (
    <Option {...props}>
        {`${props.data.label}`}
        <br />
        {`${props.data.email}`}
        <br />
        {`${props.data.contactNumber}`}
    </Option>
);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#767676",
      height: "40px",
      color: "#767676",
      lineHeight: "initial",
      marginRight: "10px",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "black",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("User-Info") === null ||
      !localStorage.getItem("ApiKey")
    ) {
      toast.error("Session Expired! Please login again...");
      window.location.replace("/");
    } else {
      setRoleId(userData.userRoleId);
    }
  }, []);

  useEffect(() => { 
      loadAgent();
  },[]);

  const makeUrl = () => {
    let url = `request?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&requestType=0&userRoleId=${roleId}`;
    if (requestType !== 0) {
      url = `request?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&requestType=${requestType}&userRoleId=${roleId}`;
    }
    if (selectedAgent.length > 0) {
      url = `request?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&requestType=${requestType}&agentIds=${selectedAgent.join(',')}&userRoleId=${roleId}`;
    }
    if (requestType !== 0 && selectedAgent.length > 0) {
      url = `request?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&requestType=${requestType}&agentIds=${selectedAgent.join(',')}&userRoleId=${roleId}`;
    }
    return url;
  };

  const loadRequestList = async () => {
    await axiosMiddleware
      .get(makeUrl())
      .then((res) => {
        const totalCount = (res.data.Data || []).length;
        if (!res.data.Status || totalCount == 0) {
          setHasMore(false);
          setRequestData([]);
          setIsRequestData(false);
          setResCount(0);
        }
        if (res.data.Status && totalCount === 15) {
          let preparedReqData = setIsSellerAssignedState(res.data.Data);
          setOffSet(offSet + limit);
          setRequestData(preparedReqData);
          setIsRequestData(true);
          setHasMore(true);
          setResCount(totalCount);
        }
        if (res.data.Status && totalCount > 0 && totalCount < 15) {
          let preparedReqData = setIsSellerAssignedState(res.data.Data);
          setOffSet(offSet + limit);
          setRequestData(preparedReqData);
          setIsRequestData(true);
          setHasMore(false);
          setEndMsg(false);
          if (isEmpty(res.data.Data)) {
            setResCount(0);
          } else {
            setResCount(totalCount);
          }
        }
      })
      .catch((e) => {
        toast.error("Error...! " + e);
        // console.log(err);
      });
  };

  const setIsSellerAssignedState = (data) => {
    const list = [];
    data.forEach((reqObj) => {
      if (!isEmpty(reqObj.seller)) {
        list.push({
          ...reqObj,
          isSellerAssigned: true,
        });
      } else {
        list.push(reqObj);
      }
    });

    return list;
  };

  useEffect(() => {
    loadRequestList();
  }, []);

  useEffect(() => {
    if (showAssignSellerModal || showAddEditModal || showDetailsModal) {
      document.body.classList.add('overflow-hidden')
      // props.scrollBlock(true);
    }
  }, [showAssignSellerModal, showAddEditModal, showDetailsModal]);

  function handleClick(cellIndex) {
    setReqIndex(cellIndex);
    setSelectedIndexData(requestData[cellIndex]);
    setShowAddEditModal(true);
  }

  function handleSearchChange(e) {
    setOffSet(0);
    setSearchFilter(e.target.value);
  }

  function handleDetailClick(cellIndex) {
    setSelectedIndexData(requestData[cellIndex]);
    setShowDetailsModal(true);
  }

  const handleFilterByAgent = (e) => {
    setOffSet(0)
    if(e && e.length > 0){
      setSelectedAgent(e);
    }else{
      setSelectedAgent([]);
    }
  }

  function handleAssignButton(cellIndex) {
    setSelectedIndexData(requestData[cellIndex]);
    setReqIndex(cellIndex);
    setShowAssignSellerModal(true);
  }

  const handleRowUpdate = (data, index) => {
    requestData[index] = data;
  };

  const handleSellerAssigned = (isAssigned, index, selectedSeller = "") => {
    if (isAssigned) {
      if(selectedSeller == ''){
        let tempReqIndexData = { ...requestData[index], isSellerAssigned: false };
        requestData[index] = tempReqIndexData;
      }
      else{
        let tempReqIndexData = { ...requestData[index], isSellerAssigned: true, seller:selectedSeller };
        requestData[index] = tempReqIndexData;
      }
    }
  };

  const handleCloseModal = (popupOf) => {
    switch (popupOf) {
      case "Details":
        setShowDetailsModal(false);
        document.body.classList.remove('overflow-hidden')
        // props.scrollBlock(false);
        break;
      case "AssignSeller":
        setShowAssignSellerModal(false);
        document.body.classList.remove('overflow-hidden')
        // props.scrollBlock(false);
        setSelectedIndexData([]);
        break;
      case "AddEditReq":
        if (newCatObject) {
          setNewCatObject([]);
          setNewSubCatObject([]);
          setNewBrandObject([]);
        }
        setShowAddEditModal(false);
        document.body.classList.remove('overflow-hidden')
        // props.scrollBlock(false);
        setSelectedIndexData([]);
        break;
      default:
        break;
    }
  };

  const fetchMoreData = async () => {
    await axiosMiddleware
      .get(makeUrl())
      .then((res) => {
        let dummyArray = _get(res, "data.Data", []);
        let preparedReqData = setIsSellerAssignedState(dummyArray);
        let fetchArray = _concat(requestData, preparedReqData);
        setRequestData(fetchArray);
        setOffSet(offSet + limit);
        if (dummyArray.length === 0) {
          setHasMore(false);
        }
      })
      .catch((e) => {
        toast.error("Error...! " + e);
        // console.log(err);
      });
  };

  useEffect(() => {
      loadRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType, debouncedSearchTerm, selectedAgent]);

  const handleRemoveButton = (reqId) => {
    let newrequestData = [];
    swal({
      title: "Are you sure?",
      text: "Once deleted, can not be restored!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        await axiosMiddleware
          .delete(`request/${reqId}`)
          .then((res) => {
            if (res.data.Status) {
              if (requestData.length === 1) {
                loadRequestList();
              } else {
                _forEach(requestData, (value, index) => {
                  if (value.id !== reqId) {
                    newrequestData.push(value);
                  }
                });
                setRequestData(newrequestData);
              }
              toast.success("Record Deleted Successfully");
            } else {
              toast.error("Error ! Something went wrong.");
            }
          })
          .catch((e) => {
            toast.error("Error...! Error in deleting the record.");
            // console.log(err);
          });
      }
    });
  };

  const handleRejectDelivered = async (action, reqId, index) => {
    let data = {};
    let msg = ``;
    let reqStatus = ``;
    const userId = JSON.parse(sessionStorage.getItem("User-Info"));
    // const reqData = { ...requestData[index], requestStatus: action === "reject" ? "Closed" : "Delivered" };
    if (action === "reject") {
      data = {
        requestStatusId: "2",
      };
      msg = `Once Marked as Rejected, can not be changed`;
      reqStatus = "Rejected";
    } else {
      data = {
        requestStatusId: "3",
      };
      msg = `Once Marked as Delivered, can not be changed`;
      reqStatus = "Delivered";
    }

    swal({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        await axiosMiddleware
          .put(`request/${reqId}/user/${userId.id}/request-status`, data)
          .then((res) => {
            if (res.data.Status) {
              window.location.reload();
              toast.success(
                `Request Status Changed to "${reqStatus}" Successfully`
              );
            }
          })
          .catch((e) => {
            toast.error("Error...! " + e);
            // console.log(err);
          });
      }
    });
  };

  const handleOnOtherSelected = (catType) => {
    if (catType === "Category") {
      setShowAddCategory(true);
    } else if (catType === "Subcategory") {
      setShowAddSubCategory(true);
    } else if (catType === "Brand") {
      setShowAddBrand(true);
    }
  };

  const searchAgent = (searchT = '')=>{
      return allagentList[0].options.filter((entry) =>
      (typeof entry.label === "string" && entry.label.toLowerCase().includes(searchT.toLowerCase())));
  }

  const loadAgent = async (searchT = '') => {
    let url = `agent?userRoleId=${roleId}`;
        await axiosMiddleware.get(url).then((res) => { 
          let newOption = iDToValueResolveAsyncSelectAllCol(res.data.Data, ["firstName", "lastName"]);
          // let newOption2 = iDToValueResolveAsyncSelect(res.data.Data, ["firstName","lastName","email","contactNumber"]);
          const options = [
              {
                  options: [
                      ...newOption
                  ]
              }
          ];
          setAgentList(options);
          setAllAgentList(options);
      }).catch((e) => { 
          console.log(e);
      });
}
const loadAgentInSelect = (inputValue, callback) => {
    inputValue != '' && callback(searchAgent(inputValue));
};
  
  const loadingArr = [];

  for (let index = 0; index < 6; index++) {
    loadingArr.push(<tr>
      <td colSpan="2"><SkeletonLoading/></td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      </tr>)
  }
  return (
    <div className="container-request">
      <div className="pannel-header">
        <div className="left-sec pannel-cols">
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <h2 className="request-title-heading">Request</h2>
          </div>
        </div>
        <div className="right-sec pannel-cols">
          <div className="right-sec">
            <button
              className="btn primary small"
              type="button"
              onClick={() => setShowAddEditModal(true)}
            >
              <div>
                <img src="./Image/plus_icon.png" alt="Plus" />
                <span>Add Request</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="search-field">
      <div className="filter-drop" style={{minWidth:"300px"}}>
            <AsyncSelect
                name="_filter_Agent"
                options={agentList}
                components={{ Option: CustOption }}
                loadOptions={loadAgentInSelect}
                defaultOptions={agentList}
                isClearable={true}
                styles={customStyles}
                // defaultValue={
                //     values.mainCategoryId &&
                //     allParentCat[
                //     allParentCat.findIndex(
                //         (item) => item.value === values.mainCategoryId
                //     )
                //     ]
                // }
                isMulti={true} // Add isMulti prop
                onChange={(selectedOptions) => {
                    handleFilterByAgent(selectedOptions.map(option => option.value));
                }}
                // onChange={(e) => {handleFilterByAgent(e)}}
                placeholder="Filter by Agent...."
            />
        </div>
        <div className="filter-drop">
          <select
            className={`small data-hj-allow`}
            onChange={(e) => {
              setRequestType(parseInt(e.target.value));
              setOffSet(0);
            }}
            name="requestType"
            placeholder="Filter by Request Status"
            defaultValue="1"
            // disabled={resCount === 0 && searchFilter === "" ? true : false}
          >
            <option value="0">Filter by Request Status</option>
            {/* <option value="0" selected>Filter by Request Status...</option> */}
            <option value="1">OPEN</option>
            <option value="2">CLOSED</option>
            <option value="3">DELIVERED</option>
          </select>
        </div>
        <div className="form-element-group search-frm">
          <input
            type="text"
            className={`small data-hj-allow ${
              resCount === 0 && searchFilter === "" && "disabled"
            }`}
            placeholder="Search Request...."
            onChange={handleSearchChange}
            disabled={resCount === 0 && searchFilter === "" ? true : false}
          />
        </div>
      </div>
      <div className="card-table table-wrapper">
        {resCount !== 0 ? (
          <InfiniteScroll
            dataLength={requestData.length}
            next={fetchMoreData}
            loader={
              <table className="table-main">
                
                  <tbody>
                      {loadingArr}
                  </tbody>
              </table>
             }
            hasMore={hasMore}
            endMessage={
              endMsg && (
                <p style={{ textAlign: "center" }}>
                  <b>No more records found...</b>
                </p>
              )
            }
            scrollableTarget="scrollablediv"
            className="infiniteRequestTable"
          >
            <table className="table-main">
              <thead>
                <tr>
                  <th colSpan="1" className="span-col"></th>
                  <th>Customer</th>
                  <th>Contact</th>
                  <th>Product Details</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th>Reject</th>
                  <th>Mark Delivered</th>
                  <th>Assign Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isRequestData &&
                  requestData.map((requestObj, index) => (
                    <tr key={index}>
                      <td colSpan="1" className="span-col"></td>
                      <td style={{ width: "16%" }}>
                        <span>
                          {requestObj.customer.firstName +
                            " " +
                            requestObj.customer.lastName}
                        </span>
                      </td>
                      <td style={{ width: "8%" }}>
                        <span>{requestObj.customer.contactNumber}</span>
                      </td>
                      <td style={{ width: "16%" }}>
                        <span>
                          <div className="productDetailSpan">
                            <span className="categorySpan">
                              {requestObj.mainCategoryName}
                            </span>
                            <span className="subCategorySpan">
                              {requestObj.categoryName}
                            </span>
                            <span className="subCategorySpan">
                              {requestObj.brandName}
                            </span>
                          </div>
                        </span>
                      </td>
                      <td style={{ width: "5%" }}>{requestObj.quantity}</td>
                      <td style={{ width: "5%" }}>
                        <span
                          className={
                            requestObj.requestStatus === "Open"
                              ? "open-neutral-span highlighted"
                              : requestObj.requestStatus === "Closed"
                              ? "decline-neutral-span highlighted"
                              : "delivered-neutral-span highlighted"
                          }
                        >
                          {requestObj.requestStatus}
                        </span>
                      </td>
                      <td>
                        {requestObj.requestStatus === "Open" && (
                          <img
                            src="./Image/ban.png"
                            alt="Reject"
                            onClick={() =>
                              handleRejectDelivered(
                                "reject",
                                requestObj.id,
                                index
                              )
                            }
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        )}
                      </td>
                      <td>
                        {requestObj.requestStatus === "Open" && (
                          <img
                            src="./Image/tick.png"
                            alt="Mark Delivered"
                            onClick={() =>
                              handleRejectDelivered(
                                "mark",
                                requestObj.id,
                                index
                              )
                            }
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        )}
                      </td>
                      <td style={{ width: "11%" }}>
                        {requestObj.requestStatus === "Open" && (
                          <div>
                            <button
                              className="assignSellerButton"
                              onClick={() => handleAssignButton(index)}
                            >
                              {requestObj.isSellerAssigned
                                ? "Reassign Seller"
                                : "Assign Seller"}
                            </button>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="edit">
                          <FaIcons.FaRegEye
                            size={20}
                            style={{
                              color: "#6b6b6f",
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDetailClick(index)}
                          />
                          {requestObj.requestStatus === "Open" && (
                            <img
                              src="./Image/pencil.png"
                              alt="Pencil"
                              onClick={() => handleClick(index)}
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                            />
                          )}
                          <img
                            src="./Image/delete.png"
                            alt="Remove"
                            onClick={() => handleRemoveButton(requestObj.id)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </InfiniteScroll>
        ) : (
          <EmptyList
            itemFor="Request"
            ifBtn={searchFilter !== "" ? false : true}
            onAddNew={() => setShowAddEditModal(true)}
            btnText="Add Request"
          ></EmptyList>
        )}
      </div>
      {showAssignSellerModal && (
        <AssignSeller
          ReqIndex={reqIndex}
          onSellerAssigned={handleSellerAssigned}
          onClose={() => {
            handleCloseModal("AssignSeller");
          }}
          selectedIndexData={selectedIndexData}
        />
      )}

      {showAddEditModal && (
        <AddRequest
          ReqIndex={reqIndex}
          onRowUpdate={handleRowUpdate}
          onClose={() => {
            handleCloseModal("AddEditReq");
          }}
          selectedIndexData={selectedIndexData}
          isAddNew={isEmpty(selectedIndexData) ? true : false}
          onOtherSelected={handleOnOtherSelected}
          newCatObject={newCatObject}
          newSubCatObject={newSubCatObject}
          newBrandObject={newBrandObject}
          setNewCatObject={setNewCatObject}
          setNewSubCatObject={setNewSubCatObject}
          setNewBrandObject={setNewBrandObject}
        />
      )}
      {showDetailsModal && (
        <Details
          ReqIndex={reqIndex}
          onRowUpdate={handleRowUpdate}
          onClose={() => {
            handleCloseModal("Details");
          }}
          detailsObject={selectedIndexData}
        />
      )}

      {showAddCategory && (
        <AddCategory
          trigger={showAddCategory}
          setTrigger={setShowAddCategory}
          cellProduct={{}}
          isOntheFly={true}
          newCatObject={setNewCatObject}
        />
      )}
      {showAddSubCategory && (
        <AddSubCategory
          trigger={showAddSubCategory}
          setTrigger={setShowAddSubCategory}
          cellProduct={
            newCatObject
              ? {
                  parentCategory: {
                    id: newCatObject.id,
                    name: newCatObject.name,
                  },
                }
              : []
          }
          isOntheFly={true}
          newSubCatObject={setNewSubCatObject}
        />
      )}
      {showAddBrand && (
        <AddBrand
          trigger={showAddBrand}
          setTrigger={setShowAddBrand}
          cellProduct={
            newSubCatObject
              ? {
                  category: [
                    { id: newSubCatObject.id, name: newSubCatObject.name },
                  ],
                }
              : newCatObject
              ? { category: [{ id: newCatObject, name: newCatObject.name }] }
              : {}
          }
          isOntheFly={true}
          newBrandObject={setNewBrandObject}
        />
      )}
    </div>
  );
}

export default Request;

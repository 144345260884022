import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import swal from "sweetalert";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";

import "../Request/request.css";
import axiosMiddleware from "../../../helper/axios";

function AssignSeller(props) {
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(50);
  const [offSet, setOffSet] = useState(0);
  const [selectedSeller, setSelectedSeller] = useState(props.selectedIndexData.isSellerAssigned?props.selectedIndexData.seller.id:"");
  const [sellerList, setSellerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Loading...");
  const loadSellerList = (searchText = "") => {
    let url = `seller?limit=50&offset=0`;
    if (searchText !== "") {
      url = `seller?searchText=${searchText}&limit=50&offset=0`;
    }
    axiosMiddleware
      .get(url)
      .then((res) => {
        setSellerList(res.data.Data);
        setOffSet(offSet + limit);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    loadSellerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event) {
    setSelectedSeller(event.target.value);
  }

  function handleSearchChange(e) {
    loadSellerList(e.target.value);
  }

  const saveAssignedSeller = async() => {
    setIsLoading(true);
   
        const data = {
          sellerId: `${selectedSeller}`,
          requestIds: `${props.selectedIndexData.id}`,
        };
        await axiosMiddleware
          .put(`request/assign-request`, data)
          .then((res) => {
            if (res.data.Status === true) {
              toast.success(res.data.Message);
              props.onSellerAssigned(true, props.ReqIndex, sellerList.find(sellerObj => sellerObj.id == selectedSeller));
              props.onClose();
              setIsLoading(false);
            } else {
              toast.success(res.data.Message);
              props.onSellerAssigned(false, props.ReqIndex);
              props.onClose();
              setIsLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
          });
  };

  return (
    <div className="global-popup">
      <div className="addSellerPopUp">
        <div className="addPopUpInner">
          <div className="seller-headind border-bottom">
            <h2 className="popup-heading" style={{ marginTop: "4px" }}>Assign Sellers</h2>
            <span>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a onClick={props.onClose}>
                <img src="./Image/closepopup-icon.svg" alt="Close" />
              </a>
            </span>
          </div>
          <div className="popupbody" style={{ paddingTop: "10px" }}>
            <div className="form-element-group search-frm" style={{ margin: "10px 0px 10px 0px" }}>
              <div className="search-field p-0" style={{ overflow: "hidden" }}>
                <input
                  type="text"
                  className="form-control shadow-none input-class"
                  placeholder="Search Seller...."
                  style={{ width: "100%" }}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div id="scrollableDiv" style={{ overflow: "auto", height: "300px" }}>
              <div>
                {/* eslint-disable-next-line array-callback-return */}
                {!isEmpty(sellerList) && sellerList.map((sellerObj, index) => (
                  <div
                    className="CategoryStep border-bottom d-flex justify-content-between p-0 m-0 w-100"
                    style={{
                      fontSize: "14px",
                      height: "72px",
                      alignItems: "center",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: 32,
                        marginBottom: 10,
                      }}
                    >
                      <label className="radio-label">
                        <input
                          className="option-input radio"
                          type="radio"
                          name="user-list"
                          value={sellerObj.id}
                          checked={selectedSeller === sellerObj.id}
                          onChange={handleChange}
                        />
                      </label>
                      <div className="avtar_users">
                        <Avatar
                          size={32}
                          round="20px"
                          maxInitials={2}
                          name={sellerObj.firstName + " " + sellerObj.lastName}
                        />
                      </div>
                      <div
                        className="multiple"
                        style={{
                          marginLeft: 12,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          className="dark-text"
                          style={{
                            textTransform: "capitalize",
                            width: "100%",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          {sellerObj.firstName + " " + sellerObj.lastName}
                        </span>
                        <span
                          className="grey-text"
                          style={{
                            textTransform: "capitalize",
                            width: "100%",
                            fontWeight: "400",
                            fontSize: "11px",
                            color: "rgb(107,107,111)",
                          }}
                        >
                          {sellerObj.position} 
                        </span>
                        {selectedSeller === sellerObj.id && <span
                          className="grey-text"
                          onClick={()=>setSelectedSeller(0)}
                          style={{
                            textTransform: "capitalize",
                            width: "100%",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "rgb(107,107,111)",
                            cursor: "pointer"
                          }}
                        >
                          {"Remove"}
                        </span>}
                      </div>
                    </div>
                    <span
                      className={
                        sellerObj.hasAccess === "Give Access"
                          ? "giveAccessLink"
                          : "hasAccessLink"
                      }
                    >
                      {selectedSeller === sellerObj.id ? (
                        <div>
                          <img src="./Image/check-small.svg" alt="Check" />
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="popup-footer border-top d-flex justify-content-between" style={{ padding: "15px 15px 20px 15px" }}>
              <button
                className="cancel-btn"
                type="reset"
                onClick={props.onClose}>
                Cancel
              </button>
              <div className="footerBtn">
                <button type="button" className={`btn primary ${isLoading ? "disabledButton" : "small"}`} disabled={isLoading ? true : false} onClick={saveAssignedSeller}>
                  {isLoading ? loadingMsg : "Assign"}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div >

  );
}

export default AssignSeller;

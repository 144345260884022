import { useState, useEffect } from "react";
import Switch from "react-switch";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import axiosMiddleware from "../../../helper/axios";
import { iDToValueResolveAsyncSelect } from "../../../helper/helper";
import useForm from "../../../helper/useForm";
import ValidateRules from "../../../helper/ValidateRules";

import "./addCategory.css";

function AddSubCategory(props) {
  const [allParentCat, setAllParentCat] = useState();
  const [isAllParentCat, setIsAllParentCat] = useState(false);
  const [isImageChange, setIsImageChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Loading...");
  const { values, errors, handleChange, handleSubmit, initialValues } = useForm(
    handleCatForm,
    ValidateRules.SubCategory
  );
  const loadParentCat = async (searchText) => {
    await axiosMiddleware.get(`category?searchText=${searchText}&limit=&offset=&sortBy=&orderTo=&parentId=0`).then((res) => {
        let asyncSortedValues = iDToValueResolveAsyncSelect(res.data.Data);
        setAllParentCat(asyncSortedValues);
        setIsAllParentCat(true);
      })
      .catch((e) => {
        toast.error("Error...! Something went wrong, Unable to load Parent Category.");
        // console.log(e);
      });
  };

  useEffect(() => {
    initialValues({
      catId: props.cellProduct.id || null,
      catName: props.cellProduct.name || null,
      catImageURL: props.cellProduct.image || null,
      catDescription: props.cellProduct.description || null,
      catStatus: parseInt(props.cellProduct.isActive) ? true : false,
      isApproved: parseInt(props.cellProduct.isApproved) ? true : false,
      catParentCat:
        props.cellProduct.parentCategory &&
        (props.cellProduct.parentCategory.id || null)
    });
    loadParentCat("");
    setIsEdited(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cellProduct]);

  function loadImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      const output = document.getElementById("output");
      output.src = reader.result;
      handleChange("", "file", "catImageURL", reader.result);
      setIsImageChange(true);
      setIsEdited(true);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  function handleClose() {
    if(props.setTrigger){props.setTrigger(false);}
    if(props.setCellProduct){props.setCellProduct({});}
  }

  function handleCatForm() {
    setIsLoading(true);
    const submit = {
      name: values.catName,
      image: isImageChange && values.catImageURL,
      status: `${values.catStatus ? 1 : 0}`,
      isApproved: `${values.isApproved ? 1 : 0}`,
      description: values.catDescription,
      parentId: `${values.catParentCat}`,
    };

    if (values.catId !== null) {
      editCategory(values.catId, submit);
    } else {
      addCategory(submit);
    }
  }

  async function addCategory(data) {
    await axiosMiddleware
      .post("category", data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          setIsLoading(false);
          props.setTrigger(false);
          if(props.isOntheFly){
            props.newSubCatObject(res.data.Data);
          }else{
            window.location.reload(false);
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Something went wrong, Unable to Add Sub Category.");
        // console.log(e);
      });
  }

  async function editCategory(id, data) {
    await axiosMiddleware
      .put(`category/${id}`, data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.onRowUpdate(res.data.Data, props.catIndex);
          props.setTrigger(false);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Something went wrong, Unable to Edit Sub Category.");
        // console.log(e);
      });
  }

  const filterSelect = (inputValue) => {
    let filterObj = allParentCat;
    return filterObj.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadCategoryInToSelect = (inputValue, callback) => {
    loadParentCat(inputValue);
    callback(filterSelect(inputValue));
  };

  return props.trigger ? (
    <div className="global-popup">
      <div className="addProductPopup">
        {isAllParentCat && <div className="addPopupinner fade-in-up">
          <div className="addProduct-heading border-bottom">
            <h2 className="popup-heading" style={{ marginTop: "7px" }}>
              Add SubCategory
            </h2>
            <span>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={handleClose}>
                <img src="./Image/closepopup-icon.svg" alt="Close Icon" />
              </a>
            </span>
          </div>
          <div className="popupInnerBody">
            <div>
              <form className="fomik-form" onSubmit={handleSubmit} noValidate>
                <div className="textfield-div">
                  <label className="activeUser-label" htmlFor="CatName">
                    <span>Name</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={`form-control shadow-none input-class ${errors.catName && 'is-danger'}`}
                    style={{ width: "400px" }}
                    value={values.catName}
                    onChange={(e) => { handleChange(e, "text", "", ""); setIsEdited(true); }}
                    placeholder="Enter category name"
                    name="catName"
                    required
                  />
                  {errors.catName && (<p className="help is-danger">{errors.catName}</p>)}
                </div>

                <div className="textfield-div">
                  <label className="activeUser-label" htmlFor="CatDescription">
                    <span>Description</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={`form-control shadow-none input-class`}
                    style={{ width: "400px" }}
                    value={values.catDescription}
                    onChange={(e) => {
                      handleChange(e, "text", "", "");
                      setIsEdited(true);
                    }}
                    placeholder="Enter description"
                    name="catDescription"
                  />
                </div>

                <label htmlFor="Parent Category" className="activeUser-label">
                  <span>Parent Category</span>
                </label>
                <AsyncSelect
                  className={`${errors.catParentCat && "is-danger"}`}
                  name="catParentCat"
                  loadOptions={loadCategoryInToSelect}
                  defaultOptions={allParentCat}
                  isClearable={true}
                  defaultValue={
                    allParentCat[
                    allParentCat.findIndex(
                      (item) => item.value === values.catParentCat
                    )
                    ]
                  }
                  onChange={(e) => {
                    handleChange("", "reactSelect", "catParentCat", e);
                    setIsEdited(true);
                  }}
                placeholder="Select Parent Category...."
                />
                {errors.catParentCat && (
                  <p className="help is-danger">{errors.catParentCat}</p>
                )}
                <div className="d-flex justify-content-between">
                  <label className="activeUser-label">
                    <span>Status</span>
                    <Switch
                      name="catStatus"
                      onChange={(e) => { handleChange("", "switch", "catStatus", e); setIsEdited(true); }}
                      checked={values.catStatus}
                    />
                  </label>
                  <label className="activeUser-label">
                    <span>Is Approved</span>
                    <Switch
                      name="isApproved"
                      onChange={(e) => { handleChange("", "switch", "isApproved", e); setIsEdited(true); }}
                      checked={values.isApproved}
                    />
                  </label>
                </div>
                <div
                  className="image-upload"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px",
                  }}
                >
                  <label className="image-upload-label" htmlFor="fileUpload">
                    Upload Image
                  </label>
                  <input
                    type="file"
                    name="catImageUrl"
                    id="fileUpload"
                    accept="image/*"
                    onChange={loadImage}
                    required
                  />
                  <img
                    id="output"
                    style={{
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      width: "120px",
                    }}
                    src={values.catImageURL}
                    alt=""
                  />
                </div>
                <div
                  className=" border-bottom"
                  style={{ width: "500px", marginLeft: "-48px" }}
                ></div>
                <div className="addProductFooter d-flex">
                  <button
                    type="reset"
                    className="footer-cancel"
                    onClick={() => { props.setTrigger(false); handleClose(); }}
                  >
                    Cancel
                  </button>
                  <div className="footerBtn">
                    <button type="submit" className={`btn primary ${isLoading || !isEdited ? "disabledButton" : "small"}`} disabled={isLoading || !isEdited ? true : false}>{isLoading ? loadingMsg : "Save"}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  ) : (
    ""
  );
}

export default AddSubCategory;

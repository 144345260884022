import React from "react";
import { useHistory } from "react-router-dom";
import useForm from "../../helper/useForm";
import ValidateRules from "../../helper/ValidateRules";
import axiosMiddleware from "../../helper/axios";
import { toast } from "react-toastify";
import "./signin.css";

function ForgotPassword() {
  const history = useHistory();
  const { values, errors, handleChange, handleSubmit, setValues } = useForm(
    forgotPassword,
    ValidateRules.ForgotPassword
  );

  const handleBackButton = () => {
    history.push("/");
  };

  async function forgotPassword() {
    const data = { email: values.email, userRoleId: 3 };

    await axiosMiddleware
      .put("user/forgot-password", data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
        } else {
          toast.success(res.data.Message);
          setValues("");
        }
      })
      .catch((e) => console.error(e));
  }

  return (
    <div className="login-page">
      <nav className="navbar navbar-light signin-navbar">
        <div className="container">
          <a href="/">
            <img src="./Image/logo.jpg" alt="Logo" width="100" height="100" />
          </a>
        </div>
      </nav>
      <div className="signin-body resetage-body">
        <div className="container">
          <div className="login-wrapper">
            <div className="login-left-banner cols">
              <div className="img-wrap">
                <img src="./Image/signin-bg.png" alt="DayBulk Logo" />
              </div>
              <div className="title">
                <h1>
                  {" "}
                  <span>Welcome</span> to the DayBulk!
                </h1>
              </div>
            </div>
            <div className="login-content forget-password cols">
              <h2 className="title">Forgot Password</h2>
              <form className="forgot-form" onSubmit={handleSubmit}>
                <div className="form-element-group login-group">
                  <label htmlFor="Headline">E-mail</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@example.com"
                    onChange={(e) => handleChange(e, "text", "", "")}
                    value={values.email || ""}
                    name="email"
                  />
                  {errors.email && (
                    <p className="help is-danger">{errors.email}</p>
                  )}
                </div>
                <div className="form-element-group">
                  <div className="buttonSection d-flex">
                    <button
                      className="actionButton fullwidth primary btn"
                      onClick={handleBackButton}
                    >
                      Back
                    </button>
                    <button className="actionButton fullwidth primary btn">
                      Forgot Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <footer className="login-footer">
          <p>&copy; {new Date().getFullYear()} DayBulk.com All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default ForgotPassword;

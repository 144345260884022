/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import _concat from "lodash/concat";
import _get from "lodash/get";
import _forEach from 'lodash/forEach';
import swal from "sweetalert";
import AddBanner from "../pop-up-model-pages/AddBanner";
import axiosMiddleware from "../../../helper/axios";
import { toast } from "react-toastify";
import EmptyList from "../EmptyState/EmptyList";
// import { useDebounce } from "../../../helper/UseDebounce";
import SkeletonLoading from "../../../components/SkeletonLoading";

function Banner(props) {
  const [showModal, setShowModal] = useState(false);
  const [banner, setBanner] = useState({});
  const [bannerList, setBannerList] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [limit] = useState(15);
  // eslint-disable-next-line no-unused-vars
  const [searchFilter, setSearchFilter] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [bannerIndex, setBannerIndex] = useState(-1);
  const [isBannerList, setIsBannerList] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("User-Info"));
  const [resCount, setResCount] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [roleId, setRoleId] = useState();
  // const debouncedSearchTerm = useDebounce(searchFilter, 1000);
  const [endMsg, setEndMsg] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem("User-Info") === null || !localStorage.getItem("ApiKey")) {
      toast.error("Session Expired! Please login again...");
      window.location.replace("/")
    } else {
      setRoleId(userData.userRoleId);
    }
  }, []);

  async function loadBanners() {
    await axiosMiddleware.get(`banner?limit=${limit}&offset=${offSet}&sortBy=&orderTo=&userRoleId=3`).then((res) => {
      const totalCount = (res.data.Data || []).length;
      if (!res.data.Status) {
        setHasMore(false);
        setBannerList([]);
        setIsBannerList(false);
        setResCount(0);
      }
      if (res.data.Status && totalCount === 15) {
        setOffSet(offSet + limit);
        setBannerList(res.data.Data);
        setIsBannerList(true);
        setHasMore(true);
        setResCount(totalCount);
      }
      if (res.data.Status && totalCount > 0 && totalCount < 15) { 
        setOffSet(offSet + limit);
        setBannerList(res.data.Data);
        setIsBannerList(true);
        setHasMore(false);
        setResCount(totalCount);
        setEndMsg(false)
      }
        },
        (err) => {
          console.log(err.toJSON().message);
        }
    ).catch((e) => {
      toast.error("Error...! Something went wrong, Unable to load Banner List.");
      console.log(e);
    });
  }

  useEffect(()=>{
    loadBanners();
  },[]);

  // useEffect(() => {
  //   loadBanners();
  // }, [debouncedSearchTerm]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [showModal]);

  function handleClick(cellIndex) {
    setBanner(bannerList[cellIndex]);
    setBannerIndex(cellIndex);
    setShowModal(true);
  }

  // function handleSearchChange(e) {
  //   setOffSet(0);
  //   setSearchFilter(e.target.value);
  // }

  async function fetchMoreData() {
    await axiosMiddleware.get(`banner?limit=${limit}&offset=${offSet}&sortBy=&orderTo=&userRoleId=3`).then((res) => {
          let dummyArray = _get(res, "data.Data", []);
          let fetchArray = _concat(bannerList, dummyArray);
          setBannerList(fetchArray);
          setOffSet(offSet + limit);
          if (dummyArray.length === 0) {
            setHasMore(false);
          }
        },
        (err) => {
          console.log(err);
        }
    ).catch((e) => {
      toast.error("Error...! Something went wrong, Unable to load more Banners.");
      console.log(e);
    });
  }

  const handleRowUpdate = (data, index) => {
    bannerList[index] = data;
  };

  const handleRemoveButton = (bannerId) => {
    let newBannerName = [];
    swal({
      title: "Are you sure?",
      text: "Once deleted, can not be restored!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        await axiosMiddleware.delete(`banner/${bannerId}`).then((res) => {
          if (bannerList.length === 1) {
            loadBanners();
          } else { 
          _forEach(bannerList, (value, index) => {
            if (value.id !== bannerId) {
              newBannerName.push(value);
            }
          });
          setBannerList(newBannerName);
          }
          toast.success("Record Deleted Successfully");
        }).catch((e) => {
          toast.error("Error ! " + e);
          console.log(e);
        });
      }
    });
  }
  const loadingArr = [];

    for (let index = 0; index < 6; index++) {
      loadingArr.push(<tr>
        <td colSpan="2"><SkeletonLoading height="70px"/></td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        </tr>)
    }
  return (
    <div className="container-category">
      <div className="pannel-header" style={{ overflow: "hidden" }}>
        <div className="left-sec pannel-cols">
          <h2 className="panel-heading">Manage Banners</h2>
        </div>
        <div className="right-sec pannel-cols">
          <button
            className="btn primary small"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            <div>
              <img src="./Image/plus_icon.png" alt="Plus Symbol" />
              <span>Add Banner</span>
            </div>
          </button>
        </div>
      </div>
      {/* <div className="search-field" style={{ overflow: "hidden" }}>
        <div className="form-element-group search-frm">
          <input
            type="text"
            className={`small data-hj-allow ${resCount === 0 && searchFilter === "" && "disabled"}`}
            placeholder="Search Brand Name...."
            onChange={handleSearchChange}
            disabled={resCount === 0 && searchFilter === "" ? true : false}
          />
        </div>
      </div> */}
      <div className="card-table table-wrapper">
        {resCount !== 0 ? (
          <InfiniteScroll
          dataLength={isBannerList && bannerList.length}
          scrollableTarget="scrollablediv"
          className="infiniteCategoryTable"
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <table className="table-main">
                
                <tbody>
                    {loadingArr}
                </tbody>
            </table>
            }
          endMessage={endMsg && 
            <p style={{ textAlign: 'center' }}>
              <b>No more records found...</b>
            </p>
          }
        >
          <table className="table-main">
            <thead>
              <tr>
                <th colSpan="1" className="span-col"></th>
                <th>Icon</th>
                <th>Name</th>
                <th>Is Featured</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isBannerList && bannerList.map((event, index) => (
                <tr key={index}>
                  <td colSpan="1" className="span-col"></td>
                  <td>
                    {event.image !== "" ? <div className="edit">
                      <img
                        src={event.image}
                        style={{width:"400px", height:"180px"}}
                        alt="Profile"
                      />
                    </div>
                      : "No Image"}
                  </td>
                  <td>
                    <span>{event.name}</span>
                  </td>
                  <td>
                    <span className={event.isFeatured === "1" ? "active-neutral-span highlighted" : "decline-neutral-span highlighted"}>
                      {event.isFeatured === "1" ? 'Featured' : "Not Featured"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={
                        event.isActive === "1"
                          ? "active-neutral-span highlighted"
                          : "decline-neutral-span highlighted"
                      }
                    >
                      {event.isActive === "1" ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td>
                    <div className="edit">
                      <img
                        src="./Image/pencil.png"
                        alt="Pencil"
                        onClick={() => handleClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src="./Image/delete.png"
                        alt="Remove"
                        onClick={() => handleRemoveButton(event.id)}
                        style={{ cursor: "pointer", marginLeft: '10px' }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </InfiniteScroll>
        ) : (
          <EmptyList itemFor="Banner" ifBtn={searchFilter !== "" ? false : true} onAddNew={() => setShowModal(true)} btnText="Add Banner"></EmptyList>
        )
        }
      </div>

      {banner === {} ? (
        <AddBanner
          trigger={showModal}
          setTrigger={setShowModal}
          cellBanner={banner}
        />
      ) : (
          <AddBanner
          bannerIndex={bannerIndex}
          onRowUpdate={handleRowUpdate}
          trigger={showModal}
          setTrigger={setShowModal}
          cellBanner={banner}
          setCellBanner={setBanner}
          name={banner.name}
          icon={banner.image}
          active={banner.active}
        />
      )}
    </div>
  );
}

export default Banner;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import isEmpty from 'lodash/isEmpty';
import _concat from "lodash/concat";
import _get from "lodash/get";
import _forEach from 'lodash/forEach';
import axiosMiddleware from "../../../helper/axios";
import AddSeller from "../pop-up-model-pages/AddSeller";
import swal from "sweetalert";
import { toast } from "react-toastify";
import EmptyList from "../EmptyState/EmptyList";
import { useDebounce } from "../../../helper/UseDebounce";
import SkeletonLoading from "../../../components/SkeletonLoading";

const Seller = (props) => {
  const userData = JSON.parse(sessionStorage.getItem("User-Info"));
  const [hasMore, setHasMore] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(15);
  const [offSet, setOffSet] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [seller, setSeller] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [sellerIndex, setSellerIndex] = useState(-1);
  const [isSellerList, setIsSellerList] = useState(false);
  const [resCount, setResCount] = useState(1);
  const [roleId, setRoleId] = useState();
  const debouncedSearchTerm = useDebounce(searchFilter, 1000);
  const [endMsg, setEndMsg] = useState(true);
  useEffect(() => {
    if (sessionStorage.getItem("User-Info") === null || !localStorage.getItem("ApiKey")) {
      toast.error("Session Expired! Please login again...");
      window.location.replace("/")
    } else {
      setRoleId(userData.userRoleId);
    }
  }, []);

  const loadSeller = async () => {
    await axiosMiddleware.get(`seller?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&userRoleId=${roleId}`).then((res) => {
      const totalCount = (res.data.Data || []).length;
      if (!res.data.Status) {
        setHasMore(false);
        setSeller([]);
        setIsSellerList(false);
        setResCount(0);
      }
      if (res.data.Status && totalCount === 15) {
        setOffSet(offSet + limit);
        setSeller(res.data.Data);
        setIsSellerList(true);
        setHasMore(true);
        setResCount(totalCount);
      }
      if (res.data.Status && totalCount > 0 && totalCount < 15) { 
        setOffSet(offSet + limit);
        setSeller(res.data.Data);
        setIsSellerList(true);
        setHasMore(false);
        setResCount(totalCount);
        setEndMsg(false);
      }
    }).catch((e) => {
      console.log(e)
    });
  }

  useEffect(() => {
    loadSeller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  function handleSearchChange(e) {
    setOffSet(0);
    setSearchFilter(e.target.value);
  }

  function handleClick(cellIndex) {
    setSelectedSeller(seller[cellIndex]);
    setSellerIndex(cellIndex);
    setShowModal(true);
  }

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedSeller([]);
  }

  const handleRowUpdate = (data, index) => {
    seller[index] = data;
  }

  const handleRowInsert = (data) => {
    seller.unshift(data);
  }

  function fetchMoreData() {
    axiosMiddleware.get(`agent?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&userRoleId=${userData.userRoleId}`)
      .then((res) => {
        let dummyArray = _get(res, "data.Data", []);
        let fetchArray = _concat(seller, dummyArray);
        setSeller(fetchArray);
        setOffSet(offSet + limit);
        if (dummyArray.length === 0) {
          setHasMore(false);
        }
      });
  }

  const handleRemoveButton = (sellerId) => {
    let newSeller = [];
    swal({
      title: "Are you sure?",
      text: "Once deleted, can not be restored!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        await axiosMiddleware.delete(`seller/${sellerId}`).then((res) => {
          if (seller.length === 1) {
            loadSeller();
          } else {
            _forEach(seller, (value, index) => {
              if (value.id !== sellerId) {
                newSeller.push(value);
              }
            });
            setSeller(newSeller);
          }
          toast.success("Record Deleted Successfully");
        }).catch((e) => {
          toast.error("Error ! " + e);
          console.log(e);
        });
      }
    });
  }
  const loadingArr = [];

  for (let index = 0; index < 6; index++) {
    loadingArr.push(<tr>
      <td colSpan="2"><SkeletonLoading/></td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      </tr>)
  }
  return (
    <div className="container-agent">
      <div className="pannel-header">
        <div className="left-sec pannel-cols">
          <h2 className="panel-heading">Seller</h2>
        </div>
        <div className="right-sec pannel-cols">
          <button
            className="btn primary small"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            <img src="./Image/plus_icon.png" alt="Plus" />
            <span>Add Seller</span>
          </button>
        </div>
      </div>
      <div className="search-field">
        <div className="form-element-group search-frm">
          <input
            className={`small data-hj-allow ${resCount === 0 && searchFilter === "" && "disabled"}`}
            type="text"
            placeholder="Search Seller...."
            onChange={handleSearchChange}
            disabled={resCount === 0 && searchFilter === "" ? true : false}
          />
        </div>
      </div>
      <div className="card-table table-wrapper">
        {resCount !== 0 ? <InfiniteScroll
          dataLength={isSellerList && seller.length}
          next={fetchMoreData}
          loader={
            <table className="table-main">
              
                <tbody>
                    {loadingArr}
                </tbody>
            </table>
           }
          hasMore={hasMore}
          endMessage={ endMsg && 
            <p style={{ textAlign: 'center' }}>
              <b>No more records found...</b>
            </p>
          }
          scrollableTarget="scrollablediv"
          className="infiniteRequestTable"
        >
          <table className="table-main">
            <thead>
              <tr>
                <th colSpan="1" className="span-col">
                </th>
                <th>
                  Profile Picture
                </th>
                <th>
                  Agent Name
                </th>
                <th>
                  Contact Number
                </th>
                <th>
                  Email
                </th>
                <th>
                  Zip Code
                </th>
                <th>
                  Channel ID
                </th>
                <th>
                  Status
                </th>
                <th>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* eslint-disable-next-line array-callback-return */}
              {isSellerList && seller.map((sellerObj, index) => (
                <tr key={index}>
                  <td colSpan="1" className="span-col">

                  </td>
                  <td>
                    <img
                      src={sellerObj.profilePicture}
                      width="40"
                      height="40"
                      alt="Profile"
                    />
                  </td>
                  <td>
                    <span>{sellerObj.firstName + " " + sellerObj.lastName}</span>
                  </td>
                  <td>
                    <span>{sellerObj.contactNumber}</span>
                  </td>
                  <td>
                    <span>{sellerObj.email}</span>
                  </td>
                  <td>
                    <span>{sellerObj.zipCode}</span>
                  </td>
                  <td>
                    <span>{sellerObj.referralCode}</span>
                  </td>
                  <td>
                    <span className={parseInt(sellerObj.isActive) ? "active-neutral-span highlighted" : "decline-neutral-span highlighted"}>
                      {parseInt(sellerObj.isActive) ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td>
                    <div className="edit">
                      <img
                        src="./Image/pencil.png"
                        alt="Pencil"
                        onClick={() => handleClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src="./Image/delete.png"
                        alt="Remove"
                        onClick={() => handleRemoveButton(sellerObj.id)}
                        style={{ cursor: "pointer", marginLeft: '10px' }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll> : <EmptyList itemFor="Seller" ifBtn={searchFilter !== "" ? false : true} onAddNew={() => setShowModal(true)} btnText="Add Seller"></EmptyList>}

      </div>

      {showModal && <> <AddSeller
        sellerIndex={sellerIndex}
        onRowUpdate={handleRowUpdate}
        onRowInsert={handleRowInsert}
        onAddNew={isEmpty(selectedSeller) ? true : false}
        selectedSeller={selectedSeller}
        onClose={handleOnClose}
      />
      </>
      }
    </div>
  );
}

export default Seller;

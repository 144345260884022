/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import _concat from "lodash/concat";
import _get from "lodash/get";
import _forEach from 'lodash/forEach';
import swal from "sweetalert";
import AddBrand from "../pop-up-model-pages/AddBrand";
import axiosMiddleware from "../../../helper/axios";
import { toast } from "react-toastify";
import EmptyList from "../EmptyState/EmptyList";
import { useDebounce } from "../../../helper/UseDebounce";
import SkeletonLoading from "../../../components/SkeletonLoading";

function Brand(props) {
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState({});
  const [brandName, setBrandName] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [limit] = useState(15);
  const [searchFilter, setSearchFilter] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [brandIndex, setBrandIndex] = useState(-1);
  const [isBrandsList, setIsBrandsList] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("User-Info"));
  const [resCount, setResCount] = useState(1);
  const [roleId, setRoleId] = useState();
  const debouncedSearchTerm = useDebounce(searchFilter, 1000);
  const [endMsg, setEndMsg] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem("User-Info") === null || !localStorage.getItem("ApiKey")) {
      toast.error("Session Expired! Please login again...");
      window.location.replace("/")
    } else {
      setRoleId(userData.userRoleId);
    }
  }, []);

  async function loadBrands() {
    await axiosMiddleware.get(`brand?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&userRoleId=${roleId}`).then((res) => {
      const totalCount = (res.data.Data || []).length;
      if (!res.data.Status) {
        setHasMore(false);
        setBrandName([]);
        setIsBrandsList(false);
        setResCount(0);
      }
      if (res.data.Status && totalCount === 15) {
        setOffSet(offSet + limit);
        setBrandName(res.data.Data);
        setIsBrandsList(true);
        setHasMore(true);
        setResCount(totalCount);
      }
      if (res.data.Status && totalCount > 0 && totalCount < 15) { 
        setOffSet(offSet + limit);
        setBrandName(res.data.Data);
        setIsBrandsList(true);
        setHasMore(false);
        setResCount(totalCount);
        setEndMsg(false)
      }
        },
        (err) => {
          toast.error(err.toJSON().message);
          // console.log(err.toJSON().message);
        }
    ).catch((e) => {
      toast.error("Error...! Error in loading Brands List.");
      // console.log(e);
    });
  }

  useEffect(() => {
    loadBrands();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [showModal]);

  function handleClick(cellIndex) {
    setProducts(brandName[cellIndex]);
    setBrandIndex(cellIndex);
    setShowModal(true);
  }

  function handleSearchChange(e) {
    setOffSet(0);
    setSearchFilter(e.target.value);
  }

  async function fetchMoreData() {
    await axiosMiddleware.get(`brand?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&userRoleId=${userData.userRoleId}`).then((res) => {
          let dummyArray = _get(res, "data.Data", []);
          let fetchArray = _concat(brandName, dummyArray);
          setBrandName(fetchArray);
          setOffSet(offSet + limit);
          if (dummyArray.length === 0) {
            setHasMore(false);
          }
        },
        (err) => {
          toast.error(err.toJSON().message);
          // console.log(err);
        }
    ).catch((e) => {
      toast.error("Error...! Unable to load more Brands.");
      // console.log(e);
    });
  }

  const handleRowUpdate = (data, index) => {
    brandName[index] = data;
  };

  const handleRemoveButton = (brandId) => {
    let newBrandName = [];
    swal({
      title: "Are you sure?",
      text: "Once deleted, can not be restored!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        await axiosMiddleware.delete(`brand/${brandId}`).then((res) => {
          if (brandName.length === 1) {
            loadBrands();
          } else { 
          _forEach(brandName, (value, index) => {
            if (value.id !== brandId) {
              newBrandName.push(value);
            }
          });
          setBrandName(newBrandName);
          }
          toast.success("Record Deleted Successfully");
        }).catch((e) => {
          toast.error("Error ! Error in deleting the record. Please try again.");
          // console.log(e);
        });
      }
    });
  }
  const loadingArr = [];

  for (let index = 0; index < 6; index++) {
    loadingArr.push(<tr>
      <td colSpan="2"><SkeletonLoading/></td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      {/* <td>
        <span><SkeletonLoading/></span>
      </td> */}
      </tr>)
  }
  return (
    <div className="container-category">
      <div className="pannel-header" style={{ overflow: "hidden" }}>
        <div className="left-sec pannel-cols">
          <h2 className="panel-heading">Brand</h2>
        </div>
        <div className="right-sec pannel-cols">
          <button
            className="btn primary small"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            <div>
              <img src="./Image/plus_icon.png" alt="Plus Symbol" />
              <span>Add Brand</span>
            </div>
          </button>
        </div>
      </div>
      <div className="search-field" style={{ overflow: "hidden" }}>
        <div className="form-element-group search-frm">
          <input
            type="text"
            className={`small data-hj-allow ${resCount === 0 && searchFilter === "" && "disabled"}`}
            placeholder="Search Brand Name...."
            onChange={handleSearchChange}
            disabled={resCount === 0 && searchFilter === "" ? true : false}
          />
        </div>
      </div>
      <div className="card-table table-wrapper">
        {resCount !== 0 ? <InfiniteScroll
          dataLength={isBrandsList && brandName.length}
          scrollableTarget="scrollablediv"
          className="infiniteCategoryTable"
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <table className="table-main">
              
                <tbody>
                    {loadingArr}
                </tbody>
            </table>
           }
          endMessage={endMsg && 
            <p style={{ textAlign: 'center' }}>
              <b>No more records found...</b>
            </p>
          }
        >
          <table className="table-main">
            <thead>
              <tr>
                <th colSpan="1" className="span-col"></th>
                <th>Icon</th>
                <th>Name</th>
                <th>Status</th>
                <th>Is Approved</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isBrandsList && brandName.map((item, index) => (
                <tr key={index}>
                  <td colSpan="1" className="span-col"></td>
                  <td>
                    {item.image !== "" ? <div className="edit">
                      <img
                        src={item.image}
                        width="40"
                        height="40"
                        alt="Profile"
                      />
                    </div>
                      : "No Image"}
                  </td>
                  <td>
                    <span>{item.name}</span>
                  </td>
                  <td>
                    <span
                      className={
                        item.isActive === "1"
                          ? "active-neutral-span highlighted"
                          : "decline-neutral-span highlighted"
                      }
                    >
                      {item.isActive === "1" ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={
                        item.isApproved === "1"
                          ? "active-neutral-span highlighted"
                          : "decline-neutral-span highlighted"
                      }
                    >
                      {item.isApproved === "1" ? "Approved" : "Not Approved"}
                    </span>
                  </td>
                  <td>
                    <div className="edit">
                      <img
                        src="./Image/pencil.png"
                        alt="Pencil"
                        onClick={() => handleClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src="./Image/delete.png"
                        alt="Remove"
                        onClick={() => handleRemoveButton(item.id)}
                        style={{ cursor: "pointer", marginLeft: '10px' }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll> : <EmptyList itemFor="Brands" ifBtn={searchFilter !== "" ? false : true} onAddNew={() => setShowModal(true)} btnText="Add Brand"></EmptyList>}
      </div>

      {products === {} ? (
        <AddBrand
          trigger={showModal}
          setTrigger={setShowModal}
          cellProduct={products}
          brandProducts={brandName}
          isOntheFly={false}
        />
      ) : (
          <AddBrand
          brandIndex={brandIndex}
          onRowUpdate={handleRowUpdate}
          trigger={showModal}
          setTrigger={setShowModal}
          brandProducts={brandName}
          cellProduct={products}
          setCellProduct={setProducts}
          name={products.name}
          icon={products.image}
          active={products.active}
          isOntheFly={false}
        />
      )}
    </div>
  );
}

export default Brand;

import axios from "axios";
import { toast } from "react-toastify";
let axiosMiddleware = {
  get: (urlPath) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(urlPath, {
            headers: {
              ApiKey: localStorage.getItem("ApiKey"),
              'Cache-Control': 'no-cache',
              "Access-Control-Allow-Origin": "*",
            }
          })
          .then(
            (successResp) => {
              return resolve(successResp);
            },
            (errResp) => {
              return reject(handleError(errResp));
            }
          );
      } catch (e) {
        return reject(e);
      }
    });
  },
  put: (urlPath, data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(urlPath, data, {
            headers: {
              ApiKey: localStorage.getItem("ApiKey"),
              'Cache-Control': 'no-cache',
              "Access-Control-Allow-Origin": "*",
            }
          })
          .then(
            (successResp) => {
              return resolve(successResp);
            },
            (errResp) => {
              return reject(handleError(errResp));
            }
          );
      } catch (e) {
        return reject(e);
      }
    });
  },
  post: (urlPath, data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(urlPath, data, {
            headers: {
              ApiKey: localStorage.getItem("ApiKey"),
              'Cache-Control': 'no-cache',
              "Access-Control-Allow-Origin": "*",
            }
          })
          .then(
            (successResp) => {
              return resolve(successResp);
            },
            (error) => {
              // console.log("create error",Object.assign({}, error.response.data));
              toast.error(error.response.data);
              return reject(handleError(Object.assign({}, error.response.data)));
            }
          );
      } catch (error) {
        return reject(error);
      }
    });
  },
  delete: (urlPath) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .delete(urlPath, {
            headers: {
              ApiKey: localStorage.getItem("ApiKey"),
              'Cache-Control': 'no-cache',
              "Access-Control-Allow-Origin": "*",
            }
          })
          .then(
            (successResp) => {
              return resolve(successResp);
            },
            (errResp) => {
              return reject(handleError(errResp));
            }
          );
      } catch (e) {
        return reject(e);
      }
    });
  },
};

export default axiosMiddleware;

const handleError = (errResp) => {
  if ((errResp.response && errResp.response.status && errResp.response.status === 401) || (errResp.response && errResp.response.status && errResp.response.status === 403)) {
    toast.error("Authorization Required");
    delete axios.defaults.headers["ApiKey"];
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
    errResp._IsDeterminedError = true;
  }else{
    toast.error("Internal Server Error at Network Request.");
    // console.log(errResp);
  }
  return errResp;
};

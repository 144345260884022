import React from "react";
import { NavLink, Link } from "react-router-dom";
// import OutsideClickHandler from "react-outside-click-handler";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
// import * as AiIcons from "react-icons/ai";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io5";
import * as FiIcons from "react-icons/fi";

import "./sidebar.css";

function Sidebar(props) {
  return (
    <>
      <div className="fixed-sidebar">
        <div className="slim-header with-sidebar">
          <div className="container-fluid">
            <div className="slim-header-left">
              <h2 className="slim-logo">
                <Link to="/category" className="logo-link">
                  <img src="./Image/logo2.png" alt="DayBulk Logo" />
                </Link>
                <Link to="/category" className="logo-link show-sidefix">
                  <img src="./Image/DB_Logo_2.png" alt="DayBulk Logo" />
                </Link>
              </h2>
            </div>
          </div>
        </div>
        <div className="slim-sidebar">
          <button
            className="sidebar-toggle-btn"
            onClick={props.onSidebarButtonClick}
          >
            <svg
              width="18"
              height="15"
              viewBox="0 0 21 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.752812 13.0002H19.2528C19.667 13.0002 20.0028 13.336 20.0028 13.7502C20.0028 14.1299 19.7207 14.4437 19.3546 14.4933L19.2528 14.5002H0.752812C0.338599 14.5002 0.00281239 14.1644 0.00281239 13.7502C0.00281239 13.3705 0.284966 13.0567 0.651042 13.007L0.752812 13.0002H19.2528H0.752812ZM0.752812 6.50318H19.2528C19.667 6.50318 20.0028 6.83897 20.0028 7.25318C20.0028 7.63288 19.7207 7.94668 19.3546 7.99634L19.2528 8.00318H0.752812C0.338599 8.00318 0.00281239 7.6674 0.00281239 7.25318C0.00281239 6.87349 0.284966 6.55969 0.651042 6.51003L0.752812 6.50318H19.2528H0.752812ZM0.751953 0.00341797H19.252C19.6662 0.00341797 20.002 0.339204 20.002 0.753418C20.002 1.13311 19.7198 1.44691 19.3537 1.49657L19.252 1.50342H0.751953C0.33774 1.50342 0.00195312 1.16763 0.00195312 0.753418C0.00195312 0.373722 0.284107 0.0599268 0.650183 0.0102644L0.751953 0.00341797H19.252H0.751953Z"
                fill="#143656"
              />
            </svg>
          </button>
          <ul className="nav nav-sidebar">
            <li className="sidebar-nav-item">
              <NavLink
                to="/dashboard"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <BiIcons.BiDetail
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/category"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <BiIcons.BiCategory
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Category</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/subcategory"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <BsIcons.BsListNested
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>SubCategory</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/brand"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <SiIcons.SiBrandfolder
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Brand</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/request"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <MdIcons.MdOutlineMoveToInbox
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Request</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/agent"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <MdIcons.MdSupportAgent
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Agent</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/seller"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <IoIcons.IoPersonOutline
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Seller</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/customers"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <FiIcons.FiUsers
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Customers</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/banner"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <BsIcons.BsImages
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Banner</span>
              </NavLink>
            </li>
            <li className="sidebar-nav-item">
              <NavLink
                to="/settings"
                className="sidebar-nav-link "
                activeClassName="nav-active"
              >
                <BsIcons.BsGear
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Settings</span>
              </NavLink>
            </li>
            <li
              className="sidebar-nav-item"
              style={{ position: "absolute", bottom: "0" }}
            >
              <NavLink to="/logout" className="sidebar-nav-link ">
                <FiIcons.FiLogOut
                  size={20}
                  className="sidemeny-iconactive siedbar-icons"
                />
                <span>Log Out</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;

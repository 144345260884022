/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import AgentSalesChart from "./AgentSalesChart";
import { toast } from 'react-toastify';
import ProductSaleOverview from './ProductSellOverview';
import ProductSalesChart from './ProductSalesChart';

const Dashboard = (props) => {
    const userData = JSON.parse(sessionStorage.getItem("User-Info"));
    const [key, setKey] = useState();
    // eslint-disable-next-line no-unused-vars
    const [roleId, setRoleId] = useState();

    useEffect(() => {
        if (sessionStorage.getItem("User-Info") === null || !localStorage.getItem("ApiKey")) {
            toast.error("Session Expired! Please login again...");
            window.location.replace("/")
        } else {
            setRoleId(userData.userRoleId);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setKey(document.getElementById("panel_wrapper").offsetWidth);
        }, 300)
    }, [props.onShowSideMenu]);

    return (
        <div className="container-category" style={{ overflow: "hidden" }} key={key}>
            <div className="pannel-header">
                <div className="left-sec pannel-cols">
                    <h2 className="panel-heading">Dashboard</h2>
                </div>
                <div className="right-sec pannel-cols">
                    Welcome Admin
                </div>
            </div>
            <div className="row d_card_container_row">
                <div className="col-6">
                    <AgentSalesChart></AgentSalesChart>
                </div>
                <div className="col-6">
                    <ProductSalesChart></ProductSalesChart>
                </div>
            </div>
            {/* <div className="row d_card_container_row">
                <div className="col-8">
                    
                </div>
            </div> */}
            <div className="row d_card_container_row">
                <div className="col-12">
                    <ProductSaleOverview></ProductSaleOverview>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
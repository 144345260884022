/* eslint-disable react-hooks/exhaustive-deps */
import { Chart } from "react-google-charts";
import { makeStyles } from "@mui/styles";
import axiosMiddleware from "../../../helper/axios";
import { useEffect, useState } from "react";
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import EmptyChart from "../EmptyState/EmptyChart";
// import DatePicker from "react-datepicker";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {SelectDateDropDown} from "./SelectDateDropDown";
import { getInitDate } from "../../../helper/helper";
import SkeletonLoading from "../../../components/SkeletonLoading";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "368px",
    },
}));

const AgentSalesChart = (props) => {
    const classes = useStyles();
    // const [initialDate, setInitiaDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [data, setData] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loading, setLoading] = useState(true);

    const loadAgentData = async () => {
        let url = ``;
        if (startDate && endDate) { 
            url = `agent/most-seller?limit=10&offset=0&startDate=${startDate}&endDate=${endDate}`;
        } else {
            let { sDate, eDate } = getInitDate(new Date())
            url = `agent/most-seller?limit=10&offset=0&startDate=${sDate}&endDate=${eDate}`;
        }
        await axiosMiddleware.get(url).then((res) => {
            let tempData = [["Agents", "Delivered"]];
            if (res.data.Status) {
                _forEach(res.data.Data, (value, index) => {
                    if (parseInt(value.totalRequest) !== 0) {
                        tempData.push([`${value.firstName} ${value.lastName}`, parseInt(value.totalRequest)]);
                    }
                });
            } else {
                tempData = [];
            }
            setData(tempData);
            setLoading(false);
        }).catch((e) => {
            console.log(e);
            setLoading(false);
        });
    }

    // const chartEvents = [
    //     {
    //         eventName: "select",
    //         callback({ chartWrapper }) {
    //             console.log("Selected ", chartWrapper.getChart().getSelection());
    //         }
    //     }
    // ];

    useEffect(() => {
        setTimeout(() => {
            loadAgentData();
        }, 300);
    }, [startDate, endDate]);

    const OnDateRangeChange = (dateRange) => { 
        setStartDate(dateRange.startDate);
        setEndDate(dateRange.endDate);
    }
    
    return (
        <div className="d_card_container">
            <div className="d_card">
                <div className="d_card_header">
                    <div>Data - Agents by most sales</div>
                    <SelectDateDropDown OnDateRangeChange={OnDateRangeChange} onPosition="right" />
                    {/* <DatePicker
                        placeholderText="Select Month"
                        selected={new Date(agentStartDate)}
                        onChange={(date) => setAgentStartDate(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        withPortal
                        maxDate={new Date()}
                    /> */}
                </div>
                <div className="d_card_body">
                    {!_isEmpty(data) ? <Chart
                        className={classes.root}
                        loader={<SkeletonLoading
                            height="370px"
                        />}
                        chartType="Bar"
                        data={data}
                        options={{
                            // title: ' of Largest U.S. Cities',
                            chartArea: { width: "auto" },
                            hAxis: {
                                title: "Total Sales ",
                                minValue: 0,
                            },
                            vAxis: {
                                title: "Agent",
                            },
                            animation: {
                                duration: 1000,
                                easing: 'out',
                            },
                        }}
                        // chartEvents={chartEvents}
                    /> : loading ? <SkeletonLoading
                    height="370px"
                /> :<EmptyChart></EmptyChart>}
                </div>
            </div>
        </div>
    );
}

export default AgentSalesChart;

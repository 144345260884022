/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import isEmpty from "lodash/isEmpty";
import _concat from "lodash/concat";
import _get from "lodash/get";
import _forEach from 'lodash/forEach';
import AddAgent from "../pop-up-model-pages/AddAgent";
import axiosMiddleware from "../../../helper/axios";
import swal from "sweetalert";
import { toast } from "react-toastify";
import EmptyList from "../EmptyState/EmptyList";
import { useDebounce } from "../../../helper/UseDebounce";
import * as FaIcons from "react-icons/fa";
import moment from "moment";
import SkeletonLoading from "../../../components/SkeletonLoading";

function Agent(props) {
  const [hasMore, setHasMore] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [limit] = useState(15);
  const [offSet, setOffSet] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [agent, setAgent] = useState([]);
  const [isAgentList, setIsAgentList] = useState(false)
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [agentIndex, setAgentIndex] = useState(-1);
  const [agentDetailsShow, setAgentDetailsShow] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("User-Info"));
  const [resCount, setResCount] = useState(1);
  const [roleId, setRoleId] = useState();
  const debouncedSearchTerm = useDebounce(searchFilter, 1000);
  const [endMsg, setEndMsg] = useState(true);
  const [requestData, setRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("User-Info") === null || !localStorage.getItem("ApiKey")) {
      toast.error("Session Expired! Please login again...");
      window.location.replace("/")
    } else {
      setRoleId(userData.userRoleId);
    }
  }, []);

  const loadAgents = async () => {
    await axiosMiddleware.get(`agent?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&userRoleId=${roleId}`).then((res) => {
      const totalCount = (res.data.Data || []).length;
      if (!res.data.Status) {
        setHasMore(false);
        setAgent([]);
        setIsAgentList(false);
        setResCount(0);
      }
      if (res.data.Status && totalCount === 15) {
        setOffSet(offSet + limit);
        setAgent(res.data.Data);
        setIsAgentList(true);
        setHasMore(true);
        setResCount(totalCount);
      }
      if (res.data.Status && totalCount > 0 && totalCount < 15) { 
        setOffSet(offSet + limit);
        setAgent(res.data.Data);
        setIsAgentList(true);
        setHasMore(false);
        setResCount(totalCount);
        setEndMsg(false);
      }
    }).catch((e) => {
      console.log(e)
    });
  }

  useEffect(() => {
    loadAgents();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [showModal]);

  const loadRequestList = async (id) => {
    setIsLoading(true);
    setRequestData([]);
    await axiosMiddleware.get(`request?agentIds=${id}`).then((res) => {
        const totalCount = (res.data.Data || []).length;
        if (!res.data.Status) {
        setIsLoading(false);
        setRequestData([]);
        }else{
        setIsLoading(false);
        setRequestData(res.data.Data);
        }
    }).catch((e) => {
        toast.error("Error...! " + e);
        // console.log(err);
    });
    }

  function handleSearchChange(e) {
    setOffSet(0);
    setSearchFilter(e.target.value);
  }

  function handleClick(cellIndex) {
    setSelectedAgent(agent[cellIndex]);
    setAgentIndex(cellIndex);
    setShowModal(true);
  }

  function handleShow(cellIndex) {
    setSelectedAgent(agent[cellIndex]);
    if(cellIndex == agentIndex){
      setAgentDetailsShow(!agentDetailsShow)
    }
    else{
      loadRequestList(agent[cellIndex].id)
      setAgentIndex(cellIndex);
      setAgentDetailsShow(true)
    }
  }

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedAgent([]);
  };

  const handleRowUpdate = (data, index) => {
    agent[index] = data;
  };

  const handleRowInsert = (data) => {
    agent.unshift(data);
  };

  async function fetchMoreData() {
    await axiosMiddleware.get(`agent?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&userRoleId=${userData.userRoleId}`)
      .then((res) => {
        let dummyArray = _get(res, "data.Data", []);
        let fetchArray = _concat(agent, dummyArray);
        setAgent(fetchArray);
        setOffSet(offSet + limit);
        if (dummyArray.length === 0) {
          setHasMore(false);
        }
      }).catch((e) => {
        console.log(e);
      });
  }

  const handleRemoveButton = (agentId) => {
    let newAgent = [];
    swal({
      title: "Are you sure?",
      text: "Once deleted, can not be restored!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        await axiosMiddleware.delete(`agent/${agentId}`).then((res) => {
          if (agent.length === 1) {
            loadAgents();
          } else { 
            _forEach(agent, (value, index) => {
              if (value.id !== agentId) {
                newAgent.push(value);
              }
            });
            setAgent(newAgent);
          }
          toast.success("Record Deleted Successfully");
        }).catch((e) => {
          toast.error("Error ! " + e);
          console.log(e);
        });
      }
    });
  }

  const loadingArr = [];

  for (let index = 0; index < 6; index++) {
    loadingArr.push(<tr>
      <td colSpan="2"><SkeletonLoading/></td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      </tr>)
  }
  return (
    <div className="container-agent">
      <div className="pannel-header">
        <div className="left-sec pannel-cols">
          <h2 className="panel-heading">Agent</h2>
        </div>
        <div className="right-sec pannel-cols">
          <button
            className="btn primary small"
            type="submit"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <img src="./Image/plus_icon.png" alt="Plus" />
            <span>Add Agent</span>
          </button>
        </div>
      </div>
      <div className="search-field">
        <div className="form-element-group search-frm">
          <input
            type="text"
            className={`small data-hj-allow ${resCount === 0 && searchFilter === "" && "disabled"}`}
            placeholder="Search Agent Name...."
            onChange={handleSearchChange}
            disabled={ resCount === 0 && searchFilter === "" ? true : false}
          />
        </div>
      </div>
      <div className="card-table table-wrapper">
        {resCount !== 0 ? <InfiniteScroll
          dataLength={agent && agent.length}
          next={fetchMoreData}
          loader={
            <table className="table-main">
              
                <tbody>
                    {loadingArr}
                </tbody>
            </table>
           }
          endMessage={endMsg && 
            <p style={{ textAlign: 'center' }}>
              <b>No more records found...</b>
            </p>
          }
          hasMore={hasMore}
          scrollableTarget="scrollablediv"
          className="infiniteRequestTable"
        >
          <table className="table-main">
            <thead>
              <tr>
                <th colSpan="1" className="span-col">
                </th>
                <th>
                  Profile Picture
                </th>
                <th>
                  Agent Name
                </th>
                <th>
                  Contact Number
                </th>
                <th>
                  Email
                </th>
                <th>
                  Zip Code
                </th>
                <th>
                  Channel ID
                </th>
                <th>
                  Status
                </th>
                <th>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* eslint-disable-next-line array-callback-return */}
              {isAgentList && agent.map((agentObj, index) => (
                <>
                <tr key={index}>
                  <td colSpan="1" className="span-col"></td>
                  <td>
                    {agentObj.profilePicture !== "" ? <div className="edit">
                      <img
                        src={agentObj.profilePicture}
                        width="40"
                        height="40"
                        alt="Profile"
                      />
                    </div>
                      : "No Image"}
                  </td>
                  <td>
                    <span>{agentObj.firstName + " " + agentObj.lastName}</span>
                  </td>
                  <td>
                    <span>{agentObj.contactNumber}</span>
                  </td>
                  <td>
                    <span>{agentObj.email}</span>
                  </td>
                  <td>
                    <span>{agentObj.zipCode}</span>
                  </td>
                  <td>
                    <span>{agentObj.referralCode}</span>
                  </td>
                  <td>
                    <span className={parseInt(agentObj.isActive) ? "active-neutral-span highlighted" : "decline-neutral-span highlighted"}>
                      {parseInt(agentObj.isActive) ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td>
                    <div className="edit">
                      <img
                        src="./Image/pencil.png"
                        alt="Pencil"
                        onClick={() => handleClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src="./Image/delete.png"
                        alt="Remove"
                        onClick={() => handleRemoveButton(agentObj.id)}
                        style={{ cursor: "pointer", marginLeft: '10px' }}
                      />
                      {(agentIndex == index && agentDetailsShow) ? 
                        <FaIcons.FaChevronUp
                        size={20}
                        style={{
                          color: "#6b6b6f",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleShow(index)}
                      />
                      :<FaIcons.FaChevronDown
                        size={20}
                        style={{
                          color: "#6b6b6f",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleShow(index)}
                      />}
                      
                    </div>
                  </td>
                </tr>
                <tr className={`drop-table ${(agentIndex == index && agentDetailsShow) ? 'active':""}`}>
                        <th></th>
                        <th>CUSTOMER</th>
                        <th>CONTACT</th>
                        <th>PRODUCT DETAILS</th>
                        <th>QUANTITY</th>
                        <th>CREATED AT</th>
                        <th>EXPECTED DELIVERY</th>
                        <th>STATUS</th>
                        <th></th>
                </tr>
                {isLoading &&
                <tr className={`drop-table ${(agentIndex == index && agentDetailsShow) ? 'active':""}`}>
                      <td className="text-center" colSpan={10}>Loading Data......</td>
                </tr>}   
                {requestData.length > 0 ? requestData.map((requestObj, subindex) => (
                <tr className={`drop-table ${(agentIndex == index && agentDetailsShow) ? 'active':""}`} key={index}>
                  <td colSpan="1" className="span-col"></td>
                  <td>
                    <span>
                      {requestObj.customer.firstName + " " + requestObj.customer.lastName}
                    </span>
                  </td>
                  <td >
                    <span>{requestObj.customer.contactNumber}</span>
                  </td>
                  <td>
                    <span>
                      <div className="productDetailSpan">
                        <span className="categorySpan">
                          {requestObj.mainCategoryName}
                        </span>
                        <span className="subCategorySpan">
                          {requestObj.categoryName}
                        </span>
                        <span className="subCategorySpan">
                          {requestObj.brandName}
                        </span>
                      </div>
                    </span>
                  </td>
                  <td>{requestObj.quantity}</td>
                  <td>{moment(requestObj.createdAt, 'YYYY-MM-DD').format('DD-MMM-YYYY')}</td>
                  <td>{moment(requestObj.expectedDeliveryDate, 'YYYY-MM-DD').format('DD-MMM-YYYY')}</td>
                  <td>
                    <span className={
                      requestObj.requestStatus === "Open" ? "open-neutral-span highlighted" : requestObj.requestStatus === "Closed" ? "decline-neutral-span highlighted" : "delivered-neutral-span highlighted"
                    }
                    >
                      {requestObj.requestStatus}
                    </span>
                  </td>
                  <td></td>
                </tr>
              )): !isLoading ?
              <tr className={`drop-table ${(agentIndex == index && agentDetailsShow) ? 'active':""}`}>
                      <td className="text-center" colSpan={9}>No Data Found.</td>
              </tr>:""
              }
                </>
              ))}
            </tbody>
          </table>
        </InfiniteScroll> : <EmptyList itemFor="Agents" ifBtn={searchFilter !== "" ? false : true} onAddNew={() => setShowModal(true)} btnText="Add Agent"></EmptyList>}
      </div>

      {showModal && (
        <>
          {" "}
          <AddAgent
            agentIndex={agentIndex}
            onRowUpdate={handleRowUpdate}
            onRowInsert={handleRowInsert}
            onAddNew={isEmpty(selectedAgent) ? true : false}
            selectedAgent={selectedAgent}
            onClose={handleOnClose}
          />
        </>
      )}
    </div>
  );
}

export default Agent;

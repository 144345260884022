import React, {PropsWithChildren} from 'react'
import Skeleton, {SkeletonTheme}from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function InlineWrapperWithMargin({ children }) {
  return <span style={{ marginRight: '0.5rem' }}>{children}</span>
}
export default function SkeletonLoading({width, height, circle, inline, containerClassName, count, baseColor, highlightColor, wrapper, ...args}) {
    const totalwidth = width?width:circle?100:"";
    const totalheight = height?height:circle?100:"";
  return (
    <Skeleton
    circle={circle}
    inline={inline}
    wrapper={wrapper?wrapper:InlineWrapperWithMargin}
    width={totalwidth}
    height={totalheight}
    count={count}
    containerClassName={containerClassName}
     />
  )
}

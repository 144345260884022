/* eslint-disable react-hooks/exhaustive-deps */
import axiosMiddleware from "../../../helper/axios";
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import _concat from "lodash/concat";
import _get from "lodash/get";
import _forEach from 'lodash/forEach';
import swal from "sweetalert";
import AddSubCategory from "../pop-up-model-pages/AddSubCategory";
import { toast } from "react-toastify";
import EmptyList from "../EmptyState/EmptyList";
import { useDebounce } from "../../../helper/UseDebounce";
import { iDToValueResolveAsyncSelect } from "../../../helper/helper";
import AsyncSelect from "react-select/async";
import '../Request/request.css';
import { useLocation } from "react-router-dom";
import SkeletonLoading from "../../../components/SkeletonLoading";

function SubCategory(props) {
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState({});
  const [searchFilter, setSearchFilter] = useState("");
  const [subCategoryProduct, setSubCategoryProduct] = useState([]);
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [limit] = useState(15);
  const [offSet, setOffSet] = useState(0);
  const [catIndex, setCatIndex] = useState(-1);
  const [isSubCatgeoryList, setIsSubCatgeoryList] = useState(false)
  const userData = JSON.parse(sessionStorage.getItem("User-Info"));
  const [resCount, setResCount] = useState(1);
  const [roleId, setRoleId] = useState();
  const debouncedSearchTerm = useDebounce(searchFilter, 1000);
  const [endMsg, setEndMsg] = useState(true);
  const location = useLocation();
  useEffect(() => {
    if (sessionStorage.getItem("User-Info") === null || !localStorage.getItem("ApiKey")) {
      toast.error("Session Expired! Please login again...");
      window.location.replace("/")
    } else {
      setRoleId(userData.userRoleId);
    }
    if(location.state){
      setSelectedMainCategory(location.state.value);
    }
  }, []);

  async function loadCategory() {
    var url = `category?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&parentId=&userRoleId=${roleId}`;
    if (selectedMainCategory) { 
      url = `category?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&parentId=${selectedMainCategory
}&userRoleId=${roleId}`
    }
    await axiosMiddleware.get(url).then((res) => {
      const totalCount = (res.data.Data || []).length;
      if (!res.data.Status) {
        setHasMore(false);
        setSubCategoryProduct([]);
        setIsSubCatgeoryList(false);
        setResCount(0);
      }
      if (res.data.Status && totalCount === 15) {
        setOffSet(offSet + limit);
        setSubCategoryProduct(res.data.Data);
        setIsSubCatgeoryList(true);
        setHasMore(true);
        setResCount(totalCount);
      }
      if (res.data.Status && totalCount > 0 && totalCount < 15) { 
        setOffSet(offSet + limit);
        setSubCategoryProduct(res.data.Data);
        setIsSubCatgeoryList(true);
        setHasMore(false);
        setResCount(totalCount);
        setEndMsg(false)
      }
    }).catch((e) => {
      toast.error("Error...! Unable to load Main Categories");
      // console.log(e);
    });
  }

  async function loadMainCategory(searchText) {
    var url = searchText ? `category?searchText=${searchText}&parentId=0&userRoleId=${roleId}` : `category?parentId=0&userRoleId=${roleId}`;
    await axiosMiddleware.get(url).then((res) => { 
      let newOption = iDToValueResolveAsyncSelect(res.data.Data, []);
      // let newOption2 = iDToValueResolveAsyncSelect(res.data.Data, ["firstName","lastName","email","contactNumber"]);
      const options = [
        {
          options: [
            ...newOption
          ]
        }
      ];
      setMainCategoryList(options);
    }).catch((e) => {
      toast.error("Error...! Error in fetching Main Category for filter.");
      // console.log("Error in fetching Main Category for filter", e);
    });
  }

  useEffect(() => {
    loadCategory();
  }, [debouncedSearchTerm, selectedMainCategory]);
  
  useEffect(() => {
    loadMainCategory(undefined);
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [showModal]);

  function fetchMoreData() {
    var url = `category?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&parentId=&userRoleId=${userData.userRoleId}`;
    if (selectedMainCategory) { 
      url = `category?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&parentId=${selectedMainCategory
}&userRoleId=${userData.userRoleId}`;
    }
    axiosMiddleware.get(url).then((res) => {
        let dummyArray = _get(res, "data.Data", []);
        let fetchArray = _concat(subCategoryProduct, dummyArray);
        setSubCategoryProduct(fetchArray);
        setOffSet(offSet + limit);
        if (dummyArray.length === 0) {
          setHasMore(false);
        }
    }).catch((e) => {
      toast.error("Error...! Something went wrong, unable to load more categories.");
      // console.log(e);
    });
  }

  function handleClick(cellIndex) {
    setProducts(subCategoryProduct[cellIndex]);
    setCatIndex(cellIndex);
    setShowModal(true);
  }

  function handleSearchChange(e) {
    setOffSet(0);
    setSearchFilter(e.target.value);
  }

  const handleRowUpdate = (data, index) => {
    subCategoryProduct[index] = data;
  };

  const handleRemoveButton = (subCatID) => {
    let newsubCategoryProduct = [];
    swal({
      title: "Are you sure?",
      text: "Once deleted, can not be restored!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        await axiosMiddleware.delete(`category/${subCatID}`).then((res) => {
          if (subCategoryProduct.length === 1) {
            loadCategory();
          } else { 
          _forEach(subCategoryProduct, (value, index) => {
            if (value.id !== subCatID) {
              newsubCategoryProduct.push(value);
            }
          });
          setSubCategoryProduct(newsubCategoryProduct);
          }
          toast.success("Record Deleted Successfully");
        }).catch((e) => {
          toast.error("Error ! " + e);
          // console.log(e);
        });
      }
    });
  }

  const handleFilterByCategory = (e) => {
    setOffSet(0);
    e ? setSelectedMainCategory(e.value) : setSelectedMainCategory(0);
    loadMainCategory('');
  }

  const filterSelect = (inputValue) => {
    let filterObj = mainCategoryList[0].options;
    return filterObj.filter((i) => i?.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadCategoryInSelect = (inputValue, callback) => {
    loadMainCategory(inputValue);
    setTimeout(() => {
      callback(filterSelect(inputValue));
    }, 800)
  };

  const loadingArr = [];

  for (let index = 0; index < 6; index++) {
    loadingArr.push(<tr>
      <td colSpan="2" className="span-col"><SkeletonLoading/></td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      </tr>)
  }
  return (
    <div className="subcategory-container container-request">
      <div className="pannel-header">
        <div className="left-sec pannel-cols">
          <h2 className="panel-heading">Sub Category</h2>
        </div>
        <div className="right-sec pannel-cols">
          <button
            className="btn primary small"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            <img src="./Image/plus_icon.png" alt="Plus Icon" />
            <span>Add Sub Category</span>
          </button>
        </div>
      </div>
      <div className="search-field">
        <div className="filter-drop" style={{ minWidth: "300px" }}>
          <AsyncSelect
            name="_filter_category"
            options={mainCategoryList}
            loadOptions={loadCategoryInSelect}
            defaultOptions={mainCategoryList}
            defaultValue={location.state && {value: location.state.value, label: location.state.label}}
            isClearable={true}
            onChange={(e) => { handleFilterByCategory(e) }}
            placeholder="Filter by Main Category...."
          />
        </div>
        {/* {resCount !== 0 ? 
        
          : ""} */}
          <div className="form-element-group search-frm">
            <input
              type="text"
              className={`small data-hj-allow ${resCount === 0 && searchFilter === "" && "disabled"}`}
              placeholder="Search Sub Category...."
              onChange={handleSearchChange}
              disabled={resCount === 0 && searchFilter === "" ? true : false}
          />
        </div>
      </div>
      <div className="card-table table-wrapper">
        {resCount !== 0 ? (<InfiniteScroll
          dataLength={subCategoryProduct && subCategoryProduct.length}
          next={fetchMoreData}
          loader={
              <table className="table-main">
                
                  <tbody>
                      {loadingArr}
                  </tbody>
              </table>
          }
          hasMore={hasMore}
          endMessage={endMsg && 
            <p style={{ textAlign: 'center' }}>
              <b>No more records found...</b>
            </p>
          }
          scrollableTarget="scrollablediv"
          className="infiniteCategoryTable"
        >
          <table className="table-main">
            <thead>
              <tr>
                <th colSpan="1" className="span-col"></th>
                <th>Icon</th>
                <th>Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Is Approved</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isSubCatgeoryList && subCategoryProduct.map((item, index) => (
                <tr key={index}>
                  <td colSpan="1" className="span-col"></td>
                  <td>
                    <img
                      src={item.image}
                      width="30"
                      height="30"
                      alt="Logo"
                    />
                  </td>
                  <td>
                    <span>{item.name}</span>
                  </td>
                  <td>
                    <span>{item.description}</span>
                  </td>
                  <td>
                    <span
                      className={
                        item.isActive === "1"
                          ? "active-neutral-span highlighted"
                          : "decline-neutral-span highlighted"
                      }
                    >
                      {item.isActive === "1" ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={
                        item.isApproved === "1"
                          ? "active-neutral-span highlighted"
                          : "decline-neutral-span highlighted"
                      }
                    >
                      {item.isApproved === "1" ? "Approved" : "Not Approved"}
                    </span>
                  </td>
                  <td>
                    <div className="edit">
                      <img
                        src="./Image/pencil.png"
                        alt="Pencil"
                        onClick={() => handleClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src="./Image/delete.png"
                        alt="Remove"
                        onClick={() => handleRemoveButton(item.id)}
                        style={{ cursor: "pointer", marginLeft: '10px' }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>) : (<EmptyList itemFor="Sub Category" ifBtn={searchFilter !== "" ? false : true} onAddNew={() => setShowModal(true)} btnText="Add Sub Category"></EmptyList>)}
      </div>

      {showModal && (
        <>
          {products === {} ? (
            <AddSubCategory
              trigger={showModal}
              setTrigger={setShowModal}
              cellProduct={products}
              isOntheFly={false}
            />
          ) : (
            <AddSubCategory
              catIndex={catIndex}
              onRowUpdate={handleRowUpdate}
              trigger={showModal}
              setTrigger={setShowModal}
              cellProduct={products}
              setCellProduct={setProducts}
              name={products.name}
              icon={products.image}
              active={products.active}
              subProduct={products.subProduct}
              isOntheFly={false}
            />
          )}
        </>
      )}
    </div>
  );
}

export default SubCategory;

import axios from "axios";
import { useEffect } from "react";
import { createBrowserHistory } from "history";

function Logout() {
  const history = createBrowserHistory({ forceRefresh: true });

  useEffect(() => {
    delete axios.defaults.headers["ApiKey"];
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
  }, [history]);

  return <></>;
}

export default Logout;

import isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
export const valueToIDresolveAsyncSelect = (obj) => {
    let newSelectedArray = [];
    if (obj && obj.length > 1) {
        for (let key of obj) {
            newSelectedArray.push({ id: key.value });
        }
        return newSelectedArray;
    } else if (obj && obj.length === 1) {
        newSelectedArray.push({ id: obj[0].value });
        return newSelectedArray;
    } else {
        return newSelectedArray;
    }
};

export const iDToValueResolveAsyncSelect = (obj, concatLabels = []) => {
    let newSelectedArray = [];
    if (obj && obj.length > 1) {
        for (let key of obj) {
            if (!isEmpty(concatLabels)) {
                let concatText = "";
                _forEach(concatLabels, (value, index) => {
                    concatText += key[value] + " "
                });
                newSelectedArray.push({ value: key.id, label: concatText });
            } else { 
                newSelectedArray.push({ value: key.id, label: key.name });
            }

        }
        return newSelectedArray;
    } else if (obj && obj.length === 1) {
        if (!isEmpty(concatLabels)) {
            let concatText = "";
            _forEach(concatLabels, (value, index) => {
                concatText += obj[0][value] + " "
            });
            newSelectedArray.push({ value: obj[0].id, label: concatText });
        } else {
            newSelectedArray.push({ value: obj[0].id, label: obj[0].name });
        }
        return newSelectedArray;
    } else {
        return newSelectedArray;
    }
};

export const iDToValueResolveAsyncSelectAllCol = (obj, concatLabels = []) => {
    let newSelectedArray = [];
    if (obj && obj.length > 1) {
        for (let key of obj) {
            let concatText = "";
            _forEach(concatLabels, (value, index) => {
                concatText += key[value] + " ";
            });
            newSelectedArray.push({ value: key.id, label: concatText, ...key });
        }
        return newSelectedArray;
    } else if (obj && obj.length === 1) {
        newSelectedArray.push({ value: obj[0].id, label: `${obj[0].firstName} ${obj[0].lastName}`, ...obj[0] });
        return newSelectedArray;
    } else {
        return newSelectedArray;
    }
};

export const iDToValueResolveSelect = (obj) => {
    let newSelectedArray = [];
    if (obj && obj.length > 1) {
        for (let key of obj) {
            newSelectedArray.push({ value: key.id, label: key.name, image: key.image });
        }
        return newSelectedArray;
    } else if (obj && obj.length === 1) {
        newSelectedArray.push({ value: obj[0].id, label: obj[0].name, image: obj[0].image });
        return newSelectedArray;
    } else {
        return newSelectedArray;
    }
};

export const getInitDate = (date) => {
    // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const priorDate = new Date(new Date().setDate(date.getDate() - 30));
    // var sDate = date.getFullYear() + "-" + parseInt(date.getMonth() + 1) + "-" + 1;
    var sDate = priorDate.getFullYear() + "-" + parseInt(priorDate.getMonth() + 1) + "-" + priorDate.getDate();
    // var eDate = lastDay.getFullYear() + "-" + (parseInt(lastDay.getMonth()) + 1) + "-" + lastDay.getDate();
    var eDate = date.getFullYear() + "-" + (parseInt(date.getMonth()) + 1) + "-" + date.getDate();
    return { sDate, eDate };
}
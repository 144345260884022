/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axiosMiddleware from "../../../helper/axios";
import _concat from "lodash/concat";
import _get from "lodash/get";
import _forEach from 'lodash/forEach';
import AddCategory from "../pop-up-model-pages/AddCategory";
import swal from "sweetalert";
import { toast } from "react-toastify";
import EmptyList from "../EmptyState/EmptyList";
import { useDebounce } from "../../../helper/UseDebounce";
import { Link } from "react-router-dom";
import SkeletonLoading from "../../../components/SkeletonLoading";

function Category(props) {
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState({ name: "", icon: "", active: "" });
  const [searchFilter, setSearchFilter] = useState("");
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [limit] = useState(15);
  const [hasMore, setHasMore] = useState(true);
  const [catIndex, setCatIndex] = useState(-1);
  const [isCategoryList, setIsCategoryList] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("User-Info"));
  const [resCount, setResCount] = useState(1);
  const [roleId, setRoleId] = useState();
  const [endMsg, setEndMsg] = useState(true);
  const debouncedSearchTerm = useDebounce(searchFilter, 1000);
  useEffect(() => {
    if (sessionStorage.getItem("User-Info") === null || !localStorage.getItem("ApiKey")) {
      toast.error("Session Expired! Please login again...");
      window.location.replace("/")
    } else {
      setRoleId(userData.userRoleId);
    }
  }, []);

  async function loadCategory() {
    await axiosMiddleware.get(`category?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=&orderTo=&parentId=0&userRoleId=${roleId}`).then((res) => {
      const totalCount = (res.data.Data || []).length;
      if (!res.data.Status) {
        setHasMore(false);
        setCategoryProducts([]);
        setIsCategoryList(false);
        setResCount(0);
      }
      if (res.data.Status && totalCount === 15) {
        setOffSet(offSet + limit);
        setCategoryProducts(res.data.Data);
        setIsCategoryList(true);
        setHasMore(true);
        setResCount(totalCount);
      }
      if (res.data.Status && totalCount > 0 && totalCount < 15) {
        setOffSet(offSet + limit);
        setCategoryProducts(res.data.Data);
        setIsCategoryList(true);
        setHasMore(false);
        setResCount(totalCount);
        setEndMsg(false)
      }
    }).catch((e) => {
      toast.error("Error...! Something went wrong, Unable to load Catgeories List.");
      // console.log(e);
    });

  }

  useEffect(() => {
    loadCategory();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [showModal]);

  function handleClick(cellIndex) {
    setProducts(categoryProducts[cellIndex]);
    setCatIndex(cellIndex);
    setShowModal(true);
  }

  function handleSearchChange(e) {
    setOffSet(0)
    setSearchFilter(e.target.value);
  }

  async function fetchMoreData() {
    await axiosMiddleware.get(`category?searchText=${searchFilter}&limit=${limit}=&offset=${offSet}=&sortBy=&orderTo=&parentId=0&userRoleId=${userData.userRoleId}`).then((res) => {
      let dummyArray = _get(res, "data.Data", []);
      let fetchArray = _concat(categoryProducts, dummyArray);
      setCategoryProducts(fetchArray);
      setOffSet(offSet + limit);
      if (dummyArray.length === 0) {
        setHasMore(false);
      }
    }).catch((e) => {
      toast.error("Error...! Something went wrong, Unable to load More Catgeories.");
      // console.log(e);
    });
  }

  const handleRowUpdate = (data, index) => {
    categoryProducts[index] = data;
  };

  const handleRemoveButton = (catID) => {
    let newcategoryProducts = [];
    swal({
      title: "Are you sure?",
      text: "Once deleted, can not be restored!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        await axiosMiddleware.delete(`category/${catID}`).then((res) => {
          if (categoryProducts.length === 1) {
            loadCategory();
          } else { 
          _forEach(categoryProducts, (value, index) => {
            if (value.id !== catID) {
              newcategoryProducts.push(value);
            }
          });
          setCategoryProducts(newcategoryProducts);
          }
          toast.success("Record Deleted Successfully");
        }).catch((e) => {
          toast.error("Error ! Error in deleting the record, please try again.");
          // console.log(e);
        });
      }
    });
  }
  const loadingArr = [];

  for (let index = 0; index < 6; index++) {
    loadingArr.push(<tr>
      <td colSpan="2" className="span-col"><SkeletonLoading/></td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      </tr>)
  }
  return (
    <div className="container-category">
      <div className="pannel-header">
        <div className="left-sec pannel-cols">
          <h2 className="panel-heading">Category</h2>
        </div>
        <div className="right-sec pannel-cols">
          <button
            className="btn primary small"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            <img src="./Image/plus_icon.png" alt="Plus Symbol" />
            <span>Add Category</span>
          </button>
        </div>
      </div>

      <div className="search-field" style={{ overflow: "hidden" }}>
          <div className="form-element-group search-frm">
            <input
              type="text"
            className={`small data-hj-allow ${resCount === 0 && searchFilter === "" && "disabled"}`}
              placeholder="Search Category Name...."
            onChange={handleSearchChange}
            disabled={resCount === 0 && searchFilter === "" ? true : false}
            />
        </div>
      </div>
      <div className="card-table table-wrapper">
        {resCount !== 0 ? <InfiniteScroll
          dataLength={isCategoryList && categoryProducts.length}
          scrollableTarget="scrollablediv"
          className="infiniteCategoryTable"
          next={fetchMoreData}
          hasMore={hasMore}
          endMessage={endMsg &&
            <p style={{ textAlign: 'center' }}>
              <b>No more records found...</b>
            </p>
          }
          loader={
              <table className="table-main">
                  <tbody>
                      {loadingArr}
                  </tbody>
              </table>
          }
        >
          <table className="table-main">
            <thead>
              <tr>
                <th colSpan="1" className="span-col"></th>
                <th>Icon</th>
                <th>Name</th>
                <th>Status</th>
                <th>Is Approved</th>
                <th>Actions</th>
                <th>Subcategories Details</th>
              </tr>
            </thead>
            <tbody>
              {isCategoryList && categoryProducts.map((item, index) => (
                <tr key={index}>
                  <td colSpan="1" className="span-col"></td>
                  <td>
                    <img
                      src={item.image}
                      width="30"
                      height="30"
                      alt="Logo"
                    />
                  </td>
                  <td>
                    <span>{item.name}</span>
                  </td>
                  <td>
                    <span
                      className={
                        item.isActive === "1"
                          ? "active-neutral-span highlighted"
                          : "decline-neutral-span highlighted"
                      }
                    >
                      {item.isActive === "1" ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={
                        item.isApproved === "1"
                          ? "active-neutral-span highlighted"
                          : "decline-neutral-span highlighted"
                      }
                    >
                      {item.isApproved === "1" ? "Approved" : "Not Approved"}
                    </span>
                  </td>
                  <td>
                    <div className="edit">
                      <img
                        src="./Image/pencil.png"
                        alt="Pencil"
                        onClick={() => handleClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src="./Image/delete.png"
                        alt="Remove"
                        onClick={() => handleRemoveButton(item.id)}
                        style={{ cursor: "pointer", marginLeft: '10px' }}
                      />
                    </div>
                  </td>
                  <td>
                    <Link to={{ pathname: '/subcategory', state: { value: item.id, label: item.name} }} className="btn primary small" type="button">
                      <i class="fa fa-external-link" style={{color:"#939397"}}></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll> : <EmptyList itemFor="Category" ifBtn={searchFilter !== "" ? false : true} onAddNew={() => setShowModal(true)} btnText="Add Category"></EmptyList>}
      </div>

      {products === {} ? (
        <AddCategory
          trigger={showModal}
          setTrigger={setShowModal}
          categoryProducts={categoryProducts}
          cellProduct={products}
          isOntheFly={false}
        />
      ) : (
        <AddCategory
          catIndex={catIndex}
          onRowUpdate={handleRowUpdate}
          trigger={showModal}
          setTrigger={setShowModal}
          categoryProducts={categoryProducts}
          cellProduct={products}
          setCellProduct={setProducts}
          isOntheFly={false}
        />
      )}
    </div>
  );
}

export default Category;

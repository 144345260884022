import ContactSetting from './ContactSetting';
import EmailInvitation from './EmailInvitation';

const Settings = (props) => {

    return (
        <div className="container-category" style={{ overflow: "hidden" }}>
            <div className="pannel-header">
                <div className="left-sec pannel-cols">
                    <h2 className="panel-heading">Settings</h2>
                </div>
                <div className="right-sec pannel-cols">
                    Welcome Admin
                </div>
            </div>
            <div className="row d_card_container_row">
                <div className="col-6">
                    <ContactSetting></ContactSetting>
                </div>
                <div className="col-6">
                    <EmailInvitation></EmailInvitation>
                </div>
            </div>
        </div>
    );
}

export default Settings;
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import useForm from "../../helper/useForm";
import ValidateRules from "../../helper/ValidateRules";
import axiosMiddleware from "../../helper/axios";
import { toast } from "react-toastify";
import "./signin.css";

function ResetPassword() {
  const history = useHistory();
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Processing...");
  const [isPasswordShown, setIsPasswordShown] = useState("false");
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState("false");
  const { values, errors, handleChange, handleSubmit } = useForm(
    resetPassword,
    ValidateRules.ResetPassword
  );

  function resetPassword() {
    setIsLoading(true);
    const data = {
      token: token,
      password: values.password,
    };

    if (values.password === values.confirmPassword) {
      reset(data);
    }
  }

  async function reset(data) {
    await axiosMiddleware
      .put(`user/reset-password`, data)
      .then((res) => {
        if (!res.data.Status) {
          setIsLoading(false);
          toast.error(res.data.Message);
        } else {
          setIsLoading(false);
          toast.success(res.data.Message);
          history.push("/");
        }
      })
      .catch((e) => console.error(e));
  }

  return (
    <div className="login-page">
      <nav className="navbar navbar-light signin-navbar">
        <div className="container">
          <a href="/">
            <img src="../Image/logo.jpg" alt="Logo" width="100" height="100" />
          </a>
        </div>
      </nav>
      <div className="signin-body resetage-body">
        <div className="container">
          <div className="login-center login-wrapper">
            <div className="login-left-banner cols">
              <div className="img-wrap">
                <img src="../Image/signin-bg.png" alt="DayBulk Logo" />
              </div>
              <div className="title">
                <h1>
                  {" "}
                  <span>Welcome</span> to the DayBulk!
                </h1>
              </div>
            </div>
            <div className="login-content forget-password cols">
              <h2 className="title">Reset Password</h2>
              <form className="forgot-form" onSubmit={handleSubmit}>
                <div className="form-element-group login-group">
                  <div className="form-group login-group">
                    <label htmlFor="Password">Enter Password</label>
                    <input
                      type={isPasswordShown ? "password" : "text"}
                      placeholder="Enter your password"
                      onChange={(e) => handleChange(e, "text", "", "")}
                      value={values.password || ""}
                      name="password"
                      className={`form-control signin-password input-field shadow-none ${
                        errors.password && "is-danger"
                      }`}
                    />
                    <i
                      className={
                        isPasswordShown ? "far fa-eye" : "far fa-eye-slash"
                      }
                      onClick={() =>
                        setIsPasswordShown((preState) => !preState)
                      }
                    />
                    {errors.password && (
                      <p className="help is-danger">{errors.password}</p>
                    )}
                  </div>
                </div>
                <div className="form-element-group login-group">
                  <label htmlFor="confirmPassword">
                    Enter Confirm Password
                  </label>
                  <input
                    type={isConfirmPasswordShown ? "password" : "text"}
                    className={`form-control signin-password input-field shadow-none ${
                      errors.confirmPassword && "is-danger"
                    }`}
                    placeholder="Enter your confirm password"
                    onChange={(e) => handleChange(e, "text", "", "")}
                    value={values.confirmPassword || ""}
                    name="confirmPassword"
                  />
                  <i
                    className={
                      isConfirmPasswordShown ? "far fa-eye" : "far fa-eye-slash"
                    }
                    onClick={() =>
                      setIsConfirmPasswordShown((preState) => !preState)
                    }
                  />
                  {errors.confirmPassword && (
                    <p className="help is-danger">{errors.confirmPassword}</p>
                  )}
                </div>
                <div className="form-element-group">
                  <div className="buttonSection d-flex">
                    <button type="submit" className="btn signin-btn" disabled={isLoading ? true : false}>
                      {isLoading ? loadingMsg : "Reset Password"}
                    </button>
                    <Link to={'/'} className="btn" type="button">
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <footer className="login-footer">
          <p>&copy; {new Date().getFullYear()} DayBulk.com All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default ResetPassword;

import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import axiosMiddleware from "../../../helper/axios";
import "../pop-up-model-pages/addCategory.css"
import _concat from "lodash/concat";
import _get from "lodash/get";
import _forEach from 'lodash/forEach';
import useForm from "../../../helper/useForm";
import ValidateRules from "../../../helper/ValidateRules";

const sendingToList = [
    {label:"Customer(s)", value:"1"},
    {label:"Seller(s)", value:"2"},
    {label:"Agent(s)", value:"3"},
];

const resolveForReactSelect = (obj) => {
    let newSelectedArray = [];
    if (obj && obj.length > 1) {
        for (let key of obj) {
            if(key.name){
                newSelectedArray.push({ value: key.id, label: key.name, channelID: key.referralCode });
            }else{
                newSelectedArray.push({ value: key.id, label: key.firstName+" "+key.lastName, channelID: key.referralCode });
            }
        }
        return newSelectedArray;
    } else if (obj && obj.length === 1) {
        if(obj[0].name){
            newSelectedArray.push({ value: obj[0].id, label: obj[0].name, channelID: obj[0].referralCode });
        }else{
            newSelectedArray.push({ value: obj[0].id, label: obj[0].firstName+" "+obj[0].lastName, channelID: obj[0].referralCode });
        }
        return newSelectedArray;
    } else {
        return newSelectedArray;
    }
}

const EmailInvitation = (props) => {
    const userData = JSON.parse(sessionStorage.getItem("User-Info"));
    const [isLoading, setisLoading] = useState(false);
    const [isEdited, setisEdited] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState("Loading...");
    const [channelIDList, setChannelIDList] = useState([]);
    const [channel, setChannel] = useState(false)
    const [sendingTo, setSendingTo] = useState("3");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        initialValues,
      } = useForm(handleEmailForm, ValidateRules.Emails);

    useEffect(()=>{
        initialValues({
            emails: "",
            sendingTo: "",
            channelID: "",
        })
    },[]);

    const getChannel = async (to) => {
        setisLoading(true);
        let _url = `agent?sortBy=&orderTo=&userRoleId=${userData.userRoleId}`;
        if(to === "1"){
            _url = `user?sortBy=firstName&orderTo=DESC&userRoleId=${userData.userRoleId}`;
        }else if(to === "2"){
            _url = `seller?sortBy=&orderTo=&userRoleId=${userData.userRoleId}`;
        }else{
            _url = `agent?sortBy=&orderTo=&userRoleId=${userData.userRoleId}`;
        }
        await axiosMiddleware.get(_url).then((res) => {
        const totalCount = (res.data.Data || []).length;
        console.log(res.data.Data);
        if (!res.data.Status) {
            setChannelIDList([]);
            setChannel(false);
        }
        if (res.data.Status && totalCount === 15) {
            setChannelIDList(resolveForReactSelect(res.data.Data));
            setChannel(true);
        }
        if (res.data.Status && totalCount > 0 && totalCount < 15) {
            setChannelIDList(resolveForReactSelect(res.data.Data));
            setChannel(true);
        }
        setisLoading(false);
      }).catch((e) => {
        console.log(e);
        setisLoading(false);
      });
    }

    useEffect(() => {
        if(sendingTo === "1"){
            getChannel(sendingTo);
        }else if(sendingTo === "2"){
            getChannel(sendingTo);
        }else{
            getChannel(sendingTo);
        }
    }, [sendingTo]);

    function handleEmailForm(){
        setisLoading(true);
        setLoadingMsg("Sending Invite(s)");
        console.log(values);
        setisLoading(false);
    }
    
    return (
        <div className="d_card_container">
            <div className="d_card">
                <div className="d_card_header">
                    <div>Email Invitations</div>
                </div>
                <div className="d_card_body">
                    <form className="settingsForm fomik-form" onSubmit={handleSubmit} noValidate>
                        <div className="form-body">
                            <div className="form-section">
                                <h3 className="form-heading">Optional: Select Channel</h3>
                                <div className="input">
                                    <span className="input-span">ChannelID of</span>
                                    <ReactSelect 
                                    name="sendingTo" 
                                    options={sendingToList}
                                    onChange={(e)=>{setSendingTo(e.value)}}
                                    />
                                </div>
                                <div className="input">
                                    <span className="input-span">Select Channel ID</span>
                                    <ReactSelect 
                                    name="channelID" 
                                    options={channelIDList}
                                    onChange={(e)=>{console.log(e.channelID)}}
                                    />
                                </div>
                            </div>
                            <div className="form-section">
                                <h3 className="form-heading">Enter Email(s)</h3>
                                <div className="input">
                                    <span className="input-span">*Email(s)</span>
                                    <input
                                    required 
                                    type={"email"} 
                                    name="emails" 
                                    className={`form-control shadow-none input-class`} 
                                    multiple
                                    value={values.emails} 
                                    placeholder="Enter Email(s) ..."/>
                                    <span className="field-notes"><b>Note: </b>Enter comma (,) sepeareted values for multiple emails.</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-footer">
                            <button type="submit" className={`btn primary ${isLoading || !isEdited ? "disabledButton" : "small"}`} disabled={isLoading || !isEdited ? true : false}>{isLoading ? loadingMsg : "Send Invite(s)"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EmailInvitation;

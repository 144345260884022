/* eslint-disable react-hooks/exhaustive-deps */
import { Chart } from "react-google-charts";
import { makeStyles } from "@mui/styles";
import axiosMiddleware from "../../../helper/axios";
import { useEffect, useState } from "react";
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import EmptyChart from "../EmptyState/EmptyChart";
// import DatePicker from "react-datepicker";
import { SelectDateDropDown } from "./SelectDateDropDown";
import { getInitDate } from "../../../helper/helper";
import SkeletonLoading from "../../../components/SkeletonLoading";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "368px",
    },
}));

const ProductSalesChart = (props) => {
    const classes = useStyles();
    // const [productStartDate, setProductStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [data, setData] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loading, setLoading] = useState(true);
    const loadProductData = async () => {
        let url = ``;
        if (startDate && endDate) {
            url = `request/top-selling-products?limit=10&offset=0&startDate=${startDate}&endDate=${endDate}`;
        } else {
            let { sDate, eDate } = getInitDate(new Date())
            url = `request/top-selling-products?limit=10&offset=0&startDate=${sDate}&endDate=${eDate}`;
        }
        await axiosMiddleware.get(url).then((res) => {
            let tempData = [];
            if (res.data.Status) {
                _forEach(res.data.Data, (value, index) => {
                    if (parseInt(value.totalSellingQty) !== 0) {
                        tempData.push([value.categoryName, parseInt(value.totalSellingQty)]);
                    }
                });
            } else {
                tempData = [];
            }
            setData(tempData);
            setLoading(false);
        }).catch((e) => {
            console.log(e);
            setLoading(false);
        });
    }
    
    // const chartEvents = [
    //     {
    //         eventName: "select",
    //         callback({ chartWrapper }) {
    //             console.log("Selected ", chartWrapper.getChart().getSelection());
    //         }
    //     }
    // ];

    useEffect(() => {
        setTimeout(() => {
            loadProductData();
        }, 300);
    }, [startDate, endDate]);

    const OnDateRangeChange = (dateRange) => {
        setStartDate(dateRange.startDate);
        setEndDate(dateRange.endDate);
    }

    return (
        <div className="d_card_container">
            <div className="d_card">
                <div className="d_card_header">
                    <div>Data - Product by most sales</div>
                    <SelectDateDropDown OnDateRangeChange={OnDateRangeChange} onPosition="left" />
                    {/* <DatePicker
                        placeholderText="Select Month"
                        selected={new Date(productStartDate)}
                        onChange={(date) => setProductStartDate(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        withPortal
                        maxDate={new Date()}
                    /> */}
                </div>
                <div className="d_card_body">
                    {!_isEmpty(data) ? <Chart
                        className={classes.root}
                        chartType="Bar"
                        loader={<SkeletonLoading
                            height="370px"
                        />}
                        data={[["Products", "Quantity"], ...data]}
                        options={
                            {
                                // title: ' of Largest U.S. Cities',
                                chartArea: { width: "auto" },
                                hAxis: {
                                    title: "Total Quantity ",
                                    minValue: 0,
                                },
                                vAxis: {
                                    title: "Product",
                                },
                                animation: {
                                    duration: 1000,
                                    easing: 'out',
                                },
                            }
                        
                        }
                        rootProps={{ "data-testid": "1" }}
                    // chartEvents={chartEvents}
                    /> : loading ? <SkeletonLoading
                    height="370px"
                /> :<EmptyChart></EmptyChart>}
                </div>
            </div>
        </div>
    );
}

export default ProductSalesChart;

const emptyEmail = "Email address is required";
const invalidEmail = "Email address is invalid";
const emptyPassword = "Password is required";
const invalidPassword = "Password must be 6 or more characters";

let emailValidate = {
  EmptyEmail: (values) => {
    let errors = {};
    if (!values) {
      return errors;
    }
  },
  InvalidEmail: (values) => {
    let errors = {};
    if (!/\S+@\S+\.\S+/.test(values)) {
      return errors;
    }
  },
};

let validate = {
  Login: (values) => {
    let errors = {};
    if (emailValidate.EmptyEmail(values.email)) {
      errors.email = emptyEmail;
    } else if (emailValidate.InvalidEmail(values.email)) {
      errors.email = invalidEmail;
    }
    if (!values.password) {
      errors.password = emptyPassword;
    } else if (values.password.length < 6) {
      errors.password = invalidPassword;
    }
    return errors;
  },
  ForgotPassword: (values) => {
    let errors = {};
    if (emailValidate.EmptyEmail(values.email)) {
      errors.email = emptyEmail;
    } else if (emailValidate.InvalidEmail(values.email)) {
      errors.email = invalidEmail;
    }
    return errors;
  },
  ResetPassword: (values) => {
    let errors = {};
    if (!values.password) {
      errors.password = emptyPassword;
    } else if (values.password.length < 6) {
      errors.password = invalidPassword;
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = `Confirm ${emptyPassword}`;
    } else if (values.confirmPassword.length < 6) {
      errors.confirmPassword = `Confirm ${invalidPassword}`;
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Confirm password must be same as password";
    }
    return errors;
  },
  Category: (values) => {
    let errors = {};
    if (!values.catName) {
      errors.catName = "Category name is required";
    }
    return errors;
  },
  SubCategory: (values) => {
    let errors = {};
    if (!values.catName) {
      errors.catName = "Category name is required";
    }
    if (
      !values.catParentCat ||
      values.catParentCat === null ||
      values.catParentCat === undefined
    ) {
      errors.catParentCat = "Please select Parent Category";
    }
    return errors;
  },
  Agent: (values) => {
    let errors = {};
    if (!values.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }
    if (emailValidate.EmptyEmail(values.email)) {
      errors.email = emptyEmail;
    } else if (emailValidate.InvalidEmail(values.email)) {
      errors.email = invalidEmail;
    }
    if (!values.zipCode) {
      errors.zipCode = "ZipCode is required";
    } else if (values.zipCode.length > 6 || values.zipCode.length < 6) {
      errors.zipCode = "Invalid Zipcode";
    }

    if (!values.contactNumber) {
      errors.contactNumber = "Contact Number is required";
    } else if (isNaN(values.contactNumber)) {
      errors.contactNumber = "Invalid entry, enter only numbers";
    } else if (
      values.contactNumber.length < 10 ||
      values.contactNumber.length > 12
    ) {
      errors.contactNumber = "Invalid entry, can only be 10 or 12 digits";
    }

    return errors;
  },
  Seller: (values) => {
    let errors = {};
    return errors;
  },
  Brand: (values) => {
    let errors = {};
    if (!values.brandName) {
      errors.brandName = "Brand name is required";
    }
    if (!values.category) {
      errors.category = "Category is required";
    }
    return errors;
  },
  RequestPage: (values) => {
    let errors = {};
    if (!values.customerId) {
      errors.customerId = "Please select Customer";
    }
    if (!values.mainCategoryId) {
      errors.mainCategoryId = "Please select Main Category";
    }
    if (!values.categoryId || values.categoryId === null) {
      errors.categoryId = "Please select Sub Category";
    }
    if (!values.brandId || values.brandId === null) {
      errors.brandId = "Please select a Brand";
    }
    if (!values.quantity) {
      errors.quantity = "Please enter Qunatity";
    } else if (parseInt(values.quantity) === 0) {
      errors.quantity = "Qunatity must be greater than 0";
    }
    if (!values.modelNumber) {
      errors.modelNumber = "Please enter a Model Number";
    }
    if (!values.lastCheckedPrice) {
      errors.lastCheckedPrice = "Please enter Last Checked Price";
    } else if (parseFloat(values.lastCheckedPrice) === 0) {
      errors.lastCheckedPrice = "Price can not be 0";
    }
    if (!values.expectedDeliveryDate) {
      errors.expectedDeliveryDate = "Please select an Expected Delivery Date";
    }
    return errors;
  },
  Banner: (values) => {
    let errors = {};
    if (!values.bannerName) {
      errors.bannerName = "Please Enter Banner Name";
    }
    if(!values.bannerImageURL){
      errors.bannerImageURL = "Please Select Image";
    }
    return errors;
  },
  Emails: (values) => {
    let errors = {};

    return errors;
  },
  Settings: (values) => {
    let errors = {};
    
    if (isNaN(values.whatsAppMobileNumber)) {
      errors.whatsAppMobileNumber = "Invalid entry, enter only numbers";
    } else if (
      values.whatsAppMobileNumber.length < 10 || values.whatsAppMobileNumber.length > 12) {
      errors.whatsAppMobileNumber = "Invalid entry, can only be 10 or 12 digits";
    }

    if (isNaN(values.callContactNumber)) {
      errors.callContactNumber = "Invalid entry, enter only numbers";
    } else if (
      values.callContactNumber.length < 10 || values.callContactNumber.length > 12) {
      errors.callContactNumber = "Invalid entry, can only be 10 or 12 digits";
    }
    
    return errors;
  }
};
export default validate;

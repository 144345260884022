/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import isEmpty from "lodash/isEmpty";
import _concat from "lodash/concat";
import _get from "lodash/get";
// import _forEach from 'lodash/forEach';
import axiosMiddleware from "../../../helper/axios";
// import swal from "sweetalert";
import { toast } from "react-toastify";
import EmptyList from "../EmptyState/EmptyList";
import AddCustomer from "../pop-up-model-pages/AddCustomer";
import { useDebounce } from "../../../helper/UseDebounce";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import * as FaIcons from "react-icons/fa";
import '../Request/request.css';
import { iDToValueResolveAsyncSelectAllCol } from "../../../helper/helper";
import moment from "moment";
import SkeletonLoading from "../../../components/SkeletonLoading";

const { Option } = components;

const CustOption = (props) => (
    <Option {...props}>
        {`${props.data.label}`}
        <br />
        {`${props.data.email}`}
        <br />
        {`${props.data.contactNumber}`}
    </Option>
);

function Customer(props) {
    const [hasMore, setHasMore] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [limit] = useState(15);
    const [offSet, setOffSet] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [user, setUser] = useState([]);
    const [isUserList, setIsUserList] = useState(false)
    const [searchFilter, setSearchFilter] = useState("");
    const [selectedUser, setSelectedUser] = useState([]);
    const [userIndex, setUserIndex] = useState(-1);
    const userData = JSON.parse(sessionStorage.getItem("User-Info"));
    const [resCount, setResCount] = useState(1);
    const [roleId, setRoleId] = useState();
    const debouncedSearchTerm = useDebounce(searchFilter, 1000);
    const [endMsg, setEndMsg] = useState(true);
    const [agentList, setAgentList] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(0);
    const [requestData, setRequestData] = useState([]);
    const [userDetailsShow, setUserDetailsShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if (sessionStorage.getItem("User-Info") === null || !localStorage.getItem("ApiKey")) {
            toast.error("Session Expired! Please login again...");
            window.location.replace("/")
        } else {
            setRoleId(userData.userRoleId);
        }
    }, [userData]);

    const makeUrl = () => { 
        let url = `user?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=firstName&orderTo=DESC&userRoleId=${roleId}`;
        if (selectedAgent !== 0) {
            url = `user?searchText=${searchFilter}&limit=${limit}&offset=${offSet}&sortBy=firstName&orderTo=DESC&agentId=${selectedAgent}&userRoleId=${roleId}`
            return url;
        } else { 
            return url;
        }
    }

    const loadUsers = async () => {
        await axiosMiddleware.get(makeUrl()).then((res) => {
            const totalCount = (res.data.Data || []).length;
            if (!res.data.Status) {
                setHasMore(false);
                setUser([]);
                setIsUserList(false);
                setResCount(0);
            }
            if (res.data.Status && totalCount === 15) {
                setOffSet(offSet + limit);
                setUser(res.data.Data);
                setIsUserList(true);
                setHasMore(true);
                setResCount(totalCount);
            }
            if (res.data.Status && totalCount > 0 && totalCount < 15) {
                setOffSet(offSet + limit);
                setUser(res.data.Data);
                setIsUserList(true);
                setHasMore(false);
                setResCount(totalCount);
                setEndMsg(false);
            }
            if (res.data.Status && totalCount === 0) {
                setOffSet(0);
                setUser([]);
                setIsUserList(false);
                setHasMore(false);
                setResCount(totalCount);
                setEndMsg(false);
            }
        }).catch((e) => {
            console.log(e)
        });
    }

    const loadAgent = async (searchT = '') => {
        let url = `agent?userRoleId=${roleId}`;
        await axiosMiddleware.get(url).then((res) => { 
            let newOption = iDToValueResolveAsyncSelectAllCol(res.data.Data, ["firstName", "lastName"]);
            // let newOption2 = iDToValueResolveAsyncSelect(res.data.Data, ["firstName","lastName","email","contactNumber"]);
            const options = [
                {
                    options: [
                        ...newOption
                    ]
                }
            ];
            setAgentList(options);
        }).catch((e) => { 
            console.log(e);
        });
    }

    const loadRequestList = async (id) => {
    setIsLoading(true);
    setRequestData([]);
    await axiosMiddleware.get(`request?customerIds=${id}`).then((res) => {
        const totalCount = (res.data.Data || []).length;
        if (!res.data.Status) {
        setIsLoading(false);
        setRequestData([]);
        }else{
        setIsLoading(false);
        setRequestData(res.data.Data);
        }
    }).catch((e) => {
        toast.error("Error...! " + e);
        // console.log(err);
    });
    }
    
    useEffect(() => {
        loadUsers();
    }, [debouncedSearchTerm, selectedAgent]);
    
    useEffect(() => { 
        loadAgent();
    },[]);

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('overflow-hidden')
            // props.scrollBlock(true);
        } else {
            document.body.classList.remove('overflow-hidden')
            // props.scrollBlock(false);
        }
    }, [showModal]);

    function handleShow(cellIndex) {
    setSelectedUser(user[cellIndex]);
    if(cellIndex == userIndex){
        setUserDetailsShow(!userDetailsShow)
    }
    else{
        loadRequestList(user[cellIndex].id)
        setUserIndex(cellIndex);
        setUserDetailsShow(true)
    }
    }

    function handleSearchChange(e) {
        setOffSet(0);
        setSearchFilter(e.target.value);
    }

    const handleFilterByAgent = (e) => {
    setOffSet(0)
    if(e){
        setSelectedAgent(e.value);
    }else{
        setSelectedAgent(0);
    }
    }

    function handleClick(cellIndex) {
        setSelectedUser(user[cellIndex]);
        setUserIndex(cellIndex);
        setShowModal(true);
    }

    const handleOnClose = () => {
        setShowModal(false);
        setSelectedUser([]);
    };

    const handleRowUpdate = (data, index) => {
        user[index] = data;
    };

    const handleRowInsert = (data) => {
        user.unshift(data);
    };

    async function fetchMoreData() {
        await axiosMiddleware.get(makeUrl())
            .then((res) => {
                let dummyArray = _get(res, "data.Data", []);
                let fetchArray = _concat(user, dummyArray);
                setUser(fetchArray);
                setOffSet(offSet + limit);
                if (dummyArray.length === 0) {
                    setHasMore(false);
                }
            }).catch((e) => {
                console.log(e);
            });
    }
    const searchAgent = (searchT = '')=>{
        return agentList[0].options.filter((entry) =>
        (typeof entry.label === "string" && entry.label.toLowerCase().includes(searchT.toLowerCase())));
    }
    const loadAgentInSelect = (inputValue, callback) => {
        inputValue != '' && callback(searchAgent(inputValue));
    };
    const loadingArr = [];

    for (let index = 0; index < 6; index++) {
      loadingArr.push(<tr>
        <td colSpan="2"><SkeletonLoading/></td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        <td>
          <span><SkeletonLoading/></span>
        </td>
        </tr>)
    }
    return (
        <div className="container-user container-request">
            <div className="pannel-header">
                <div className="left-sec pannel-cols">
                    <h2 className="panel-heading">User</h2>
                </div>
                <div className="right-sec pannel-cols">
                    <button
                        className="btn primary small"
                        type="submit"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        <img src="./Image/plus_icon.png" alt="Plus" />
                        <span>Add Customer</span>
                    </button>
                </div>
            </div>
            <div className="search-field">
                <div className="filter-drop" style={{minWidth:"300px"}}>
                    <AsyncSelect
                        name="_filter_Agent"
                        options={agentList}
                        components={{ Option: CustOption }}
                        loadOptions={loadAgentInSelect}
                        defaultOptions={agentList}
                        isClearable={true}
                        // defaultValue={
                        //     values.mainCategoryId &&
                        //     allParentCat[
                        //     allParentCat.findIndex(
                        //         (item) => item.value === values.mainCategoryId
                        //     )
                        //     ]
                        // }
                        onChange={(e) => {handleFilterByAgent(e)}}
                        placeholder="Filter by Agent...."
                    />
                </div>
                <div className="form-element-group search-frm">
                    <input
                        type="text"
                        className={`small data-hj-allow ${resCount === 0 && searchFilter === "" && "disabled"}`}
                        placeholder="Search User Name...."
                        onChange={handleSearchChange}
                        disabled={resCount === 0 && searchFilter === "" ? true : false}
                    />
                </div>
            </div>
            <div className="card-table table-wrapper">
                {resCount !== 0 ? <InfiniteScroll
                    dataLength={user && user.length}
                    next={fetchMoreData}
                    loader={
                    <table className="table-main">
                        
                        <tbody>
                            {loadingArr}
                        </tbody>
                    </table>
                    }
                    endMessage={endMsg && 
                        <p style={{ textAlign: 'center' }}>
                            <b>No more records found...</b>
                        </p>
                    }
                    hasMore={hasMore}
                    scrollableTarget="scrollablediv"
                    className="infiniteRequestTable"
                >
                    <table className="table-main">
                        <thead>
                            <tr>
                                <th>
                                    Picture
                                </th>
                                <th>
                                    User Name
                                </th>
                                <th>
                                    Contact Number
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Zip Code
                                </th>
                                <th>
                                    Channel ID
                                </th>
                                <th>
                                    Agent
                                </th>
                                <th>
                                    Request
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* eslint-disable-next-line array-callback-return */}
                            {isUserList && user.map((userObj, index) => (
                                <>
                                <tr key={index}>
                                    <td>
                                        <div className="edit">
                                            <img
                                                src={userObj.profilePicture ? userObj.profilePicture: "Image/profile.png"}
                                                width="40"
                                                height="40"
                                                alt="Profile"
                                            />
                                        </div>
                                        
                                    </td>
                                    <td>
                                        <span>{userObj.firstName + " " + userObj.lastName}</span>
                                    </td>
                                    <td>
                                        <span>{userObj.contactNumber}</span>
                                    </td>
                                    <td>
                                        <span>{userObj.email}</span>
                                    </td>
                                    <td>
                                        <span>{userObj.zipCode}</span>
                                    </td>
                                    <td>
                                        <span>{userObj.referralCode}</span>
                                    </td>
                                    <td>
                                        {userObj.agent && `${userObj.agent.firstName} ${userObj.agent.lastName}`}
                                        <br />
                                        {userObj.agent && `${userObj.agent.email}`}
                                        <br />
                                        {userObj.agent && `${userObj.agent.contactNumber}`}
                                    </td>
                                    <td>
                                        <span>Delivered - {userObj.totalDeliveredRequest} <br /> Open - {userObj.totalOpenRequest}</span>
                                    </td>
                                    <td>
                                        <span className={parseInt(userObj.isActive) ? "active-neutral-span highlighted" : "decline-neutral-span highlighted"}>
                                            {parseInt(userObj.isActive) ? "Active" : "Inactive"}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="edit">
                                            <img
                                                src="./Image/pencil.png"
                                                alt="Pencil"
                                                onClick={() => handleClick(index)}
                                                style={{ cursor: "pointer",  }}
                                            />
                                            {(userIndex == index && userDetailsShow) ? 
                                            <FaIcons.FaChevronUp
                                            size={20}
                                            style={{
                                            color: "#6b6b6f",
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            }}
                                            onClick={() => handleShow(index)}
                                        />
                                        :<FaIcons.FaChevronDown
                                            size={20}
                                            style={{
                                            color: "#6b6b6f",
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            }}
                                            onClick={() => handleShow(index)}
                                        />}
                                        </div>
                                    </td>
                                </tr>
                            <tr className={`drop-table ${(userIndex == index && userDetailsShow) ? 'active':""}`}>
                                 <th></th>
                                 <th>PRODUCT DETAILS</th>
                                 <th></th>
                                 <th></th>
                                 <th>CREATED AT</th>
                                 <th>EXPECTED DELIVERY</th>
                                 <th>LAST CHECKED PRICE</th>
                                 <th>QUANTITY</th>
                                 <th>STATUS</th>
                                 <th></th>
                            </tr>
                         {isLoading &&
                          <tr className={`drop-table ${(userIndex == index && userDetailsShow) ? 'active':""}`}>
                               <td className="text-center" colSpan={10}>Loading Data......</td>
                         </tr>}    
                         {requestData.length > 0 ? requestData.map((requestObj, subindex) => (
                         <tr className={`drop-table ${(userIndex == index && userDetailsShow) ? 'active':""}`} key={index}>
                           <td colSpan="1" className="span-col"></td>
                           <td colSpan="3" className="span-col">
                           <span>
                               <div className="productDetailSpan">
                                 <span className="categorySpan">
                                   {requestObj.mainCategoryName+", "+requestObj.categoryName+", "+requestObj.brandName}
                                 </span>
                               </div>
                             </span>
                           </td>
                           <td>{moment(requestObj.createdAt, 'YYYY-MM-DD').format('DD-MMM-YYYY')}</td>
                           <td>{moment(requestObj.expectedDeliveryDate, 'YYYY-MM-DD').format('DD-MMM-YYYY')}</td>
                           <td>{requestObj.lastCheckedPrice}</td>
                           <td>{requestObj.quantity}</td>
                           <td>
                             <span className={
                               requestObj.requestStatus === "Open" ? "open-neutral-span highlighted" : requestObj.requestStatus === "Closed" ? "decline-neutral-span highlighted" : "delivered-neutral-span highlighted"
                             }
                             >
                               {requestObj.requestStatus}
                             </span>
                           </td>
                           <td colSpan="2" className="span-col"></td>
                         </tr>
                       )): !isLoading ?
                       <tr className={`drop-table ${(userIndex == index && userDetailsShow) ? 'active':""}`}>
                               <td className="text-center" colSpan={10}>No Data Found.</td>
                       </tr>:""
                       }
                         </>   ))}
                        </tbody>
                    </table>
                </InfiniteScroll> : <EmptyList itemFor="Users" ifBtn={(searchFilter !== "" && selectedAgent !== 0) || (searchFilter === "" && selectedAgent !== 0) || (searchFilter !== "" && selectedAgent === 0) ? false : true} onAddNew={() => setShowModal(true)} btnText="Add User"></EmptyList>}
            </div>

            {showModal && (
                <>
                    {" "}
                    <AddCustomer
                        userIndex={userIndex}
                        onRowUpdate={handleRowUpdate}
                        onRowInsert={handleRowInsert}
                        onAddNew={isEmpty(selectedUser) ? true : false}
                        selectedUser={selectedUser}
                        onClose={handleOnClose}
                    />
                </>
            )}
        </div>
    );
}

export default Customer;

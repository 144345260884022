import React, { useState, useEffect } from "react";
import Switch from "react-switch";
// import AsyncSelect from "react-select/async";
import Select from "react-select";
import _forEach from "lodash/forEach";
import axiosMiddleware from "../../../helper/axios";
import useForm from "../../../helper/useForm";
import ValidateRules from "../../../helper/ValidateRules";
import { toast } from "react-toastify";
import "./addBrand.css";
import { iDToValueResolveAsyncSelect } from "../../../helper/helper";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    backgroundColor: state.isHovered ? 'transparent' : "transparent",
    color: '#196604',
    paddingLeft: 20,
    fontSize: 14,
    cursor:"pointer",
  }),

  group: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    margin:0,
  }),

  groupHeading: (provided, state) => ({
    ...provided,
    color: '#f59e1b',
    fontSize: "inherit",
    cursor: "pointer",
    backgroundColor: state.isHovered ? 'DEEBFF' : "#fbfbfb",
    paddingBottom: 5,
    paddingTop: 5,
    margin:0,
  }),
}

const createGroup = (groupName, options, setValue) => {
  return {
    label: (() => {
      return (
        <div onClick={() => setValue(value => value.concat(options.filter(grpOpt => !value.includes(grpOpt))))}>
          {groupName}
        </div>
      );
    })(),
    options: options
  };
};

function AddBrand(props) {
  const [allParentCat, setAllParentCat] = useState();
  const [isAllParentCat, setIsAllParentCat] = useState(false);
  const [isImageChange, setIsImageChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Loading...");
  const [category, setCategory] = useState([]);
  const [options, setOptions] = useState([]);
  const userData = JSON.parse(sessionStorage.getItem("User-Info"));
  const { values, errors, handleChange, handleSubmit, initialValues } = useForm(
    handleBrandForm,
    ValidateRules.Brand
  );
  
  const loadParentCat = async (searchText) => {
    await axiosMiddleware
      .get(
        `category?searchText=${searchText}&limit=&offset=&sortBy=&orderTo=&parentId=`
      )
      .then((res) => {
        let asyncSortedValues = iDToValueResolveAsyncSelect(res.data.Data);
        setAllParentCat(asyncSortedValues);
        setIsAllParentCat(true);
      })
      .catch((e) => {
        // console.log(e);
        toast.error("Error...! Fialed to load Main Catgeory list.");
      });
  };

  const getCategoryTreeData = async () => {
    let optionsArray = [];
    await axiosMiddleware.get(`category/tree-list?userRoleId=${userData.userRoleId}`).then((res) => {
      let dummyArray = _get(res, "data.Data", []);
      _forEach(dummyArray, (val) => {
        let subCat = [];
        if(_isEmpty(val.subCategory)){
          subCat.push({ label: val.name, value: val.id });
        }else{
          _forEach(val.subCategory, (valSub) => {
            subCat.push({ label: valSub.name, value: valSub.id });
          });
        }
        const temp = createGroup(val.name, subCat, setCategory)
        optionsArray.push(temp);
      });
      setOptions(optionsArray);
    }).catch((e) => {
      // console.log(e);
      toast.error("Error...! Failed to load Category Tree, please reload window.");
    });
    // options = optionsArray;
    // console.log(optionsArray);
  };

  useEffect(() => {
    handleChange("", "reactSelectMulti", "category", category);
    setIsEdited(true);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[category]);
  
  useEffect(() => {
    let asyncSortedValues = iDToValueResolveAsyncSelect(props.cellProduct.category);
    setCategory(asyncSortedValues);
    initialValues({
      brandId: props.cellProduct.id || "",
      brandName: props.cellProduct.name || "",
      brandImageURL: props.cellProduct.image || "",
      brandDescription: props.cellProduct.description || "",
      brandStatus: parseInt(props.cellProduct.isActive) ? true : false,
      isApproved : parseInt(props.cellProduct.isApproved) ? true : false,
      category: asyncSortedValues,
    });
    loadParentCat("");
    setIsEdited(false);
    getCategoryTreeData("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cellProduct]);

  function loadImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      const output = document.getElementById("output");
      output.src = reader.result;
      handleChange("", "file", "brandImageURL", reader.result);
      setIsImageChange(true);
      setIsEdited(true);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  function handleClose() {
    if(props.setTrigger){
      props.setTrigger(false);
      }
      if(props.setCellProduct){
        props.setCellProduct({});
      }
  }

  function handleBrandForm() {
    setIsLoading(true);
    let selectedCategories = "";
    _forEach(values.category, function (value, key) {
      selectedCategories += value.value + ",";
    });
    let preparedCategoryData = selectedCategories.replace(/,\s*$/, "");
    const submit = {
      name: values.brandName,
      image: isImageChange && values.brandImageURL,
      status: `${values.brandStatus ? 1 : 0}`,
      isApproved: `${values.isApproved ? 1 : 0}`,
      description: `${values.brandDescription}`,
      categoryIds: `${preparedCategoryData}`,
    };
    if (values.brandId !== "") {
      editBrand(values.brandId, submit);
    } else {
      addBrand(submit);
    }
  }

  async function addBrand(data) {
    await axiosMiddleware.post("brand", data).then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.setTrigger(false);
          setIsLoading(false);
          if(props.isOntheFly){
            props.newBrandObject(res.data.Data);
          }else{
            window.location.reload(false);
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Error in network, Unable to add new Brand.");
        // console.log(e);
      });
  }

  async function editBrand(id, data) {
    await axiosMiddleware
      .put(`brand/${id}`, data)
      .then((res) => {
        if (!res.data.Status) {
          toast.error(res.data.Message);
          setIsLoading(false);
        } else {
          toast.success(res.data.Message);
          props.onRowUpdate(res.data.Data, props.brandIndex);
          props.setTrigger(false);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Error...! Something went wrong unable to Save edited information. Please try again.");
        // console.log(e);
      });
  }

  const filterSelect = (inputValue) => {
    let filterObj = allParentCat;
    return filterObj.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // eslint-disable-next-line no-unused-vars
  const loadCategoryInToSelect = (inputValue, callback) => {
    loadParentCat(inputValue);
    callback(filterSelect(inputValue));
  };

  // eslint-disable-next-line no-unused-vars
  const filterOption = ({ label, value }, string) => {
    // default search
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = options.filter(group => group.label.props.children.toLocaleLowerCase().includes(string));

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find(opt => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  return props.trigger ? (
    <div className="global-popup">
      <div className="addProductPopup">
        {isAllParentCat && (
          <div className="addPopupinner fade-in-up">
            <div className="addProduct-heading border-bottom">
              <h2 className="popup-heading" style={{ marginTop: "7px" }}>
                Add Brand
              </h2>
              <span>
                <a onClick={handleClose} href="#close">
                  <img src="./Image/closepopup-icon.svg" alt="Close Icon" />
                </a>
              </span>
            </div>
            <div className="popupInnerBody">
              <form className="fomik-form" onSubmit={handleSubmit} noValidate>
                <div className="textfield-div">
                  <label className="activeUser-label" htmlFor="BrandName">
                    <span>Name</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={`form-control shadow-none input-class ${
                      errors.brandName && "is-danger"
                    }`}
                    style={{ width: "400px" }}
                    value={values.brandName}
                    onChange={(e) => {
                      handleChange(e, "text", "", "");
                      setIsEdited(true);
                    }}
                    placeholder="Enter Brand name"
                    name="brandName"
                    required
                  />
                  {errors.brandName && (
                    <p className="help is-danger">{errors.brandName}</p>
                  )}
                </div>
                <div className="textfield-div">
                  <label htmlFor="Parent Category" className="activeUser-label">
                    <span>Select Categories</span>
                  </label>
                  <Select
                    // components={{GroupHeading: () => <div onClick={() => console.log('i am a group and i am clickable .. yay!')}>My Group Heading</div>}}
                    onChange={option => {
                      return setCategory(option);
                    }}
                    closeMenuOnSelect={false}
                    isMulti
                    // menuIsOpen={true}
                    options={options}
                    value={category}
                    isClearable={true}
                    styles={customStyles}
                    // filterOption={filterOption}
                  />
                  
                  {/* <AsyncSelect
                    isMulti
                    className={`${errors.category && "is-danger"}`}
                    name="category"
                    loadOptions={loadCategoryInToSelect}
                    defaultOptions={allParentCat}
                    isClearable={true}
                    // defaultValue={allParentCat[allParentCat.findIndex((item) => item.value === values.category)]}
                    defaultValue={iDToValueResolveAsyncSelect(
                      props.cellProduct.category
                    )}
                    onChange={(e) => {
                      handleChange("", "reactSelectMulti", "category", e);
                      setIsEdited(true);
                    }}
                    placeholder="Select Parent Category...."
                  /> */}
                  {errors.category && (
                    <p className="help is-danger">{errors.category}</p>
                  )}
                </div>
                <div className="textfield-div">
                  <label
                    className="activeUser-label"
                    htmlFor="BrandDescription"
                  >
                    <span>Description</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={`form-control shadow-none input-class`}
                    style={{ width: "400px" }}
                    value={values.brandDescription}
                    onChange={(e) => {
                      handleChange(e, "text", "", "");
                      setIsEdited(true);
                    }}
                    placeholder="Enter description"
                    name="brandDescription"
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <label className="activeUser-label">
                    <span>Status</span>
                    <Switch
                      name="brandStatus"
                      onChange={(e) => {
                        handleChange("", "switch", "brandStatus", e);
                        setIsEdited(true);
                      }}
                      checked={values.brandStatus}
                    />
                  </label>
                  <label className="activeUser-label">
                    <span>Is Approved</span>
                    <Switch
                      name="isApproved"
                      onChange={(e) => {
                        handleChange("", "switch", "isApproved", e);
                        setIsEdited(true);
                      }}
                      checked={values.isApproved}
                    />
                  </label>
                </div>
                <div
                  className="image-upload"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px",
                  }}
                >
                  <label className="image-upload-label" htmlFor="fileUpload">
                    Upload Image
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="fileUpload"
                    accept="image/*"
                    onChange={loadImage}
                    required
                  />
                  <img
                    id="output"
                    style={{
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      width: "120px",
                    }}
                    src={values.brandImageURL}
                    alt=""
                  />
                </div>
                <div
                  className=" border-bottom"
                  style={{ width: "500px", marginLeft: "-48px" }}
                ></div>
                <div className="addProductFooter d-flex">
                  <button
                    type="reset"
                    className="footer-cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <div className="footerBtn">
                    <button
                      type="submit"
                      className={`btn primary ${
                        isLoading || !isEdited ? "disabledButton" : "small"
                      }`}
                      disabled={isLoading || !isEdited ? true : false}
                    >
                      {isLoading ? loadingMsg : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    ""
  );
}

export default AddBrand;

import React from "react";
import QRCode from "react-qr-code";
import "./addRequest.css";

function Details(props) {
  const makeDate = (date) => {
    let newDate = '';
    let makedate = new Date(date) || new Date();
    newDate = makedate.getFullYear() + "-" + (parseInt(makedate.getMonth()) + 1) + "-" + makedate.getDate()
    return newDate;
  }
  console.log('props.detailsObject.isSellerAssigned',props.detailsObject.isSellerAssigned)
  return (
    <div className="global-popup">
      <div className="detail-popup">
        <div className="popup-content">
          <div className="border-bottom">
            <div className="popup-heading d-flex col-12">
              <h2 style={{ marginTop: "6px" }}>Request Details</h2>
              <span>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={props.onClose} >
                  <img src="./Image/closepopup-icon.svg" alt="Close Icon" />
                </a>
              </span>
            </div>
          </div>
          <div className="popupbody border-bottom">
            <div className="customer-detail-heading">
              <h3>Customer Detail</h3>
            </div>
            <div className="card card-inner">
              <div className="name-detail">
                <label>
                  <span className="name-span">Name :</span>
                  <span className="name-tag">
                    {props.detailsObject.customer.firstName + " " + props.detailsObject.customer.lastName}
                  </span>
                </label>
                <label>
                  <span className="contact-number-span">Contact Number :</span>
                  <span className="contact-tag">{props.detailsObject.customer.contactNumber}</span>
                </label>
                <label>
                  <span className="email-span">Email :</span>
                  <span className="email-tag">{props.detailsObject.customer.email}</span>
                </label>
              </div>
            </div>
            {props.detailsObject.isSellerAssigned && 
            <>
            <div className="customer-detail-heading" style={{ marginTop: "5px" }}>
              <h3>Seller Detail</h3>
            </div>
            <div className="card card-inner">
            <div className="name-detail">
              <label>
                <span className="name-span">Name :</span>
                <span className="name-tag">
                  {props.detailsObject.seller.firstName +" "+ props.detailsObject.seller.lastName}
                </span>
              </label>
              <label>
                <span className="contact-number-span">Contact Number :</span>
                <span className="contact-tag">{props.detailsObject.seller.contactNumber}</span>
              </label>
              <label>
                <span className="email-span">Email :</span>
                <span className="email-tag">{props.detailsObject.seller.email}</span>
              </label>
            </div>
          </div>
          </>
            }
            <div className="customer-detail-heading" style={{ marginTop: "5px" }}>
              <h3>Product Detail</h3>
            </div>
            <div className="card card-inner" style={{ marginBottom: "20px" }}>
              <div className="product-detail">
                <div className="product-innerDiv">
                  <label>
                    <span className="category-name">Category: </span>
                    <span className="category-product">{props.detailsObject.mainCategoryName}</span>
                  </label>
                  <label>
                    <span className="category-name">Sub Category: </span>
                    <span className="category-product">{props.detailsObject.categoryName}</span>
                  </label>
                  <label>
                    <span className="category-name">Brand: </span>
                    <span className="category-product">{props.detailsObject.brandName}</span>
                  </label>
                  <label>
                    <span className="quantity-name">Quantity :</span>
                    <span className="quantity-detail">{props.detailsObject.quantity}</span>
                  </label>
                  <label>
                    <span className="category-name">Deal Price: </span>
                    <span className="category-product">{props.detailsObject.dealPrice || "NA"}</span>
                  </label>
                  <label>
                    <span className="category-name">Last Checked Price: </span>
                    <span className="category-product">{props.detailsObject.lastCheckedPrice}</span>
                  </label>
                  <label>
                    <span className="quantity-name">Request Status :</span>
                    <span className="quantity-detail">{props.detailsObject.requestStatus}</span>
                  </label>
                  <label>
                    <span className="quantity-name">Expected Delivery Date :</span>
                    <span className="quantity-detail">{makeDate(props.detailsObject.expectedDeliveryDate)}</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="customer-detail-heading" style={{ marginTop: "5px" }}>
              <h3>Scane QR Code</h3>
            </div>
            <div className="card card-inner" style={{ marginBottom: "20px" }}>
              <div className="product-detail">
                <div className="product-innerDiv">
                  <label style={{ justifyContent: "center",padding: "20px 0px"}}>
                    <QRCode value={props.detailsObject.qrCodeValue} level="H" size={200} />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="footer-content"
            style={{ marginTop: "5px", alignItems: "center" }}
          >
            <div
              className="productSaveButton"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <button type="submit" onClick={props.onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;

import React from "react";

import "./emptyState.css";

function EmptyList(props) {
  return (
    <div className="content-area">
      <div className="emptycard" style={{ margin: "auto", marginTop: "0px" }}>
        <div>
          <img src="./Image/empty-state-NotFound.svg" alt="emptylist" />
        </div>
        <ul className="emptyStateUl">
          {props.ifBtn ?
            (<><li className="emptyTitleIventory">
            <h4 className="page-title empty" style={{ marginBottom: "20px" }}>There’s nothing in the {props.itemFor} list yet</h4>
          </li>
          <li className="emptyState">
            <span>
              You can start by adding new {props.itemFor}.
            </span>
              </li>
            </>) :
            (<>
              <li className="emptyTitleIventory"><h4 className="page-title empty" style={{ marginBottom: "20px" }}>No match found</h4></li>
              <li className="emptyState">
                <span>
                  Please check spelling, or add new {props.itemFor}
                </span>
              </li>
            </>)
          }
          {props.ifBtn && <li>
            <button
              className="btn primary small"
              type="submit"
              onClick={props.onAddNew}
            >
              <img src="./Image/plus_icon.png" alt="Plus Symbol" />
              <span>{props.btnText}</span>
            </button>
          </li>}
        </ul>
      </div>
    </div>
  );
}

export default EmptyList;

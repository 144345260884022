import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useHistory } from "react-router-dom";
import useForm from "../../helper/useForm";
import ValidateRules from "../../helper/ValidateRules";
import axios from "axios";
import "./signin.css";

function Signin() {
  const [isPasswordShown, setIsPasswordShown] = useState("false");
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingMsg, setLoadingMsg] = useState("Signing In...");
  const history = useHistory();
  const { values, errors, setErrors, setIsSubmitting, handleChange, handleSubmit } = useForm(
    login,
    ValidateRules.Login
  );

  useEffect(() => {
    if (localStorage.getItem("ApiKey") && sessionStorage.getItem("user-info") !== null) {
      history.push("/dashboard");
    }
  }, [history]);

  async function login(values) {
    // e.preventDefault();
    setIsLoading(true);
    const data = {
      email: values.email,
      password: values.password,
      userRoleId: 3,
    };

    await axios.post("user/signin", data).then((res) => {
      const content = JSON.stringify(res.data.Data.apiKey).replace(/['"]+/g, "");
      sessionStorage.setItem("User-Info", JSON.stringify(res.data.Data));
      localStorage.setItem("ApiKey", content);
      setIsLoading(false);
      setIsSubmitting(false);
      history.push("/dashboard");
    }).catch((err) => {
      setIsSubmitting(false);
      setErrors({...errors, email:"wrong email or password"});
      setIsLoading(false);
      console.log("Err:::::" + err);
    });
  }

  return (
    <div className="container-fluid">
      <nav className="navbar navbar-light signin-navbar">
        <div className="container">
          <a href="/">
            <img src="./Image/logo.jpg" alt="Logo" width="100" height="100" />
          </a>
        </div>
      </nav>
      <div className="signin-body">
        <div className="container">
          <div className="login-center login-wrapper">
            <div className="login-left-banner cols">
              <div className="img-wrap">
                <img src="./Image/signin-bg.png" alt="DayBulk Logo" />
              </div>
              <div className="title">
                <h1>
                  {" "}
                  <span>Welcome</span> to the DayBulk!
                </h1>
              </div>
            </div>
            <div className="login-content cols">
              <form onSubmit={handleSubmit} noValidate>
                <h2 className="title">Sign In</h2>
                <div className="form-group login-group">
                  <label htmlFor="Email">E-mail</label>
                  <input
                    type="email"
                    className={`form-control input-field shadow-none ${
                      errors.email && "is-danger"
                    }`}
                    placeholder="Enter your e-mail..."
                    onChange={(e) => {
                      handleChange(e, "text", "", "");
                    }}
                    value={values.email || ""}
                    name="email"
                    required
                  />
                  {errors.email && (
                    <p className="help is-danger">{errors.email}</p>
                  )}
                </div>
                <div className="form-group login-group">
                  <label htmlFor="Password">Password</label>
                  <input
                    type={isPasswordShown ? "password" : "text"}
                    name="password"
                    className={`form-control signin-password input-field shadow-none ${
                      errors.password && "is-danger"
                    }`}
                    placeholder="Enter your password"
                    value={values.password || ""}
                    onChange={(e) => {
                      handleChange(e, "text", "", "");
                    }}
                    required
                  />
                  <i
                    className={
                      isPasswordShown ? "far fa-eye" : "far fa-eye-slash"
                    }
                    onClick={() => setIsPasswordShown((preState) => !preState)}
                  />
                  {errors.password && (
                    <p className="help is-danger">{errors.password}</p>
                  )}
                </div>
                <Link to="/reset" className="forgotPassword">
                  Forgotten Password?
                </Link>
                {/* <Link className="signin-link" to="/category"> */}
                <button type="submit" className="btn signin-btn" disabled={isLoading ? true : false}>
                  {isLoading ? loadingMsg : "Sign In"}
                </button>
                {/* </Link> */}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-wrapper">
        <footer className="login-footer">
          <p>&copy; {new Date().getFullYear()} DayBulk.com All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default Signin;

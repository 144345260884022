import React from "react";
import "./emptyState.css";
function EmptyChart(props) {
    return (
        <div className=".content-area-chart">
            <div className="emptycard" style={{ margin: "auto", marginTop: "0px" }}>
                <div>
                    <img src="./Image/empty-state-NotFound.svg" alt="emptylist" />
                </div>
                <ul className="emptyStateUl">
                    <li className="emptyTitleIventory">
                        <h4 className="page-title empty" style={{ marginBottom: "20px" }}>Opps ! its empty here...</h4>
                    </li>
                    <li className="emptyState">
                        <span>
                            No data for selected date range
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default EmptyChart;

import { useState, useEffect } from "react";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const initialValues = (values) => {
    setValues(values);
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  /**
   * 
   * @param {obj} eventObj event object of form element, leave null if event not traceable in case of custome elements.
   * @param {string} elementType type of form element.
   * @param {string} elementName name prop of form element, leave null if event object is passed.
   * @param {any} data prvide input data of form element, if not by event object of that element.
   */
  const handleChange = (eventObj, elementType, elementName, data) => {
    let name = "";
    let value = "";
    switch (elementType) {
      case "text":
        eventObj.persist();
        name = eventObj.target.name;
        value = eventObj.target.value;
        break;
      case "reactSelect":
        name = elementName;
        value = data && data.value;
        break;
      case "reactSelectMulti":
        name = elementName;
        value = data && data;
        break;
      case "switch":
        name = elementName;
        value = data;
        break;
      case "file":
        name = elementName;
        value = data;
        break;
      case "dateTime":
        name = elementName;
        value = data;
        break;
      default:
        break;
    }
    setValues((values) => ({
      ...values,
      [name]: value,
    }));
  };

  return {
    initialValues,
    handleChange,
    handleSubmit,
    setValues,
    values,
    errors,
    setErrors,
    setIsSubmitting,
  };
};

export default useForm;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyChart from "../EmptyState/EmptyChart";
import axiosMiddleware from "../../../helper/axios";
import _concat from "lodash/concat";
import _get from "lodash/get";
// import DatePicker from "react-datepicker";
import { SelectDateDropDown } from "./SelectDateDropDown";
import { getInitDate } from "../../../helper/helper";
import SkeletonLoading from "../../../components/SkeletonLoading";

const ProductSaleOverview = () => {
  const [limit] = useState(15);
  const [hasMore, setHasMore] = useState(true);
  const [resCount, setResCount] = useState(1);
  const [productList, setProductList] = useState([]);
  const [isProductList, setIsProductList] = useState(false);
  const [offset, setOffset] = useState(10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [endMsg, setEndMsg] = useState(true);
  // const [productStartDate, setProductStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));

  function InlineWrapperWithMargin({ children }) {
    return <span style={{ marginRight: '0.5rem', marginTop: "10px" }}>{children}</span>
}
  const loadProductList = async () => {
    let url = ``;
    if (startDate && endDate) {
      url = `request/products-sell-overview?limit=10&offset=0&startDate=${startDate}&endDate=${endDate}`;
    } else {
      let { sDate, eDate } = getInitDate(new Date())
      url = `request/products-sell-overview?limit=10&offset=0&startDate=${sDate}&endDate=${eDate}`
    }
    await axiosMiddleware
      .get(url)
      .then((res) => {
        const totalCount = (res.data.Data || []).length;
        if (!res.data.Status) {
          setHasMore(false);
          setProductList([]);
          setIsProductList(false);
          setResCount(0);
        }
        if (res.data.Status && totalCount === 15) {
          setOffset(offset + limit);
          setProductList(res.data.Data);
          setIsProductList(true);
          setHasMore(true);
          setResCount(totalCount);
        }
        if (res.data.Status && totalCount > 0 && totalCount < 15) {
          setOffset(offset + limit);
          setProductList(res.data.Data);
          setIsProductList(true);
          setHasMore(false);
          setResCount(totalCount);
          setEndMsg(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    loadProductList();
  }, [startDate, endDate]);

  async function fetchMoreData() {
    let url = ``;
    if (startDate && endDate) {
      url = `request/products-sell-overview?limit=10&offset=0&startDate=${startDate}&endDate=${endDate}`;
    } else {
      let { sDate, eDate } = getInitDate(new Date())
      url = `request/products-sell-overview?limit=10&offset=0&startDate=${sDate}&endDate=${eDate}`
    }
    await axiosMiddleware
      .get(url)
      .then((res) => {
        let dummyArray = _get(res, "data.Data", []);
        let fetchArray = _concat(productList, dummyArray);
        setProductList(fetchArray);
        setOffset(offset + limit);
        if (dummyArray.length === 0) {
          setHasMore(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const OnDateRangeChange = (dateRange) => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  }

  const loadingArr = [];

  for (let index = 0; index < 6; index++) {
    loadingArr.push(<tr>
      <td colSpan="2" className="span-col"><SkeletonLoading/></td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      <td>
        <span><SkeletonLoading/></span>
      </td>
      </tr>)
  }
  return (
    <div className="d_card_container" style={{minHeight:"600px"}}>
      <div className="d_card">
        <div className="d_card_header">
          <div>Data - Product Sales Overview</div>
          <div
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SelectDateDropDown OnDateRangeChange={OnDateRangeChange} onPosition="left" />
            {/* <DatePicker
              placeholderText="Select Month"
              selected={new Date(productStartDate)}
              onChange={(date) => setProductStartDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              withPortal
              maxDate={new Date()}
            /> */}
          </div>
        </div>
        <div className="d_card_body">
          <div className="card-table table-wrapper">
            {resCount !== 0 ? (
              <InfiniteScroll
                dataLength={productList && productList.length}
                next={fetchMoreData}
                loader={
                    <table className="table">
                      
                        <tbody>
                            {loadingArr}
                        </tbody>
                    </table>
                }
                endMessage={endMsg &&
                  <p style={{ textAlign: "center" }}>
                    <b>No more records found...</b>
                  </p>
                }
                hasMore={hasMore}
                scrollableTarget="scrollablediv"
                className="infiniteRequestTable"
              >
                <table className="table-main">
                  <thead>
                    <tr>
                      <th colSpan="1" className="span-col"></th>
                      <th>Product Name</th>
                      <th>Product Category</th>
                      <th>Product Brand</th>
                      <th>Avg Sold Price</th>
                      <th>Sold Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* eslint-disable-next-line array-callback-return */}
                    {isProductList &&
                      productList.map((productListObj, index) => (
                        <tr key={index}>
                          <td colSpan="1" className="span-col"></td>
                          <td>
                            <span>{productListObj.categoryName}</span>
                          </td>
                          <td>
                            <span>{productListObj.mainCategoryName}</span>
                          </td>
                          <td>
                            <span>{productListObj.brandName}</span>
                          </td>
                          <td>
                                  <span>{Math.round((parseFloat(productListObj.avgDealPrice) + Number.EPSILON) * 100) / 100}</span>
                          </td>
                          <td>
                            <span>{productListObj.totalSellingQty}</span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            ) : (
              <EmptyChart></EmptyChart>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSaleOverview;
